package com.sludg.util

import com.sludg.util.models.GroupingModels.{
  CategoryData,
  ChildReportData,
  ReportData,
  RootReportData
}

/** @author dpoliakas
  *         Date: 2019-11-18
  *         Time: 16:00
  */
object ReportProjections {

  def projectReport[A](data: RootReportData, projection: ReportData => A): RootProjection[A] = {

    def childConstructor(a: ChildReportData): (A, List[ChildProjection[A]]) => ChildProjection[A] =
      Function.uncurried((ChildProjection.apply[A] _).curried.apply(a.categoryData))

    def constructProjection[B[X] <: Projection[X]](
        data: ReportData,
        constructor: (A, List[ChildProjection[A]]) => B[A]
    ): B[A] = {
      constructor(
        projection(data),
        data.subGroups.map(s => constructProjection[ChildProjection](s, childConstructor(s)))
      )
    }

    constructProjection[RootProjection](data, RootProjection.apply)
  }

}

sealed trait Projection[+A] {
  val value: A
  val children: List[ChildProjection[A]]
}

case class RootProjection[+A](value: A, children: List[ChildProjection[A]]) extends Projection[A]
case class ChildProjection[+A](
    category: CategoryData[_],
    value: A,
    children: List[ChildProjection[A]]
) extends Projection[A]
