package com.sludg.client.components.tables

import com.sludg.client.components.tables.GroupingDataTable.GroupingDataTableComponent
import com.sludg.client.components.tables.GroupingTableModels.SelectionScopedSlotProps
import com.sludg.util.models.GroupingModels.CategoryData
import com.sludg.util.models.ReportModels.ProjectionType
import com.sludg.util.models.ReportModels.Sort.{Ascending, Descending, Neutral}
import com.sludg.util.models.{GroupingModels, ReportModels}
import com.sludg.util.PresenterSyntax._
import com.sludg.util.ApiModelPresenters._
import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.vue.{RenderHelpers, _}
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components.{
  VAutocompleteProps,
  VAutocompleteScopedSlots,
  VAutocompleteScopedSlotsSelectionData,
  VCheckBoxProps,
  VChipProps,
  VDataTableItemSlotData,
  VTooltipProps
}
import org.log4s.getLogger

import scala.scalajs.js
import com.sludg.vuetify.components.VAutocompleteEventBindings

object GroupTableRenderFunctions {
  private[this] val logger = getLogger

  import com.sludg.vue.RenderHelpers._

  def headerSelector(component: GroupingDataTableComponent, renderer: CreateElement) = {

    div(
      vAutocomplete[HeaderWithGrouping](
        vList(
          RenderOptions(slot = Some("prepend-item")),
          vListTile(
            RenderOptions(
              `class` = List(Left("v-list__tile--link"), Left("v-list__tile--active"))
            ),
            vListTileAction(
              vCheckBox(
                RenderOptions(
                  props = Some(
                    VCheckBoxProps(
                      `input-value` = Some(component.showAllHeaders),
                      color = Some("primary")
                    )
                  ),
                  on = Some(
                    EventBindings(
                      change = js.defined(e => {
                        component.showAllHeaders = !component.showAllHeaders
                      })
                    )
                  )
                )
              )
            ),
            vListTileContent(
              vListTileTitle(
                "Show All",
                RenderOptions(
                  on = Some(
                    EventBindings(
                      click = js.defined(e => {
                        component.showAllHeaders = !component.showAllHeaders
                      })
                    )
                  )
                )
              )
            ),
            if (component.showAllHeaders) {
              span(
                RenderOptions(
                  `class` = List(Left("v-ripple__container"))
                )
              )
            } else {
              nothing
            }
          )
        ),
        RenderOptions(
          props = Some(
            VAutocompleteProps[HeaderWithGrouping](
              placeholder = Some("Choose columns you want to see"),
              items = Some(component.selectableHeaderPool),
              itemText = Some(
                Right(a =>
                  a.asInstanceOf[com.sludg.client.components.tables.HeaderWithGrouping]
                    .text
                    .getOrElse(
                      a.asInstanceOf[com.sludg.client.components.tables.HeaderWithGrouping]
                        .value
                        .get
                    )
                )
              ),
              value = Some(Right(component.currentlySelectedHeaders)),
              multiple = Some(true),
              clearable = Some(true),
              `deletable-chips` = Some(true),
              itemValue = Some(Right(identity)),
              chips = Some(true),
              `small-chips` = Some(true),
              `return-object` = Some(true)
            )
          ),
          scopedSlots = Some(
            new VAutocompleteScopedSlots[HeaderWithGrouping](
              selection = js.defined(data =>
                div({
                  (if (data.index < 8) {
                     GroupTableRenderFunctions.chipGenerator(data, component, renderer)
                   } else if (data.index == 8) {
                     span(
                       RenderOptions(`class` = List(Left("grey--text caption"))),
                       s"(+${component.currentlySelectedHeaders.size - 8})"
                     ).render(renderer)
                   } else {
                     div(nothing).render(renderer)
                   })
                }).render(renderer)
              )
            )
          ),
          on = Some(
            VAutocompleteEventBindings(input = js.defined(e => {
              if (js.isUndefined(e)) {
                logger.debug("Table header selection is undefined")
              } else {
                val newValues = e.asInstanceOf[js.Array[HeaderWithGrouping]]
                component.currentlySelectedHeaders = newValues.toList
              }
            }))
          )
        )
      )
    )
  }

  def chipGenerator(
      data: VAutocompleteScopedSlotsSelectionData[HeaderWithGrouping],
      component: GroupingDataTableComponent,
      renderer: CreateElement
  ): RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots] = {
    div(
      RenderOptions(slot = Some("activator")),
      vChip(
        data.item.text.getOrElse("no text").toString,
        RenderOptions(
          props = Some(VChipProps(close = Some(true), small = Some(true))),
          on = Some(EventBindings(input = js.defined(_ => component.chipRemove(data.item))))
        )
      )
    )
  }

  def tableHeaders(a: HeaderWithGrouping, component: GroupingDataTableComponent) = {
    val classCustom2 =
      if ((a.sort contains Ascending) || (a.sort contains Descending)) Left("active") else Left("")
    val icon =
      if (a.sort contains Ascending) vIcon("arrow_upward")
      else if (a.sort contains Descending) vIcon("arrow_downward")
      else {
        if (a.sort contains Neutral)
          if (component.descending) vIcon("arrow_downward") else vIcon("arrow_upward")
        else p("")
      }

    def createDisplayHeader(
        categoryPath: List[CategoryData[_]],
        projectionType: ProjectionType
    ): RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots] = {
      if (categoryPath.size < 1) {
        categoryPath.headOption match {
          case Some(catData) =>
            div(catData.category.present, br, s" ${projectionType.present}".trim)
          case _ => div(s"${projectionType.present}")
        }
      } else {
        val result = div(
          categoryPath.map(a => a.present).mkString(" "),
          br,
          s" ${projectionType.present}".trim
        )
        result
      }
    }

    val displayHeader: RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots] =
      a.projectionType match {
        case Some(projectionType) =>
          createDisplayHeader(a.categoryData.getOrElse(List()), projectionType)
        case None => div(a.value.get)
      }

    th(displayHeader, icon)(
      RenderOptions(
        props = Some(
          js.Dynamic
            .literal(
              "key" -> a.text.get.asInstanceOf[js.Any]
            )
            .asInstanceOf[VueProps]
        ),
        `class` = List(
          Left("column sortable"),
          Left("text-xs-left"),
          classCustom2
        ),
        on = Some(
          EventBindings(
            click = js.defined(_ => {
              var currentHeader: List[GroupingModels.CategoryData[_]] =
                a.categoryData.map(_.toList).getOrElse(List())

              if ((a.sort contains Ascending) || (a.sort contains Descending)) {
                component.descending = !component.descending
              }
              val updateSort: ReportModels.Sort =
                if (component.descending) Descending else Ascending

              val updatedTableHeader = HeaderWithGrouping(
                a.text, // Text will be used for custom header names
                a.value,
                a.align,
                a.sortable,
                Some(updateSort),
                categoryData = a.categoryData.map(_.toList),
                projectionType = a.projectionType,
                width = Some("1%")
              )

              val neutralList = component.currentlySelectedHeaders.map(a => {
                HeaderWithGrouping(
                  a.text,
                  a.value,
                  a.align,
                  a.sortable,
                  Some(Neutral),
                  categoryData = a.categoryData.map(_.toList),
                  projectionType = a.projectionType,
                  width = Some("1%")
                )
              })
              component.isItSameReport = true

              a.projectionType match {
                case None =>
                  component.rootTableHead = List(updatedTableHeader)
                  component.currentlySelectedHeaders = neutralList
                  component.defaultSort(component.rawTableData)
                  if (updatedTableHeader.sort contains Ascending)
                    component.rawTableData = component.rawTableData.reverse
                case Some(b) =>
                  val sortBy = NodePath(a.projectionType.get, currentHeader.map(a => Node(a)))
                  val currentHeaderIndex = component.currentlySelectedHeaders.indexOf(a)

                  val updatedList =
                    neutralList.patch(currentHeaderIndex, List(updatedTableHeader), 1)

                  component.rootTableHead = component.defaultRootHead
                  component.currentlySelectedHeaders = updatedList
                  component.customSort(sortBy, component.descending, component.rawTableData)
              }
            })
          )
        )
      )
    )
  }

  def tableRowData(
      props: VDataTableItemSlotData[GroupingTableModels.GroupingTableItem],
      component: GroupingDataTableComponent
  ) =
    component.currentlySelectedHeaders.map(head => {
      props.item.mapping.get(head.value) match {
        case Some(value) => {
          td(value.formatted)
        }
        case None => {
          td("--")
        }
      }
    })
}
