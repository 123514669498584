package com.sludg.client.components.scheduling

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.RenderHelpers.{div, namedTag, p, td, tr, _}
import com.sludg.vue._
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components._
import org.log4s.getLogger
import org.scalajs.dom.raw.Event

import scala.concurrent.ExecutionContext
import scala.scalajs.js
import scala.scalajs.js.JSConverters._
import scala.scalajs.js.|

object EmailAdder {

  private[this] val logger = getLogger

  type EnterEmailComponent = VueComponent[_ <: EnterEmailProps, _ <: Slots, _ <: ScopedSlots]
    with EnterEmailData
    with EnterEmailMethods
    with js.Object
    with EnterEmailProps

  def enterEmailRenderer(registrationName: String) =
    namedTag[EnterEmailProps, EnterEmailEvents, ScopedSlots]("EnterEmail")

  def enterEmailComponent() = {
    VueComponent.builder
      .withData(new EnterEmailData)
      .withMethods(new EnterEmailMethods())
      .withProps(EnterEmailProps())
      .build(
        watch = new EmailSendingWatcher(),
        created = js.defined(c => {
          c.emailList = c.emails.get.toList.map(email => TableItems(Some(email)))
        }),
        components = js.Dynamic.literal(),
        templateOrRender = Right((component, renderer) => {
          div(
            vFlex(
              vCard(
                VCardProps(
                  `max-width` = Some(Left(650)),
                  `min-width` = Some(Left(650)),
                  `min-height` = Some(Left(650)),
                  height = Some(Left(500)),
                  width = Some(Left(500))
                ),
                vLayout(vFlex(p(""))),
                vLayout(vFlex(p(""))),
                vLayout(
                  vFlex(p("")),
                  vFlex(
                    vTextField(
                      RenderOptions(
                        props = Some(
                          js.Dynamic
                            .literal(
                              "regular" -> true,
                              "clearable" -> true,
                              "placeholder" -> "Email address",
                              "label" -> "Enter an email",
                              "value" -> component.emailInput,
                              "rules" -> List(
                                (((a: (String)) => {
                                  if (component.isEmailValid(component) || a.trim.length < 1) {
                                    component.emailValid = true
                                    true
                                  } else {
                                    component.emailValid = false
                                    "Invalid email entered."
                                  }
                                }): js.Function1[String, Boolean | String])
                              ).toJSArray
                            )
                            .asInstanceOf[VTextFieldProps]
                        ),
                        on = component.updateEmail(component)
                      )
                    )
                  ),
                  vFlex(
                    vButton(
                      vIcon("add"),
                      RenderOptions(
                        props = Some(VButtonProps(fab = Some(true), small = Some(true))),
                        on = Some(component.addEmailButtonClickHandler(component))
                      )
                    )
                  ),
                  vFlex(p(""))
                ),
                vDivider,
                vLayout(vFlex(p(""))),
                vDataTable(
                  RenderOptions(
                    props = Some(
                      VDataTableProps[TableItems, VDataTableHeader](
                        headers = Some(List(new VDataTableHeader(text = "Emails", value = "name"))),
                        items = Some(component.emailList),
                        `hide-actors` = Some(true),
                        `hide-headers` = Some(true),
                        `hide-actions` = Some(true)
                      )
                    ),
                    scopedSlots = Some(
                      new VDataTableScopedSlots[TableItems, VDataTableHeader](
                        items = js.defined(items => {
                          tr(
                            td(""),
                            td(items.item.name.toString),
                            td(
                              vButton(
                                vIcon("delete"),
                                RenderOptions(
                                  on = Some(
                                    component.deleteEvent(component, items.item.name.toString)
                                  ),
                                  props = Some(
                                    VButtonProps(
                                      icon = Some(true),
                                      small = Some(true),
                                      right = Some(true)
                                    )
                                  )
                                )
                              )
                            )
                          ).render(renderer)
                        })
                      )
                    )
                  )
                )
              )
            )
          ).render(renderer)
        })
      )
  }

  trait TableItems extends js.Object {
    val name: js.UndefOr[String] = js.undefined
  }

  object TableItems {

    import scala.scalajs.js.JSConverters._

    def apply(name: Option[String] = None): TableItems = {
      DynamicHelper.buildViaDynamic("name" -> name.map(js.Any.fromString).orUndefined)
    }
  }

  trait EnterEmailEvents extends EventBindings {
    def test(e: Event): Unit
  }

  object EnterEmailEvents {
    def apply() = {
      "".asInstanceOf[EnterEmailEvents]
    }
  }

  class EmailSendingWatcher() extends js.Object {

    def selectedTenant(newTenant: Int, oldValue: Int): Unit = {
      if (newTenant != oldValue) {
        logger.info("EmailSendingWatcher: New Tenant Selected! " + newTenant.toString())

        //Resetting state
        val e = this.asInstanceOf[EnterEmailComponent]

        e.emailInput = ""
        e.emailList = List[TableItems]()

        e.sendMeEmailsNow = false
        e.emailValid = false
      }
    }

  }

  class EnterEmailMethods extends js.Object {

    def deleteEvent(data: EnterEmailComponent, string: String): EventBindings = {
      EventBindings(
        click = js.defined(e => {
          data.emailList = data.emailList.filterNot(a => a.name.get == string)
          data.$emit("emailListEvent", data.emailList.map(_.name.get).mkString(","))
        })
      )
    }

    def updateEmail(component: EnterEmailComponent): Option[EventBindings] = {
      Some(
        EventBindings(
          input = js.defined(e => {
            logger.debug("Event!---[updateEmail]---" + e.toString)
            component.emailInput = e.toString
          }),
          keydown = js.defined(e => {
            logger.debug("DONT RELOAD")
          })
        )
      )
    }

    def clearEmail(component: EnterEmailComponent) = {
      component.emailInput = ""
    }

    def emitCurrentEmails(component: EnterEmailComponent) = {
      component.$emit(
        "emailListEvent",
        this.asInstanceOf[EnterEmailData].emailList.map(_.name.get).mkString(",")
      )
    }

    def addEmail(component: EnterEmailComponent) = {
      component.emailList = (component.emailList ::: List(TableItems(Some(component.emailInput))))
    }

    def isEmailValid(component: EnterEmailComponent): Boolean = {
      val emailRegex =
        """^[a-zA-Z0-9\.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$""".r
      component.emailInput.toString match {
        case null => false
        case e if e.trim.isEmpty => false
        case e if emailRegex.findFirstMatchIn(e).isDefined => {
          true
        }
        case _ => false
      }
    }

    def isEmailEntered(component: EnterEmailComponent): Boolean =
      !component.emailInput.toString.trim.isEmpty

    def addEmailButtonClickHandler(component: EnterEmailComponent): EventBindings = {
      EventBindings(
        click = js.defined(e => {
          if (isEmailEntered(component)) {
            if (isEmailValid(component)) {
              addEmail(component)
              emitCurrentEmails(component)
              clearEmail(component)
            } else {
              component.$emit("emailError", "Invalid")
              logger.debug("Try another email?!")
            }
          } else {
            component.$emit("emailError", "Empty")
            logger.debug("No email entered")
          }
        })
      )
    }
  }

  trait EnterEmailProps extends VueProps {
    val emails: js.UndefOr[js.Array[String]] = js.undefined
    var sendEmailsToMeNow: js.UndefOr[Boolean] = js.undefined
    var hideMenu: js.UndefOr[Boolean] = js.undefined
    var selectedTenant: js.UndefOr[Int] = js.undefined
  }

  object EnterEmailProps {
    def apply(
        emails: js.UndefOr[js.Array[String]] = js.undefined,
        sendEmailsToMeNow: js.UndefOr[Boolean] = js.undefined,
        hideMenu: js.UndefOr[Boolean] = js.undefined,
        selectedTenant: js.UndefOr[Int] = js.undefined
    ): EnterEmailProps = {
      js.Dynamic
        .literal(
          "emails" -> emails,
          "hideMenu" -> hideMenu,
          "selectedTenant" -> selectedTenant
        )
        .asInstanceOf[EnterEmailProps]
    }
  }

  class EnterEmailData extends js.Object {
    /* emails */
    var emailInput: String = ""
    var emailList = List[TableItems]()

    var sendMeEmailsNow = false
    var emailValid = false
  }

}
