package com.sludg.client.components.scheduling.period

import java.text.SimpleDateFormat
import java.time.format.DateTimeFormatter
import java.time._
import java.util.TimeZone

import com.sludg.client.components.scheduling.ScheduleTableData
import com.sludg.client.components.scheduling.helpers.CronConverter
import com.sludg.client.components.scheduling.helpers.CronConverter.Period.Monthly
import com.sludg.client.components.scheduling.helpers.CronConverter.{MonthlySelection, Period}
import com.sludg.vue.RenderHelpers._
import com.sludg.vue.{RenderHelpers, _}
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components._
import cron4s.Cron
import cron4s.expr.CronExpr
import org.log4s.getLogger
import org.scalajs.dom.raw.Event

import scala.scalajs.js
import scala.scalajs.js.JSConverters._
import scala.scalajs.js.ThisFunction0
import scala.util.matching.Regex

object MonthlyPage {

  private[period] val logger = getLogger

  type MonthlyPageComponent = VueComponent[_ <: MonthlyPageProps, _ <: Slots, _ <: ScopedSlots]
    with MonthlyPageData
    with MonthlyPageMethods
    with js.Object
    with MonthlyPageProps

  def monthlyPageRenderer(registrationName: String) =
    namedTag[MonthlyPageProps, MonthlyPageEvents, ScopedSlots](registrationName)

  def monthlyPageComponent() = {
    VueComponent.builder
      .withData(new MonthlyPageData)
      .withMethods(new MonthlyPageMethods())
      .withProps(MonthlyPageProps())
      .build(
        watch = new MonthlyPageWatcher(),
        components = js.Dynamic.literal(),
        templateOrRender = Right((component, renderer) => {
          div(
            vCard(
              VCardProps(`max-width` = Some(Left(650))),
              vLayout(
                vFlex(
                  vAutocomplete[String](
                    RenderOptions(
                      props = Some(
                        VAutocompleteProps[String](
                          label = Some("Every month"),
                          items = Some(
                            List(
                              "Specific months",
                              "Every month",
                              "Every 2 months",
                              "Every 3 months",
                              "Every 4 months",
                              "Every 6 months"
                            )
                          ),
                          value = Some(Left(component.everyMonth)),
                          `auto-select-first` = Some(true)
                        )
                      ),
                      on = component.monthlyPeriodSelectorHandler(component, component)
                    )
                  )
                ),
                datePicker(component),
                vFlex(component.timeSelector(component))
              ),
              vLayout(
                vDatePicker(
                  RenderOptions[VDatePickerProps, EventBindings, ScopedSlots](
                    props = Some(
                      js.Dynamic
                        .literal(
                          "type" -> "month",
                          "full-width" -> true,
                          "scrollable" -> false,
                          "multiple" -> true,
                          "value" -> component.selectedMonths
                        )
                        .asInstanceOf[VDatePickerProps]
                    ),
                    on = component.monthlySelectionOfMonthsHandler(component)
                  )
                )
              )
            )
          ).render(renderer)
        })
      )
  }

  def datePicker(component: MonthlyPageComponent) = {
    vFlex(
      vMenu(
        vTextField(
          js.Dynamic
            .literal(
              "slot" -> "activator",
              "props" -> VTextFieldProps(
                readonly = Some(true),
                label = Some("Dates"),
                value = Some(component.selectedDaysParsed)
              )
            )
            .asInstanceOf[RenderOptions[VTextFieldProps, EventBindings, ScopedSlots]]
        ),
        vDatePicker(
          RenderOptions[VDatePickerProps, EventBindings, ScopedSlots](
            props = Some(
              js.Dynamic
                .literal(
                  "reactive" -> true,
                  "scrollable" -> false,
                  "no-title" -> true,
                  "show-current" -> true,
                  "tile" -> false,
                  "flat" -> true,
                  "multiple" -> true,
                  "value" -> component.selectedDays
                )
                .asInstanceOf[VDatePickerProps]
            ),
            on = component.daysOfMonthSelectionHandler(component)
          )
        ),
        vCard(
          vButton(
            "Confirm",
            RenderOptions(on = Some(EventBindings(click = js.defined(e => {
              component.showMenu2 = false
            }))))
          ),
          RenderOptions(
            style = Some(
              js.Dynamic.literal(
                "text-align" -> "center"
              )
            )
          )
        )
      )(
        RenderOptions(
          props = Some(
            VMenuProps(
              value = Some(component.showMenu2),
              closeOnClick = Some(true),
              closeOnContentClick = Some(false)
            )
          ),
          on = Some(EventBindings(input = js.defined(e => {
            component.showMenu2 = true
          })))
        )
      )
    )
  }

  trait MonthlyPageEvents extends EventBindings {
    def test(e: Event): Unit
  }

  object MonthlyPageEvents {
    def apply() = {
      "".asInstanceOf[MonthlyPageEvents]
    }
  }

  class MonthlyPageMethods extends js.Object {

    def generateMonthsSelected(numberOfMonths: Int) = {
      val currentYear = Year.now(ZoneOffset.UTC)
      List.tabulate(numberOfMonths)(month =>
        s"$currentYear-${if ((month + 1) < 10) "0" + (month + 1) else (month + 1)}"
      )
    }

    def getInitialMonthSelected(): List[String] = {
      generateMonthsSelected(1)
    }

    def getEveryMonthSelected(): List[String] = {
      generateMonthsSelected(12)
    }

    def addSuffix(date: Int): String = {
      date % 10 match {
        case 1 if date == 11 => "th"
        case 2 if date == 12 => "th"
        case 3 if date == 13 => "th"
        case 1 => "st"
        case 2 => "nd"
        case 3 => "rd"
        case _ => "th"
      }
    }

    def showMenu(component: MonthlyPageComponent): EventBindings = {
      EventBindings(
        input = js.defined(e => {
          component.showMenu = true
        })
      )
    }

    def showMenu2(component: MonthlyPageComponent): EventBindings = {
      EventBindings(
        input = js.defined(e => {
          component.showMenu2 = true
        })
      )
    }

    def daysOfMonthSelectionHandler(data: MonthlyPageComponent): Option[EventBindings] = {
      Some(
        EventBindings(
          input = js.defined(e => {
            if (e.toString.trim.isEmpty) {
              logger.debug("Event---[daysOfMonthSelectionHandler]---No days selected.")
              data.selectedDays = List[String]("2019-01-01").toJSArray
            } else {
              val daysCurrently: js.Array[String] = e.toString.split(",").toJSArray

              logger.debug(
                "Event--[daysOfMonthSelectionHandler]---There are days selected!:" + daysCurrently.toString
              )
              data.daysOfTheMonthSelected = daysCurrently.toList

              data.selectedDays = daysCurrently.toList.distinct.toJSArray
              data.selectedDaysParsed = data.selectedDays.toList
                .map(_.drop(8))
                .map(date => date + addSuffix(date.toInt))
                .toJSArray
            }
            updateScheduleEvent(data)
          })
        )
      )
    }

    /* Monthly selection */
    def monthlySelectionOfMonthsHandler(data: MonthlyPageComponent): Option[EventBindings] = {
      Some(
        EventBindings(
          input = js.defined(e => {

            data.monthlySelection = MonthlySelection.SpecificMonths

            if (e.toString.trim.isEmpty) {
              logger.debug("Event---[monthlyMonthSelectionEvent]---No months selected.")
              data.selectedMonths = List[String]().toJSArray
            } else {
              val monthsSelectedCurrently = e.toString.split(",").toJSArray

              logger.debug("Specific months!")
              data.everyMonth = "Specific months"

              logger.debug(
                "Event---[monthlyMonthSelectionEvent]---Months selected" + monthsSelectedCurrently
              )
              data.selectedMonths = monthsSelectedCurrently
            }

            logger.debug("data" + data.selectedMonths.toString)
            updateScheduleEvent(data)
          })
        )
      )
    }

    def daysOfTheMonthSelectedHandler(
        data: MonthlyPageComponent,
        methods: MonthlyPageComponent
    ): Option[EventBindings] = {
      Some(
        EventBindings(
          input = js.defined(e => {

            if (e.toString.trim.isEmpty) {
              logger.debug(
                "Event--[dayOfTheMonthHandler]---There are no days selected. Defaulting to 1st."
              )
              data.daysOfTheMonthSelected = List("1")
            } else {
              val datesSelected = e.toString().split(",").toList.map(_.filter(_.isDigit).toString())
              logger.debug(
                "Event--[dayOfTheMonthHandler]---There are days selected!:" + datesSelected
              )
              data.daysOfTheMonthSelected = datesSelected
            }
            updateScheduleEvent(data)
          })
        )
      )
    }

    def monthlyPeriodSelectorHandler(
        data: MonthlyPageComponent,
        methods: MonthlyPageComponent
    ): Option[VAutocompleteEventBindings] = {
      Some(
        VAutocompleteEventBindings(
          input = js.defined(e => {
            e.toString match {
              case "Specific months" =>
                logger.debug("Event! [MonthlyNSelected]: Specific months selected.")
                data.monthlySelection = MonthlySelection.SpecificMonths
                data.selectedMonths = data.specificMonths
              case "Every month" => {
                logger.debug("Event! [MonthlyNSelected]: Every month selected.")
                data.monthlySelection = MonthlySelection.EveryMonth
                updateCalenderOnMonthlySection(data)
                data.everyNumberOfMonths = 1
              }
              case _ => {
                logger.debug("Event! [MonthlyNSelected]: Every number of months selected.")
                data.monthlySelection = MonthlySelection.EveryPeriodOfMonths
                //return months enum value instead of this
                data.everyNumberOfMonths = e.toString.dropRight(7).drop(6).toInt
                updateCalenderOnMonthlySection(data)
              }
            }
            updateScheduleEvent(data)
          })
        )
      )
    }

    def updateCalenderOnMonthlySection(data: MonthlyPageComponent) = {
      data.monthlySelection match {
        case MonthlySelection.EveryMonth => data.selectedMonths = getEveryMonthSelected().toJSArray
        case MonthlySelection.EveryPeriodOfMonths => {
          logger.debug(
            "[updateCalenderOnMonthlySection]---Every " + data.everyNumberOfMonths + "months"
          )

          data.selectedMonths =
            keepEveryNthElement(getEveryMonthSelected(), data.everyNumberOfMonths).toJSArray
        }
        case _ => logger.debug("Non multi-option selected, leaving as is")
      }
    }

    /* Used to deselect every nth month from the year */
    def keepEveryNthElement(l: List[String], n: Int) = {
      require(n > 0)
      for (step <- Range(start = n - 1, end = l.length, step = n))
        yield l(step)
    }

    def generateSchedule(data: MonthlyPageComponent) = {
      logger.debug("CURRENT STATE " + data.currentState)

      data.currentState match {
        case Monthly =>
          val daysOfTheMonth = data.daysOfTheMonthSelected match {
            case Nil => List("1") //Defaults to 1st
            case _ => data.selectedDaysParsed.toList.map(_.dropRight(2))
          }
          data.monthlySelection match {
            case MonthlySelection.SpecificMonths =>
              val specificMonths = data.selectedMonths.toList.length match {
                case 0 => "*" //Defaults to every month
                case _ => data.selectedMonths.toList.map(_.drop(5).toString).mkString(",")
              }

              data.schedule = CronConverter.buildCron(
                "0",
                data.time.getMinute.toString,
                data.time.getHour.toString,
                daysOfTheMonth.mkString(","),
                specificMonths
              )

            case MonthlySelection.EveryMonth =>
              data.schedule = CronConverter.buildCron(
                "0",
                data.time.getMinute.toString,
                data.time.getHour.toString,
                daysOfTheMonth.mkString(","),
                "*"
              )

            case MonthlySelection.EveryPeriodOfMonths =>
              data.schedule = CronConverter.buildCron(
                "0",
                data.time.getMinute.toString,
                data.time.getHour.toString,
                daysOfTheMonth.mkString(","),
                s"*/${data.everyNumberOfMonths}"
              )
          }
        case _ => logger.debug("convert to a component")
      }
      logger.debug("CronEx generated: " + data.schedule)
    }

    def timeSelector(
        component: MonthlyPageComponent
    ): RenderHelpers.NodeRenderer[VMenuProps, EventBindings, ScopedSlots] = {
      vMenu(
        vTextField(
          js.Dynamic
            .literal(
              "slot" -> "activator",
              "props" -> js.Dynamic
                .literal(
                  "readonly" -> true,
                  "label" -> "Arrival time",
                  "value" -> component.arrivalTimeDisplay,
                  "landscape" -> true
                )
                .asInstanceOf[VTextFieldProps] //,
              //"on" -> component.showMenu2(component)
            )
            .asInstanceOf[RenderOptions[VTextFieldProps, EventBindings, ScopedSlots]]
        ),
        vTimePicker(
          RenderOptions[VTimePickerProps, EventBindings, ScopedSlots](
            props = Some(
              js.Dynamic
                .literal(
                  "landscape" -> false
                )
                .asInstanceOf[VTimePickerProps]
            ),
            on = component.timePickerEvent(component)
          )
        ),
        vCard(
          vLayout(
            vFlex(
              vButton(
                "Confirm",
                RenderOptions(
                  on = Some(
                    EventBindings(
                      click = js.defined(e => {
                        component.showMenu = false
                      })
                    )
                  )
                )
              )
            )
          ),
          RenderOptions(
            style = Some(
              js.Dynamic.literal(
                "text-align" -> "center"
              )
            )
          )
        )
      )(
        RenderOptions(
          props = Some(
            VMenuProps(
              value = Some(component.showMenu),
              closeOnClick = Some(true),
              closeOnContentClick = Some(false)
            )
          ),
          on = Some(EventBindings(input = js.defined(_ => component.showMenu = true)))
        )
      )
    }

    def timePickerEvent(data: MonthlyPageComponent) = {
      Some(EventBindings(input = js.defined(e => {
        data.arrivalTimeDisplay = e.toString
        val timeFormatter = DateTimeFormatter.ISO_LOCAL_TIME
        val arrivalTime = LocalTime.parse(e.toString, timeFormatter)
        data.time = arrivalTime
        updateScheduleEvent(data)
      })))
    }

    def updateScheduleEvent(component: MonthlyPageComponent) = {
      generateSchedule(component)
      component.$emit("updateSchedule", component.schedule.toString)
    }

    def cronToDatePicker(data: MonthlyPageComponent, cron: CronExpr) = {
      val days = cron.daysOfMonth.toString.split(",").toList
      val months = cron.months.toString.split(",").toList.map(CronConverter.addLeadingZero)

      //Set the selected months based on the cron month section
      data.selectedMonths = if (months.head == "0*") {

        data.monthlySelection = MonthlySelection.EveryMonth
        getEveryMonthSelected().toJSArray
      } else if (months.head.contains("*") && months.head.contains("/")) {

        data.monthlySelection = MonthlySelection.EveryPeriodOfMonths
        data.everyNumberOfMonths = months.head.split("\\D+").filter(_.nonEmpty).toList.head.toInt
        keepEveryNthElement(getEveryMonthSelected(), data.everyNumberOfMonths).toJSArray
      } else {
        data.monthlySelection = MonthlySelection.SpecificMonths
        months.map(month => data.year + "-" + month).toJSArray
      }

      data.arrivalTimeDisplay =
        CronConverter.addLeadingZero(cron.hours.toString()) + ":" + CronConverter.addLeadingZero(
          cron.minutes.toString()
        )
      val formattedDays = days.map(CronConverter.addLeadingZero)
      val aDate = formattedDays.map(day => data.year + "-" + data.month + "-" + day)

      data.selectedDays = aDate.distinct.toJSArray
      data.selectedDaysParsed =
        data.selectedDays.toList.map(_.drop(8)).map(date => date + addSuffix(date.toInt)).toJSArray
    }

  }

  class MonthlyPageWatcher extends js.Object {
    def selectedTenant(newTenant: Int, oldValue: Int): Unit = {
      val m = this.asInstanceOf[MonthlyPageComponent]

      println(s"MonthlyPageWatcher: Resetting data for $newTenant")

      m.startDateDisplay = ""
      m.startDateInput = js.undefined
      m.startDateSelected = false

      m.currentState = Period.Monthly

      m.time = LocalTime.MIDNIGHT
      m.arrivalTimeDisplay = LocalTime.MIDNIGHT.toString

      m.everyMonth = "Specific months"
      m.monthlySelection = MonthlySelection.SpecificMonths
      m.selectedDays = Array[String]().toJSArray
      m.selectedDaysParsed = Array[String]("01st").toJSArray

      m.everyNumberOfMonths = 1 //Every month
      m.daysOfTheMonthSelected = List("1")

      val t = TimeZone.getTimeZone("GMT").toZoneId
      val ld = LocalDate.now(t)
      val y = ld.getYear.toString
      val mm = CronConverter.addLeadingZero(ld.getMonthValue.toString)

      m.localDate = ld
      m.year = y
      m.month = mm

      m.selectedMonths = Array[String]("0").toJSArray //Resetting months
      m.specificMonths = Array[String]("0").toJSArray

      m.schedule = Cron.unsafeParse("0 10 12 ? * *")
      m.showMenu = false
      m.showMenu2 = false
    }

    def savedCron(newVal: js.UndefOr[CronExpr], oldVal: js.UndefOr[CronExpr]): Unit = {
      if (newVal.isDefined) {
        val comp = this.asInstanceOf[MonthlyPageComponent]
        val data = this.asInstanceOf[MonthlyPageData]
        val methods = this.asInstanceOf[MonthlyPageMethods]
        methods.cronToDatePicker(comp, newVal.get)
        methods.updateScheduleEvent(comp)
      }
    }

  }

  trait MonthlyPageProps extends VueProps {
    val savedCron: js.UndefOr[CronExpr] = js.undefined
    var selectedTenant: js.UndefOr[Int] = js.undefined
  }

  object MonthlyPageProps {
    def apply(
        savedCron: js.UndefOr[CronExpr] = js.undefined,
        selectedTenant: js.UndefOr[Int] = js.undefined
    ): MonthlyPageProps = {
      js.Dynamic
        .literal(
          "selectedTenant" -> selectedTenant,
          "savedCron" -> savedCron.asInstanceOf[js.Any]
        )
        .asInstanceOf[MonthlyPageProps]
    }
  }

  class MonthlyPageData extends js.Object {
    /* date stuff */
    var startDateDisplay: String = ""
    var startDateInput: js.UndefOr[LocalDate] = js.undefined
    var startDateSelected: Boolean = false

    /* state */
    var currentState: Period = Period.Monthly

    /* time */
    var time: LocalTime = LocalTime.MIDNIGHT
    var arrivalTimeDisplay: String = LocalTime.MIDNIGHT.toString

    /* monthly */
    var everyMonth: String = "Specific months"
    var monthlySelection: MonthlySelection = MonthlySelection.SpecificMonths
    var selectedDays = Array[String]().toJSArray
    var selectedDaysParsed = Array[String]("01st").toJSArray

    var everyNumberOfMonths = 1 //Every month
    var daysOfTheMonthSelected: List[String] = List("1")

    //Temp
    val timeZone: ZoneId = TimeZone.getTimeZone("GMT").toZoneId
    var localDate: LocalDate = LocalDate.now(timeZone)
    var year = localDate.getYear.toString
    var month = CronConverter.addLeadingZero(localDate.getMonthValue.toString)

    var selectedMonths = Array[String](year + "-" + month).toJSArray
    var specificMonths = Array[String](year + "-" + month).toJSArray

    /* cronExpression */
    var schedule: CronExpr = Cron.unsafeParse("0 10 12 ? * *")
    var showMenu = false
    var showMenu2 = false

  }

}
