package com.sludg.client.components

import com.sludg.client.components.GroupSelector.makeTiles
import com.sludg.services.ApiCalls
import com.sludg.util.PresenterSyntax._
import com.sludg.util.ApiModelPresenters._
import com.sludg.util.models.GroupingModels._
import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.vue.{RenderHelpers, _}
import com.sludg.vuetify.VuetifyComponents.{vAutocomplete, vList, _}
import com.sludg.vuetify.components._
import com.sludg.vuetify.components.grid.VFlexProps
import org.log4s.getLogger
import org.scalajs.dom.Event

import scala.scalajs.js
import scala.scalajs.js.JSConverters._
import scala.scalajs.js.|

object GroupSelector {

  private[this] val logger = getLogger

  val UP_ICON = "keyboard_arrow_up"
  val DOWN_ICON = "keyboard_arrow_down"

  import com.sludg.vue.RenderHelpers._

  def groupSelectorRenderer(registrationName: String) =
    namedTag[GroupSelectorProps, GroupSelectorEvents, ScopedSlots]("GroupSelector")

  def groupSelectorComponent(apiCalls: ApiCalls, loadingEventBus: Vue) = {
    VueComponent.builder
      .withData(new GroupSelectorData())
      .withProps[GroupSelectorProps](
        js.Array(
          "currentlySelectedGroupings",
          "currentlySelectedGroupings2",
          "fullGroupingList"
        ).asInstanceOf[GroupSelectorProps]
      )
      .withMethods(new GroupSelectorMethods)
      .build(
        watch = new GroupSelectorWatcher,
        created = js.defined(c => {}),
        updated = js.defined(c => {
          logger.debug("Group selector updated")
        }),
        templateOrRender = Right((component, renderer) => {
          div(
            createAutoComplete(renderer, component, component),
            vList(
              makeTiles(
                component.currentlySelectedGroupings,
                component,
                component
              ),
              emptyGroupText(component)
            )
          ).render(renderer)
        })
      )
  }

  def emptyGroupText(
      component: GroupSelectorData with GroupSelectorProps
  ): RenderHelpers.NodeRenderer[_ <: VueProps, EventBindings, ScopedSlots] = {
    component.currentlySelectedGroupings match {
      case Nil =>
        vListTile(
          vSubheader(
            "No views have been chosen. Select a view to determine how call data is presented.",
            RenderOptions[VSubheaderProps, EventBindings, ScopedSlots](
              style = Some(
                js.Dynamic.literal(
                  "text-align" -> "center",
                  "margin" -> "0 auto"
                )
              )
            )
          )
        )

      case _ => div()
    }
  }

  def makeTiles(
      list: List[Category[_]],
      data: GroupSelectorData,
      props: GroupSelectorProps
  ) = {
    list.zipWithIndex.map { case (group, index) =>
      vListTile(
        vFlex(
          (s"${index + 1}. ${group.present}"),
          RenderOptions(
            `class` = List("xs12", "sm5", "md5").map(Left.apply)
          )
        ),
        vFlex(
          vSubheader(listSubHeadingText(group))
        ),
        vSpacer,
        renderButtons(list, group, data, props)
      )
    }
  }

  def listSubHeadingText(group: Category[_]): String = {
    group match {
      case Category.Direction => "Inbound, Outbound, Internal"
      case Category.Answer => "Answered, Not Answered"
      case Category.Day => "View by Day of Month"
      case Category.DayOfWeek => "View by Day of Week"
      case Category.Week => "View by Week"
      case Category.Month => "View by Month"
      case Category.Subscriber => "View by User"
      case Category.LastExtension => "View by Extension"
      case Category.AutoAttendant => "View by Auto Attendant"
      case Category.CallGroup => "View by Call Group"
      case Category.ClassOfService =>
        "Premium, Mobile, International, Emergency..."
      case Category.Termination => "People, Auto attendants, Voicemail"
      case Category.HourOfDay => "View by Hour of Day"
      case Category.DialledNumber => "View by Dialled Number"
    }
  }

  private def renderButtons(
      list: List[Category[_]],
      currentCategory: Category[_],
      data: GroupSelectorData,
      props: GroupSelectorProps
  ) = {
    div(
      list match {
        case head :: Nil => nothing
        case head :: tail if currentCategory == head =>
          div(
            vButton(
              vIcon(UP_ICON),
              RenderOptions(
                props = Some(
                  VButtonProps(
                    icon = Some(true),
                    small = Some(true),
                    right = Some(true),
                    disabled = Some(true)
                  )
                )
              )
            ),
            makeArrows(DOWN_ICON, list, currentCategory, data, props)
          )
        case list @ head :: tail if currentCategory == list.last =>
          div(
            makeArrows(UP_ICON, list, currentCategory, data, props),
            vButton(
              vIcon(DOWN_ICON),
              RenderOptions(
                props = Some(
                  VButtonProps(
                    icon = Some(true),
                    small = Some(true),
                    right = Some(true),
                    disabled = Some(true)
                  )
                )
              )
            )
          )
        case _ =>
          div(
            makeArrows(UP_ICON, list, currentCategory, data, props),
            makeArrows(DOWN_ICON, list, currentCategory, data, props)
          )
      }
    )
  }

  private def makeArrows(
      iconName: String,
      list: List[Category[_]],
      category: Category[_],
      data: GroupSelectorData,
      props: GroupSelectorProps
  ) = {
    vButton(
      vIcon(iconName),
      RenderOptions(
        props = Some(
          VButtonProps(
            icon = Some(true),
            small = Some(true),
            right = Some(true)
          )
        ),
        on = Some(
          EventBindings(
            click = js.defined(_ => {
              val (pre, post) = list.splitAt(list.indexOf(category))
              val tail = post.tail
              val e = post.head
              if (iconName == DOWN_ICON)
                data.newSelections = (pre ::: tail.head :: e :: tail.tail)
              if (iconName == UP_ICON)
                data.newSelections = (pre.init ::: e :: pre.last :: tail)
            })
          )
        )
      )
    )
  }

  private def createAutoComplete(
      renderer: CreateElement,
      data: GroupSelectorData,
      props: GroupSelectorProps
  ) = {

    div(
      vAutocomplete[Any](
        RenderOptions(
          props = Some(
            VAutocompleteProps[Any](
              multiple = Some(true),
              chips = Some(true),
              `deletable-chips` = Some(true),
              autocomplete = Some(true),
              `return-object` = Some(true),
              clearable = Some(true),
              placeholder = Some("Select or change report views"),
              items = props.fullGroupingList.map(_.toList).toOption,
              value = Some(Right(props.currentlySelectedGroupings)),
              itemText = {
                val rendererOfThings: Category[_] => String = c => c.present
                Some(
                  Right(x =>
                    (x: Any) match {
                      case array: js.Array[_] =>
                        array
                          .collect { case x: Category[_] =>
                            rendererOfThings(x)
                          }
                          .mkString(", ")
                      case cat: Category[_] => rendererOfThings(cat)
                      case _ => "No Views found"
                    }
                  )
                )
              }
            )
          ),
          on = Some(
            VAutocompleteEventBindings(
              input = js.defined(e => {
                val selections = e.toString.split(",").map(_.trim).toList
                if (selections != List(""))
                  data.newSelections = selections.map(x => Category.strToObj(x))
                else data.newSelections = Nil
              })
            )
          )
        )
      )
    ).render(renderer)
  }
}

class GroupSelectorWatcher() extends js.Object {
  def newSelections(newVal: List[Category[_]], oldVal: List[Category[_]]) = {
    if (newVal.nonEmpty) {
      this
        .asInstanceOf[VueComponent[_, _, _]]
        .$emit("groupSelected", newVal.asInstanceOf[Event])
      newVal match {
        case Nil => println("only none?")
        case _ =>
          this
            .asInstanceOf[VueComponent[_, _, _]]
            .$emit("groupSelected", newVal.asInstanceOf[Event])
          makeTiles(
            newVal.toList,
            this.asInstanceOf[GroupSelectorData],
            this.asInstanceOf[GroupSelectorProps]
          )
      }
    }
  }

}

trait GroupSelectorProps extends VueProps {
  val currentlySelectedGroupings: List[Category[_]]
  val fullGroupingList: js.UndefOr[js.Array[Category[_]]]
}

object GroupSelectorProps {
  def apply(
      currentlySelectedGroupings: List[Category[_]],
      fullGroupingList: js.UndefOr[js.Array[Category[_]]] = js.undefined
  ): GroupSelectorProps = {
    js.Dynamic
      .literal(
        "currentlySelectedGroupings" -> currentlySelectedGroupings
          .asInstanceOf[js.Object],
        "fullGroupingList" -> fullGroupingList
      )
      .asInstanceOf[GroupSelectorProps]
  }
}

class GroupSelectorData extends js.Object {
  var newSelections: List[Category[_]] = List()
}

class GroupSelectorMethods extends js.Object {}

class GroupSelectorComputed extends js.Object {}

trait GroupSelectorEvents extends EventBindings {
  def groupSelected(e: List[Category[_]]): Unit
}

object GroupSelectorEvents {
  def apply(
      bindings: EventBindings = EventBindings(),
      groupSelected: js.UndefOr[js.Function1[List[Category[_]], Unit]]
  ): GroupSelectorEvents = {
    bindings
      .asInstanceOf[js.Dynamic]
      .updateDynamic("groupSelected")(groupSelected)
    val groupSelectorBinding = bindings.asInstanceOf[GroupSelectorEvents]
    groupSelectorBinding
  }
}
