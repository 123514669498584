package com.sludg.client.pages

import com.sludg.client.components.scheduling.ParentScreen.{ParentScreenEvents, ParentScreenProps}
import com.sludg.client.components.scheduling.ParentScreen
import com.sludg.model.Models.AnalyticsConfig
import cron4s.expr.CronExpr
import monix.execution.Scheduler.Implicits.global
import scala.scalajs.js.UndefOr
import com.sludg.client.AnalyticsApp
import com.sludg.helpers.AppSetup.{VtslAppProps, VtslAppScopedSlots}
import com.sludg.models.Config.AppLink
import com.sludg.util.models.SilhouetteModels.Tenant
import com.sludg.vue.RenderHelpers._
import com.sludg.vue.{EventBindings, RenderOptions, Vue, _}
import com.sludg.Security
import com.sludg.auth0.SludgToken
import com.sludg.helpers.LoadingFuture
import com.sludg.services.ApiCalls
import com.sludg.util.models.RoleModels.{AssignedRoles, Role}
import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.vuetify.VuetifyComponents.{vCardText, vContainer, vIcon}
import org.log4s.getLogger

import scala.scalajs.js

object SchedulingPage {

  private[this] val logger = getLogger

  def schedulingPageRenderer(registrationName: String) =
    namedTag[VueProps, EventBindings, ScopedSlots]("SchedulingPage")

  val analyticsApp = AnalyticsApp.analyticsAppRenderer("AnalyticsApp")
  val schedulingApp = ParentScreen.parentScreenRenderer("SchedulingApp")

  def addScheduleApp(
      tenantId: UndefOr[Int]
  ) = {
    schedulingApp(
      RenderOptions(
        props = Some(ParentScreenProps(tenantId))
      )
    )
  }

  def schedulingPageComponent(
      config: AnalyticsConfig,
      apiCalls: ApiCalls,
      security: Security,
      loader: Vue,
      otherApps: List[AppLink]
  )(implicit token: SludgToken) = {
    VueComponent.builder
      .withData(new SchedulingPageData())
      .withMethods(new SchedulingPageMethods)
      .build(
        created = js.defined(s => {
          token.subscribers.headOption match {
            case Some(user) if user.admin || token.isSuperUser => setLoadingOptions(s, true)
            case Some(user) if !user.admin =>
              LoadingFuture.withLoading(
                loader,
                apiCalls.getUserRoles(user.tid.toInt, user.sid)(implicitly, token).map {
                  case Right(Some(AssignedRoles(_, roles)))
                      if roles.contains(Role.AnalyticsAdmin) =>
                    setLoadingOptions(s, true)
                  case _ => setLoadingOptions(s, false)
                }
              )
            case _ => div("")
          }

        }),
        components = js.defined(
          js.Dynamic.literal(
            "AnalyticsApp" -> AnalyticsApp
              .analyticsAppComponent(apiCalls, security, loader, otherApps),
            "SchedulingApp" -> ParentScreen.parentScreenComponent(config, apiCalls, loader)
          )
        ),
        templateOrRender = Right((p, renderer) => {
          analyticsApp(
            RenderOptions(
              props = Some(new VtslAppProps(title = js.defined("Scheduling"), token = Some(token))),
              scopedSlots = Some(
                new VtslAppScopedSlots {
                  override val default: js.UndefOr[js.Function1[Option[Tenant], VNode]] =
                    js.defined {
                      case Some(tenant) if (p.isUserChecked && p.adminAccess) =>
                        div(addScheduleApp(tenant.id)).render(renderer)
                      case Some(_) if (p.isUserChecked && !p.adminAccess) =>
                        accessRestricted(renderer)
                      case _ => div(nothing).render(renderer)
                    }
                }
              )
            )
          ).render(renderer)
        })
      )
  }

  private def accessRestricted(renderer: CreateElement) = {
    div(
      vContainer(
        vCardText(
          "Access to Scheduled reports requires administrator privileges.",
          RenderOptions(`class` = List(Left("headline")))
        ),
        p(
          RenderOptions(style = Some(js.Dynamic.literal("padding-left" -> "16px"))),
          "Please talk to your administrator if you believe this to be an error."
        ),
        p(
          RenderOptions(style = Some(js.Dynamic.literal("padding-left" -> "16px"))),
          "Your administrator can grant you access through the User Portal."
        )
      )
    ).render(renderer)
  }

  private def setLoadingOptions(
      p: SchedulingPageData,
      adminAccess: Boolean,
      isUserChecked: Boolean = true
  ): Unit = {
    p.adminAccess = adminAccess
    p.isUserChecked = isUserChecked
  }

}

class SchedulingPageData extends js.Object {
  var emails: Option[List[String]] = None
  var cronEx: Option[CronExpr] = None
  var selectedReport: Option[Int] = None

  var adminAccess = false
  var isUserChecked = false
}

class SchedulingPageMethods extends js.Object {}
