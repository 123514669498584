package com.sludg.client.components.tables

import com.sludg.client.components.tables.GroupTableRenderFunctions._
import com.sludg.client.components.tables.GroupingTableModels._
import com.sludg.scalajs.DynamicHelper
import com.sludg.util.PresenterSyntax._
import com.sludg.util.ApiModelPresenters._
import com.sludg.util.RootProjection
import com.sludg.util.models.GroupingModels
import com.sludg.util.models.GroupingModels.RootReportData
import com.sludg.util.models.ReportModels.{Filter, ReportColumn}
import com.sludg.util.models.ReportModels.Sort.{Ascending, Descending, Neutral}
import com.sludg.vue._
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components._
import org.log4s._

import scala.scalajs.js
import scala.scalajs.js.JSConverters._

object GroupingDataTable {

  private[tables] val logger = getLogger

  import com.sludg.vue.RenderHelpers._

  type GroupingDataTableComponent =
    VueComponent[_ <: DataDisplayerProps, _ <: Slots, _ <: ScopedSlots]
      with DataDisplayerData
      with DataDisplayerMethods
      with js.Object
      with DataDisplayerProps

  def groupingDataTableRenderer(registrationName: String) =
    namedTag[DataDisplayerProps, EventBindings, ScopedSlots]("GroupingDataTable")

  def groupingDataTableComponent() = {
    VueComponent.builder
      .withData(new DataDisplayerData())
      .withProps(
        js.Array("projection", "projectionData", "preSelectedHeaders", "filters")
          .asInstanceOf[DataDisplayerProps]
      )
      .withMethods(new DataDisplayerMethods)
      .build(
        updated = js.defined(c => {
          logger.info("GroupingDataTable Updated")

        }),
        created = js.defined(component => {
          logger.info("Grouping table created")
          val projData = component.projectionData
          val tableContents: TableContents = DataDisplayerUtil.dataToTableContents(projData)
          component.tableContents = tableContents

          val rootTableHead = component.projectionData.subGroups.head.categoryData.category
          component.defaultRootHead = List(
            HeaderWithGrouping(
              Some(rootTableHead.present),
              Some(rootTableHead.present),
              Some("left"),
              Some(true),
              Some(Neutral)
            )
          )
          component.rootTableHead = List(
            HeaderWithGrouping(
              Some(rootTableHead.present),
              Some(rootTableHead.present),
              Some("left"),
              Some(true),
              Some(Neutral)
            )
          )
        }),
        watch = new DataDisplayerWatcher(),
        templateOrRender = Right((component, renderer) => {
          if (component.rawTableData.nonEmpty) {

            div(
              vDivider,
              vDivider,
              headerSelector(component, renderer),
              vDivider,
              vDataTable(
                RenderOptions[
                  VDataTableProps[GroupingTableItem, HeaderWithGrouping],
                  EventBindings,
                  VDataTableScopedSlots[GroupingTableItem, HeaderWithGrouping]
                ](
                  props = Some(
                    VDataTableProps[GroupingTableItem, HeaderWithGrouping](
                      headers = Some(component.rootTableHead ++ component.currentlySelectedHeaders),
                      items = Some(component.itemsData),
                      `next-icon` = Some("arrow_downward"),
                      `prev-icon` = Some("arrow_upward")
                    )
                  ),
                  scopedSlots = Some(
                    new VDataTableScopedSlots(
                      items = js.defined(props => {
                        tr(
                          td(
                            if (props.item.categoryValue.contains("Extension"))
                              props.item.categoryValue.replaceAll("Extension:", "")
                            else props.item.categoryValue
                          ),
                          tableRowData(props, component)
                        ).render(renderer)
                      }),
                      headers = js.defined(props => {
                        tr(
                          props.headers.map(tableHeadersWithGrouping => {
                            tableHeaders(tableHeadersWithGrouping, component)
                          })
                        ).render(renderer)
                      })
                    )
                  )
                )
              )
            ).render(renderer)
          } else {
            div(nothing).render(renderer)
          }
        })
      )
  }
}

trait DataDisplayerProps extends VueProps {
  val projection: RootProjection[_]
  val projectionData: RootReportData
  val preSelectedHeaders: List[ReportColumn]
  val filters: js.Array[Filter]
}

object DataDisplayerProps {

  def apply(
      projection: RootProjection[_],
      projectionData: RootReportData,
      preSelectedHeaders: List[ReportColumn] = Nil,
      filters: List[Filter] = Nil
  ): DataDisplayerProps = {

    js.Dynamic
      .literal(
        "projection" -> projection.asInstanceOf[js.Any],
        "projectionData" -> projectionData.asInstanceOf[js.Any],
        "preSelectedHeaders" -> preSelectedHeaders.asInstanceOf[js.Object],
        "filters" -> filters.toJSArray
      )
      .asInstanceOf[DataDisplayerProps]
  }
}

trait DataTableProps extends VueProps {
  val headers: js.UndefOr[js.Array[HeaderWithGrouping]] = js.undefined
  val items: js.UndefOr[js.Array[GroupingTableItem]] = js.undefined
}

object DataTableProps {

  def apply(
      headers: Option[List[HeaderWithGrouping]] = None,
      items: Option[List[GroupingTableItem]] = None
  ): DataTableProps = {

    DynamicHelper.buildViaDynamic(
      "headers" -> headers.map(_.toJSArray).orUndefined,
      "items" -> items.map(_.toJSArray).orUndefined
    )
  }
}

class DataDisplayerData extends js.Object {
  var itemsData: List[GroupingTableItem] = List[GroupingTableItem]()
  var defaultRootHead: List[HeaderWithGrouping] = List[HeaderWithGrouping]()
  var currentlySelectedHeaders: List[HeaderWithGrouping] = List[HeaderWithGrouping]()
  var rootTableHead: List[HeaderWithGrouping] = List[HeaderWithGrouping]()
  var selectableHeaderPool: List[HeaderWithGrouping] = List[HeaderWithGrouping]()
  var defaultSelectedHeaders: List[HeaderWithGrouping] = List[HeaderWithGrouping]()
  var rawTableData: List[ExtractedTableData] = List()
  var rawTableHeaders: List[ExtractedTableHeader] = List()
  var sortBy: js.UndefOr[TableColumnsWithSorting] = js.undefined
  var descending: Boolean = false
  var showAllHeaders: Boolean = false
  var loading: Boolean = false
  var updatedTableHeaders = List[HeaderWithGrouping]()
  var tableContents: js.UndefOr[TableContents] = js.undefined
  var isItSameReport: Boolean = false

}

trait DataDisplayerEvents extends EventBindings {
  def headersSelected(e: List[TableColumnsWithSorting]): Unit
  def rootTableHead(e: GroupingModels.Category[_]): Unit
}

object DataDisplayerEvents {
  def apply(
      bindings: EventBindings = EventBindings(),
      headersSelected: js.UndefOr[js.Function1[List[TableColumnsWithSorting], Unit]]
  ): DataDisplayerEvents = {
    bindings.asInstanceOf[js.Dynamic].updateDynamic("headersSelected")(headersSelected)
    val selectedBindings = bindings.asInstanceOf[DataDisplayerEvents]
    selectedBindings
  }
}
