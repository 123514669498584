package com.sludg.model

import com.sludg.models.Config.{
  AlmostAuth0Config,
  ApiConfig,
  AppLink,
  Config,
  ScheduledReportConfig,
  VueColors
}
import cron4s.expr.CronExpr
import play.api.libs.json.{Format, Json}

import scala.scalajs.js.annotation.JSExportAll

object Models {

  @JSExportAll
  case class JWTSub(sid: Int, tid: String, ext: String, admin: Boolean)

  case class ReportFilter(id: String, name: String, filterType: String)

  case class ReportSchedule(
      id: Int,
      reportId: Int,
      tenantId: Int,
      schedule: CronExpr,
      emails: List[String]
  )

  case class JWTSubscribers(subscribers: List[SubscriberWithAdminStatus])

  case class SubscriberWithAdminStatus(sid: Int, tid: String, ext: String, admin: Boolean)

  case class Report(id: Int, tenantId: Int, name: String)

  import com.sludg.json.Deserializer._

  implicit val analyticsConfigFormat: Format[AnalyticsConfig] = Json.format[AnalyticsConfig]

  case class AnalyticsConfig(
      auth0: AlmostAuth0Config,
      vuetify: VueColors,
      apiConfig: ApiConfig,
      scheduledReport: ScheduledReportConfig,
      otherApps: List[AppLink],
      logLevel: Option[org.log4s.LogLevel]
  ) extends Config

}
