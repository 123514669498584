package com.sludg.helpers

import com.sludg.client.components.ProjectionChoicesWithData
import com.sludg.util.models.CallModels.ClassOfService.OperatorAssisted
import com.sludg.util.models.GroupingModels
import com.sludg.util.models.GroupingModels.{Category, CategoryData}

object ChartFormatters {
//Used in multiple graphs but bespoke formatting to fit them, not general enough for MessageFormatter

  def formatShortGraphText(category: GroupingModels.CategoryData[_]): String = {
    category.category match {
      case Category.Answer =>
        category.data match {
          case true => "Answered"
          case false => "Not Answered"
        }
      case Category.ClassOfService =>
        category.data match {
          case OperatorAssisted => "Operator Assisted"
          case _ => category.data.toString
        }
      case Category.DayOfWeek =>
        category.data match {
          case 0 => "Sunday"
          case 1 => "Monday"
          case 2 => "Tuesday"
          case 3 => "Wednesday"
          case 4 => "Thursday"
          case 5 => "Friday"
          case 6 => "Saturday"
        }
      case Category.Direction => category.data.toString
      case Category.Month =>
        category.data match {
          case 1 => "Jan"
          case 2 => "Feb"
          case 3 => "Mar"
          case 4 => "Apr"
          case 5 => "May"
          case 6 => "Jun"
          case 7 => "Jul"
          case 8 => "Aug"
          case 9 => "Sep"
          case 10 => "Oct"
          case 11 => "Nov"
          case 12 => "Dec"
        }
      case Category.HourOfDay => s"${category.data.toString}:00"
      case Category.Day => s"${category.data.toString}"
      case Category.Week => s"Week: ${category.data.toString}"
      case Category.Subscriber =>
        category.data match {
          case Some(sub) => sub.toString
          case None => "No User"
        }
      case Category.LastExtension =>
        category.data match {
          case Some(ext) => ext.toString
          case None => "No Extension"
          //Commented out for now in case we want to revert back to no names
          //case Some(ext) => ext.toString.replaceAll("[^\\d.]", "")

        }
      case Category.CallGroup =>
        category.data match {
          case Some(cg) => cg.toString
          case None => "No Call Group"
        }
      case Category.AutoAttendant =>
        category.data match {
          case Some(aa) => aa.toString
          case None => "No Auto Attendant"
        }
      case Category.Termination =>
        category.data match {
          case Category.AutoAttendant => "Auto Attendant"
          case _ => category.data.toString
        }
      case Category.DialledNumber =>
        category.data match {
          case Some(dn) => dn.toString
          case None => "No Number"
        }

      case _ => category.data.toString
    }
  }

  def secondsToTime(seconds: Double): String = {
    //Must be an Int or calculation doesn't work
    val x = seconds.toInt
    val s = x % 60
    val m = (x / 60) % 60
    val h = x / 60 / 60
    val time = "%02.0f:%02.0f:%02.0f".format(h, m, s)
    time
  }

  def formatTitle(fullData: ProjectionChoicesWithData[_]): String = {
    var description: String = ""
    description = fullData.upperButton match {
      case Some("Total") => "Number of calls "
      case _ =>
        fullData match {
          case ProjectionChoicesWithData(_, Some("Totals"), Some("SUM"), _) => s"Total Durations "
          case ProjectionChoicesWithData(_, Some("Totals"), Some("MIN"), _) => s"Min Durations "
          case ProjectionChoicesWithData(_, Some("Totals"), Some("MAX"), _) => s"Max Durations "
          case ProjectionChoicesWithData(_, Some("Totals"), Some("AVG"), _) => s"Avg Durations "
          case ProjectionChoicesWithData(_, Some("Talk"), Some("SUM"), _) =>
            s"Total Talk Durations "
          case ProjectionChoicesWithData(_, Some("Talk"), Some("MIN"), _) => s"Min Talk Durations "
          case ProjectionChoicesWithData(_, Some("Talk"), Some("MAX"), _) => s"Max Talk Durations "
          case ProjectionChoicesWithData(_, Some("Talk"), Some("AVG"), _) => s"Avg Talk Durations "
          case ProjectionChoicesWithData(_, Some("Ring"), Some("SUM"), _) =>
            s"Total Ring Durations "
          case ProjectionChoicesWithData(_, Some("Ring"), Some("MIN"), _) => s"Min Ring Durations "
          case ProjectionChoicesWithData(_, Some("Ring"), Some("MAX"), _) => s"Max Ring Durations "
          case ProjectionChoicesWithData(_, Some("Ring"), Some("AVG"), _) => s"Avg Ring Durations "
          case ProjectionChoicesWithData(_, Some("Totals"), None, _) => s"Total Durations "
          case ProjectionChoicesWithData(_, Some(x), None, _) => s"Total $x Durations "
          case ProjectionChoicesWithData(_, None, None, _) => "Total Durations "
          case ProjectionChoicesWithData(_, Some(x), Some(y), _) =>
            s"${y.toLowerCase.capitalize}  ${x.toLowerCase} durations "
          case ProjectionChoicesWithData(upperButton, middleButton, lowerButton, _) =>
            def format(o: Option[String], orElse: String = " ") =
              o.map(_.toLowerCase.capitalize + " ").getOrElse(orElse)
            s"${format(upperButton)}${format(middleButton)}${format(lowerButton, " ")}"
        }
    }
    description
  }

}
