package com.sludg.client.components.scheduling.period

import java.time.LocalTime
import java.time.format.DateTimeFormatter

import com.sludg.client.components.scheduling.helpers.CronConverter
import com.sludg.client.components.scheduling.helpers.CronConverter.DailySelection.{
  EveryDay,
  SpecificDays
}
import com.sludg.client.components.scheduling.helpers.CronConverter.Period.Daily
import com.sludg.client.components.scheduling.helpers.CronConverter.{
  DailySelection,
  Day,
  Period,
  buildCron
}
import com.sludg.vue.RenderHelpers._
import com.sludg.vue._
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components._
import cron4s.Cron
import cron4s.expr.CronExpr
import org.scalajs.dom.raw.Event

import scala.scalajs.js
import org.log4s._

import scala.collection.mutable.ListBuffer
import scala.scalajs.js.UndefOr

object DailyPage {

  private[period] val logger = getLogger

  type DailyPageComponent = VueComponent[_ <: DailyPageProps, _ <: Slots, _ <: ScopedSlots]
    with DailyPageData
    with DailyPageMethods
    with js.Object
    with DailyPageProps

  def dailyPageRenderer(registrationName: String) =
    namedTag[DailyPageProps, DailyPageEvents, ScopedSlots](registrationName)

  def dailyPageComponent() = {

    val beforeClicked = Some(
      js.Dynamic.literal(
        "color" -> "black",
        "left" -> "0",
        "right" -> "0",
        //      "opacity" -> "0.12",
        "content" -> "''",
        "width" -> "100%",
        "max-width" -> "160px",
        "max-height" -> "80px"
      )
    )

    val afterClicked = Some(
      js.Dynamic.literal(
        "color" -> "white",
        "background-color" -> "#82b1ff",
        "left" -> "0",
        "right" -> "0",
        "content" -> "''",
        "width" -> "100%",
        "max-width" -> "160px",
        "max-height" -> "80px",
        "border" -> "2px #82b1ff",
        "border-radius" -> "2px"
      )
    )

    val tableRO = RenderOptions(
      style = Some(
        js.Dynamic.literal(
          "width" -> "100%",
          "height" -> "200px"
        )
      )
    )

    VueComponent.builder
      .withData(new DailyPageData)
      .withMethods(new DailyPageMethods())
      .withProps(DailyPageProps())
      .build(
        watch = new DailyPageWatcher(),
        components = js.Dynamic.literal(),
        templateOrRender = Right((component, renderer) => {
          div(
            vCard(
              VCardProps(`max-width` = Some(Left(650))),
              p("Select the days you would like to receive the report."),
              vLayout(
                vFlex(
                  vLayout(component.timeSelector(component)),
                  vLayout(
                    table(
                      tableRO,
                      tr(
                        td(
                          vButton(
                            "Mon",
                            RenderOptions(
                              props = Some(VButtonProps(flat = Some(true))),
                              on = Some(
                                EventBindings(
                                  click = js.defined(e => {
                                    component.dailySelection = SpecificDays
                                    component.dayBooleansList(0) = !component.dayBooleansList(0)
                                    component.updateScheduleEvent(component)
                                  })
                                )
                              ),
                              style = if (component.dayBooleansList(0)) {
                                afterClicked
                              } else {
                                beforeClicked
                              }
                            )
                          )
                        ),
                        td(
                          vButton(
                            "Tue",
                            RenderOptions(
                              props = Some(VButtonProps(flat = Some(true))),
                              on = Some(
                                EventBindings(
                                  click = js.defined(e => {
                                    component.dailySelection = SpecificDays
                                    component.dayBooleansList(1) = !component.dayBooleansList(1)
                                    component.updateScheduleEvent(component)
                                  })
                                )
                              ),
                              style = if (component.dayBooleansList(1)) {
                                afterClicked
                              } else {
                                beforeClicked
                              }
                            )
                          )
                        ),
                        td(
                          vButton(
                            "Wed",
                            RenderOptions(
                              props = Some(VButtonProps(flat = Some(true))),
                              on = Some(
                                EventBindings(
                                  click = js.defined(e => {
                                    component.dailySelection = SpecificDays
                                    component.dayBooleansList(2) = !component.dayBooleansList(2)
                                    component.updateScheduleEvent(component)
                                  })
                                )
                              ),
                              style = if (component.dayBooleansList(2)) {
                                afterClicked
                              } else {
                                beforeClicked
                              }
                            )
                          )
                        )
                      ),
                      tr(
                        td(
                          vButton(
                            "Thu",
                            RenderOptions(
                              props = Some(VButtonProps(flat = Some(true))),
                              on = Some(
                                EventBindings(
                                  click = js.defined(e => {
                                    component.dailySelection = SpecificDays
                                    component.dayBooleansList(3) = !component.dayBooleansList(3)
                                    component.updateScheduleEvent(component)
                                  })
                                )
                              ),
                              style = if (component.dayBooleansList(3)) {
                                afterClicked
                              } else {
                                beforeClicked
                              }
                            )
                          )
                        ),
                        td(
                          vButton(
                            "Fri",
                            RenderOptions(
                              props = Some(VButtonProps(flat = Some(true))),
                              on = Some(
                                EventBindings(
                                  click = js.defined(e => {
                                    component.dailySelection = SpecificDays
                                    component.dayBooleansList(4) = !component.dayBooleansList(4)
                                    component.updateScheduleEvent(component)
                                  })
                                )
                              ),
                              style = if (component.dayBooleansList(4)) {
                                afterClicked
                              } else {
                                beforeClicked
                              }
                            )
                          )
                        ),
                        td(
                          vButton(
                            "Sat",
                            RenderOptions(
                              props = Some(VButtonProps(flat = Some(true))),
                              on = Some(
                                EventBindings(
                                  click = js.defined(e => {
                                    component.dailySelection = SpecificDays
                                    component.dayBooleansList(5) = !component.dayBooleansList(5)
                                    component.updateScheduleEvent(component)
                                  })
                                )
                              ),
                              style = if (component.dayBooleansList(5)) {
                                afterClicked
                              } else {
                                beforeClicked
                              }
                            )
                          )
                        )
                      ),
                      tr(
                        td(
                          vButton(
                            "Sun",
                            RenderOptions(
                              props = Some(VButtonProps(flat = Some(true))),
                              on = Some(
                                EventBindings(
                                  click = js.defined(e => {
                                    component.dailySelection = SpecificDays
                                    component.dayBooleansList(6) = !component.dayBooleansList(6)
                                    component.updateScheduleEvent(component)
                                  })
                                )
                              ),
                              style = if (component.dayBooleansList(6)) {
                                afterClicked
                              } else {
                                beforeClicked
                              }
                            )
                          )
                        ),
                        td(
                          vButton(
                            "Mon-Fri",
                            RenderOptions(
                              props = Some(VButtonProps(flat = Some(true))),
                              on = Some(
                                EventBindings(
                                  click = js.defined(e => {
                                    //if the first false is on saturday, and sunday is also false
                                    if (
                                      component.dayBooleansList.indexWhere(_ == false) == 5 &&
                                      !component.dayBooleansList.last
                                    ) {

                                      component.dayBooleansList(0) = true
                                      for (i <- 1 to component.dayBooleansList.length - 1)
                                        component.dayBooleansList(i) = false

                                      component.periodOfDays = 1
                                      component.dailySelection = SpecificDays

                                      component.updateScheduleEvent(component)
                                    } else {
                                      for (i <- 0 to component.dayBooleansList.length - 1) {
                                        if (i < 5) {
                                          component.dayBooleansList(i) = true
                                        } else component.dayBooleansList(i) = false
                                      }

                                      component.periodOfDays = 1
                                      component.dailySelection = SpecificDays

                                      component.updateScheduleEvent(component)

                                    }
                                  })
                                )
                              ),
                              style =
                                if (
                                  component.dayBooleansList.indexWhere(d => d) == 5 &&
                                  component.dayBooleansList.last
                                ) {
                                  afterClicked
                                } else {
                                  beforeClicked
                                }
                            )
                          )
                        ),
                        td(
                          vButton(
                            "Every Day",
                            RenderOptions(
                              props = Some(VButtonProps(flat = Some(true))),
                              on = Some(
                                EventBindings(
                                  click = js.defined(e => {
                                    if (component.dayBooleansList.forall(_ == true)) {

                                      component.dayBooleansList(0) = true
                                      for (i <- 1 to component.dayBooleansList.length - 1)
                                        component.dayBooleansList(i) = false
                                      component.periodOfDays = 1
                                      component.dailySelection = SpecificDays

                                      component.updateScheduleEvent(component)
                                    } else {
                                      for (i <- 0 to component.dayBooleansList.length - 1)
                                        component.dayBooleansList(i) = true
                                      component.periodOfDays = 1
                                      component.dailySelection = EveryDay

                                      component.updateScheduleEvent(component)
                                    }
                                  })
                                )
                              ),
                              style = if (component.dayBooleansList.forall(_ == true)) {
                                afterClicked
                              } else {
                                beforeClicked
                              }
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          ).render(renderer)
        })
      )
  }

  trait DailyPageEvents extends EventBindings {
    def test(e: Event): Unit
  }

  object DailyPageEvents {
    def apply() = {
      "".asInstanceOf[DailyPageEvents]
    }
  }

  class DailyPageMethods extends js.Object {

    def generateSchedule(component: DailyPageComponent) = {
      /* cronExpression */
      component.schedule = component.dailySelection match {
        case EveryDay => {
          buildCron(
            "0",
            component.time.getMinute.toString,
            component.time.getHour.toString,
            "?",
            "*",
            "*"
          )
        }
        case SpecificDays =>
          val scheduledDays = component.dayBooleansList

          def isNoDaysSelected: Boolean = scheduledDays.forall(_ == false)

          def processDays: String = {
            scheduledDays.zipWithIndex
              .flatMap {
                case (day, i) =>
                  if (day) {
                    i.toString
                  } else {
                    ""
                  }
              }
              .mkString(",")
          }

          val daysProcessed: String =
            if (isNoDaysSelected) {
              "0"
            } else {
              processDays
            }

          buildCron(
            "0",
            component.time.getMinute.toString,
            component.time.getHour.toString,
            "?",
            "*",
            daysProcessed
          )
      }

      logger.debug("CronEx generated: " + component.schedule)

    }

    def timeSelector(
        component: DailyPageComponent
    ): RenderHelpers.NodeRenderer[VMenuProps, EventBindings, ScopedSlots] = {
      vMenu(
        vTextField(
          js.Dynamic
            .literal(
              "slot" -> "activator",
              "props" -> js.Dynamic
                .literal(
                  "readonly" -> true,
                  "label" -> "Arrival time",
                  "value" -> component.time.toString
                )
                .asInstanceOf[VTextFieldProps]
            )
            .asInstanceOf[RenderOptions[VTextFieldProps, EventBindings, ScopedSlots]]
        ),
        vTimePicker(
          RenderOptions[VTimePickerProps, EventBindings, ScopedSlots](
            props = Some(
              js.Dynamic
                .literal(
                )
                .asInstanceOf[VTimePickerProps]
            ),
            on = component.timePickerEvent(component)
          )
        ),
        vCard(
          vButton(
            "Confirm",
            RenderOptions(on = Some(EventBindings(click = js.defined(e => {
              component.showMenu = false
            }))))
          ),
          RenderOptions(
            style = Some(
              js.Dynamic.literal(
                "text-align" -> "center"
              )
            )
          )
        )
      )(
        RenderOptions[VMenuProps, EventBindings, ScopedSlots](
          props = Some(
            VMenuProps(
              value = Some(component.showMenu),
              closeOnClick = Some(true),
              closeOnContentClick = Some(false)
            )
          ),
          on = Some(
            EventBindings(
              input = js.defined(e => {
                component.showMenu = true
              })
            )
          )
        )
      )
    }

    def timePickerEvent(data: DailyPageComponent) = {
      Some(EventBindings(input = js.defined(e => {
        val timeFormatter = DateTimeFormatter.ISO_LOCAL_TIME
        val arrivalTime = LocalTime.parse(e.toString, timeFormatter)
        data.time = arrivalTime
        updateScheduleEvent(data)
      })))
    }

    /* daily */
    def dailyNumberOfDaysHandler(component: DailyPageComponent): Option[EventBindings] = {
      Some(
        EventBindings(
          change = js.defined(e => {
            logger.debug("Event: [WeeklyDailySelection]--- Period of days = " + e.toString)

            e.toString match {
              case "Every day" => component.periodOfDays = 1
              case "Some days" => component.dailySelection = SpecificDays
            }
            updateScheduleEvent(component)
          })
        )
      )
    }

    def updateScheduleEvent(component: DailyPageComponent) = {
      generateSchedule(component)
      component.$emit("updateSchedule", component.schedule.toString)
    }

  }

  trait DailyPageProps extends VueProps {
    val savedCron: js.UndefOr[CronExpr] = js.undefined
    var selectedTenant: js.UndefOr[Int] = js.undefined
  }

  object DailyPageProps {
    def apply(
        savedCron: js.UndefOr[CronExpr] = js.undefined,
        selectedTenant: js.UndefOr[Int] = js.undefined
    ): DailyPageProps = {
      js.Dynamic
        .literal(
          "savedCron" -> savedCron.asInstanceOf[js.Any],
          "selectedTenant" -> selectedTenant.asInstanceOf[js.Any]
        )
        .asInstanceOf[DailyPageProps]
    }
  }

  class DailyPageWatcher extends js.Object {

    def selectedTenant(newTenant: Int, oldValue: Int): Unit = {
      if (newTenant != oldValue) {
        logger.info("ScheduleTableWatcher: New Tenant Selected! " + newTenant.toString())

        val c = this.asInstanceOf[DailyPageComponent]

        c.showMenu = false

        /* cronExpression */
        c.schedule = Cron.unsafeParse("0 10 12 ? * *")

        /* time */
        c.time = LocalTime.NOON

        /* daily */
        c.dailySelection = EveryDay
        c.periodOfDays = 1

        c.dayBooleansList = ListBuffer(
          true, false, false, false, false, false, false
        )
      }
    }

    def savedCron(newVal: js.UndefOr[CronExpr], oldVal: js.UndefOr[CronExpr]): Unit = {
      if (newVal.isDefined) {
        val comp = this.asInstanceOf[DailyPageComponent]
        val data = this.asInstanceOf[DailyPageData]

        cronToDayPicker(newVal, comp, data)
      }
    }
  }

  private def cronToDayPicker(
      newVal: UndefOr[CronExpr],
      comp: DailyPageComponent,
      data: DailyPageData
  ) = {
    val days = newVal.get.daysOfWeek.toString().split(",").toList

    if (days.contains("*")) {
      for (i <- comp.dayBooleansList.indices) comp.dayBooleansList(i) = true
    } else {
      days.map(dayIndex => data.dayBooleansList(dayIndex.toInt) = true)
    }

    val timeFormatter = DateTimeFormatter.ISO_LOCAL_TIME
    val arrivalTime = LocalTime.parse(
      CronConverter.addLeadingZero(newVal.get.timePart.hours.toString()) + ":"
        + CronConverter.addLeadingZero(newVal.get.timePart.minutes.toString()),
      timeFormatter
    )
    data.time = arrivalTime
    comp.updateScheduleEvent(comp)
  }

  class DailyPageData extends js.Object {
    var showMenu = false

    /* cronExpression */
    var schedule: CronExpr = Cron.unsafeParse("0 10 12 ? * *")

    /* time */
    var time: LocalTime = LocalTime.NOON

    /* daily */
    var dailySelection: DailySelection = EveryDay
    var periodOfDays = 1

    var dayBooleansList: ListBuffer[Boolean] = ListBuffer(
      true, false, false, false, false, false, false
    )

  }

}
