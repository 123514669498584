package com.sludg.client.components.scheduling.helpers

import com.sludg.client.components.scheduling.helpers.CronConverter.DailySelection.{
  EveryDay,
  SpecificDays
}
import com.sludg.client.components.scheduling.helpers.CronConverter.MonthlySelection.{
  EveryMonth,
  EveryPeriodOfMonths,
  SpecificMonths
}
import com.sludg.client.components.scheduling.helpers.CronConverter.Period.{
  Daily,
  Monthly,
  Periodically
}
import cron4s.Cron
import cron4s.expr.CronExpr
import scala.annotation.nowarn

object CronConverter {

  private[this] val logger = org.log4s.getLogger

  def cronToTypes(cronExpr: CronExpr): (Period, SpecificPeriod) = {
    val minutes = cronExpr.minutes.toString()
    val hours = cronExpr.hours.toString()
    val datesOfMonth = cronExpr.daysOfMonth.toString()
    val daysOfWeek = cronExpr.daysOfWeek.toString()
    val months = cronExpr.months.toString()

    def isHourlyDigit: Boolean = hours forall Character.isDigit

    def isMinuteDigit: Boolean = minutes forall Character.isDigit

    def isMinuteZero: Boolean = minutes == "0"

    def isHourlyZero: Boolean = hours == "0"

    def isASpecificDayOfWeek: Boolean = daysOfWeek forall Character.isDigit

    def isEveryDayOfWeek: Boolean = daysOfWeek == "*"

    def isASetOfDaysOfWeek: Boolean = daysOfWeek.exists(_.isDigit) && daysOfWeek.contains(",")

    def isASpecificDate: Boolean = datesOfMonth forall Character.isDigit

    def isASetOfDates: Boolean = datesOfMonth.exists(_.isDigit) && datesOfMonth.contains(",")

    def isEveryMonth: Boolean = months == "*"

    def isASpecificMonth: Boolean = months forall Character.isDigit

    def isASetOfMonths: Boolean = months.exists(_.isDigit) && months.contains(",")

    def isEveryNumberOfMonths: Boolean =
      months.contains("*") && months.contains("/") && months.exists(_.isDigit)

    def isEveryNumberOfMinutes: Boolean =
      minutes.contains("*") && minutes.contains("/") && minutes.exists(_.isDigit)

    def isEveryNumberOfHours: Boolean =
      hours.contains("*") && hours.contains("/") && hours.exists(_.isDigit)

    val cronLogicField: List[Boolean] = List(
      isEveryNumberOfMinutes,
      isMinuteDigit,
      isHourlyDigit,
      isMinuteZero,
      isHourlyZero,
      isEveryNumberOfHours,
      isASpecificDayOfWeek,
      isEveryDayOfWeek,
      isASetOfDaysOfWeek,
      isEveryMonth,
      isASpecificMonth,
      isASetOfMonths,
      isEveryNumberOfMonths,
      isASpecificMonth,
      isEveryMonth,
      isASetOfMonths,
      isASpecificDate,
      isASetOfDates
    )

    (cronLogicField match {
      case List(true, _, _, _, _, true, _, _, _, _, _, _, _, _, _, _, _, _) =>
        (Periodically, Periodically)
      case List(_, false, false, false, false, _, _, _, _, _, _, _, _, _, _, _, _, _) =>
        (Periodically, Periodically)
      case List(_, _, _, _, true, _, true, _, _, _, _, _, _, _, _, _, _, _) =>
        (Periodically, Periodically)
      case List(_, true, _, true, _, true, _, _, _, _, _, _, _, _, _, _, _, _) =>
        (Periodically, Periodically)
      case List(_, _, _, _, _, _, _, true, _, true, _, _, _, _, _, _, _, _) => (Daily, EveryDay)
      case List(_, _, _, _, _, _, _, true, _, _, true, _, _, _, _, _, _, _) => (Daily, Daily)
      case List(_, _, _, _, _, _, _, true, _, _, _, true, _, _, _, _, _, _) => (Daily, Daily)
      case List(_, _, _, _, _, _, _, _, true, true, _, _, _, _, _, _, _, _) => (Daily, SpecificDays)
      case List(_, _, _, _, _, _, true, _, _, true, _, _, _, _, _, _, _, _) => (Daily, SpecificDays)
      case List(_, _, _, _, _, _, _, _, _, _, _, _, true, _, _, _, true, _) =>
        (Monthly, EveryPeriodOfMonths)
      case List(_, _, _, _, _, _, _, _, _, _, _, _, true, _, _, _, _, true) =>
        (Monthly, EveryPeriodOfMonths)
      case List(_, _, _, _, _, _, _, _, _, _, _, _, _, _, true, _, true, _) => (Monthly, EveryMonth)
      case List(_, _, _, _, _, _, _, _, _, _, _, _, _, _, true, _, _, true) => (Monthly, EveryMonth)
      case List(_, _, _, _, _, _, _, _, _, _, _, _, _, true, _, _, true, _) =>
        (Monthly, SpecificMonths)
      case List(_, _, _, _, _, _, _, _, _, _, _, _, _, true, _, _, _, true) =>
        (Monthly, SpecificMonths)
      case List(_, _, _, _, _, _, _, _, _, _, _, _, _, _, _, true, true, _) =>
        (Monthly, SpecificMonths)
      case List(_, _, _, _, _, _, _, _, _, _, _, _, _, _, _, true, _, true) =>
        (Monthly, SpecificMonths)
    }): @nowarn

  }

  def addLeadingZero(day: String): String = if (day.length < 2) "0".concat(day) else day

  def cronToEnglish(cronExpr: CronExpr): String = {
    val hourTime = cronExpr.timePart.hours.toString()
    val minutesTime = cronExpr.timePart.minutes.toString()
    val minutes = cronExpr.minutes.toString()
    val hours = cronExpr.hours.toString()
    val datesOfMonth = cronExpr.daysOfMonth.toString()
    val daysOfWeek = cronExpr.daysOfWeek.toString()
    val months = cronExpr.months.toString()

    val meridiem: Option[String] = if (hours forall Character.isDigit) {
      Some(if (hours.toInt < 12) "AM" else "PM")
    } else {
      None
    }

    def addSuffix(date: Int): String = {
      date % 10 match {
        case 1 if date == 11 => "th"
        case 2 if date == 12 => "th"
        case 3 if date == 13 => "th"
        case 1 => "st"
        case 2 => "nd"
        case 3 => "rd"
        case _ => "th"
      }
    }

    def getDates: String = {
      val dates = datesOfMonth.split(",")
      if (dates.length == 1) {
        datesOfMonth.toString + addSuffix(dates.head.toInt)
      } else {
        (
          dates.view.init.map(date => date + addSuffix(date.toInt))
        ).mkString(", ") + " and " + dates.last + addSuffix(
          dates.last.toInt
        )
      }
    }

    def getMinutesTime(): String =
      (if (minutesTime.length < 2) {
         "0"
       } else {
         ""
       }) + minutesTime + " " + meridiem.getOrElse("")

    val calenderMonths = List(
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    )
    val calenderDays =
      List("Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday")

    def everyMonthOnTheDates: String = "Every month on the " + getDates

    def atACertainTime: String = " at " + hourTime + ":" + getMinutesTime()

    def generateEnglish(scheduleType: Period, scheduleType2: SpecificPeriod): String = {
      scheduleType match {
        case Monthly =>
          (scheduleType2 match {
            case SpecificMonths =>
              months.split(",").length match {
                case 1 => {
                  "Every " + calenderMonths(months.toString.toInt - 1) + " " + getDates
                }
                case 12 => everyMonthOnTheDates
                case _ => {
                  val monthsSelected = months.split(",").map(pos => calenderMonths(pos.toInt - 1))
                  "Every " + monthsSelected.view.init
                    .mkString(", ") + " and " + monthsSelected.last + " on the " + getDates
                }
              }
            case EveryMonth => everyMonthOnTheDates
            case EveryPeriodOfMonths =>
              "Every " + months.split("/")(1) + " months on the " + getDates
            case e =>
              logger.error(s"Subperiod of 'Monthly' was an invalid one: $e")
              "Error"
          }) + atACertainTime
        case Daily =>
          (scheduleType2 match {
            case EveryDay => "Every day"
            case SpecificDays =>
              val days = daysOfWeek.split(",")
              days.length match {
                case 1 => calenderDays(days.head.toInt)
                case 7 => "Every day"
                case _ => {
                  if (days.toList.sorted == List("0", "1", "2", "3", "4").sorted) {
                    "Every Mon-Fri"
                  } else {
                    val daysSelected = days.map(pos => calenderDays(pos.toInt))
                    daysSelected.view.init.mkString(", ") + " and " + daysSelected.last
                  }
                }
              }
            case e =>
              logger.error(s"Subperiod of 'Daily' was an invalid one: $e")
              "Error"
          }) + atACertainTime
        case Periodically =>
          val everyNMinutes: Boolean = minutes != "0" && minutes != "*"
          val everyHour: Boolean = hours == "0" || hours == "*"

          //After every x hours, every x minutes
          if (everyHour) {
            "Every " + minutes.split("/")(1) + " minutes"
          } else {
            if (hours.split("/")(1).toInt == 1) {
              "Every " + hours.split("/")(1) + " hour"
            } else {
              "Every " + hours.split("/")(1) + " hours" +
                (if (everyNMinutes) {
                   " and " + minutes.split("/")(1) + " minutes"
                 } else {
                   ""
                 })
            }
          }
      }
    }

    val scheduleTypes = cronToTypes(cronExpr)
    generateEnglish(scheduleTypes._1, scheduleTypes._2)
  }

  /* Builds the cron expresion */
  def buildCron(
      seconds: String,
      minutes: String,
      hours: String,
      daysOfTheMonth: String,
      months: String,
      daysOfTheWeek: String = "?"
  ): CronExpr = {
    Cron.unsafeParse(s"$seconds $minutes $hours $daysOfTheMonth $months $daysOfTheWeek")
  }

  sealed trait Day

  object Day {

    case object Monday extends Day

    case object Tuesday extends Day

    case object Wednesday extends Day

    case object Thursday extends Day

    case object Friday extends Day

    case object Saturday extends Day

    case object Sunday extends Day

  }

  sealed trait SpecificPeriod

  sealed trait Period extends SpecificPeriod

  object Period {

    case object Monthly extends Period

    case object Daily extends Period

    case object Periodically extends Period

    val scheduleTypes = List(Monthly, Daily, Periodically)
  }

  sealed trait DailySelection extends SpecificPeriod

  object DailySelection {

    case object EveryDay extends DailySelection

    case object SpecificDays extends DailySelection

  }

  sealed trait MonthlySelection extends SpecificPeriod

  object MonthlySelection {

    case object EveryMonth extends MonthlySelection

    case object EveryPeriodOfMonths extends MonthlySelection

    case object SpecificMonths extends MonthlySelection

  }

}
