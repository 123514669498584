package com.sludg.client.pages

import cats.data.EitherT
import cats.implicits._
import com.sludg.FileSaver
import com.sludg.auth0.SludgToken
import com.sludg.client.components._
import com.sludg.client.components.tables.GroupingTableModels.TableColumnsWithSorting
import com.sludg.client.pages.ReportPage._
import com.sludg.helpers.LoadingFuture
import com.sludg.services.ApiCalls
import com.sludg.util.PresenterSyntax._
import com.sludg.util.ApiModelPresenters._
import com.sludg.util.models.GroupingModels.Category
import com.sludg.util.models.ReportModels.Filter.{DateFilter, RelativeDateFilter}
import com.sludg.util.models.ReportModels.ReportColumn
import com.sludg.util.models.SilhouetteModels.Tenant
import com.sludg.vue.RenderHelpers._
import com.sludg.vue._
import com.sludg.vuetify.components.grid.VGridProps
import com.sludg.vuetify.components._
import monix.execution.Scheduler.Implicits.global
import org.log4s.getLogger
import org.scalajs.dom
import org.scalajs.dom.raw._

import scala.scalajs.js
import scala.scalajs.js.JSConverters._
import scala.util.{Failure, Success}
import com.sludg.components.filters.FilterHelpers
import com.sludg.util.models.SilhouetteModels

object ReportPageRenderFunctions {
  //Below is the total width all components should have across all screen viewports
  //multiple containers should always add up to this total
  lazy val totalWidth = List(Left("xs12"), Left("sm11"), Left("md12"), Left("lg11"), Left("xl9"))
  lazy val availableCategories: List[Category[_]] = List(
    Category.Direction,
    Category.HourOfDay,
    Category.Day,
    Category.DayOfWeek,
    Category.Week,
    Category.Month,
    Category.Subscriber,
    Category.Answer,
    Category.LastExtension,
    Category.ClassOfService,
    Category.Termination,
    Category.AutoAttendant,
    Category.CallGroup,
    Category.DialledNumber
  )

  private[pages] lazy val logger = getLogger

  val vBadge = namedTag[VueProps, EventBindings, ScopedSlots]("v-badge")

  import com.sludg.vuetify.VuetifyComponents._

  def noDescrepanciesSubmitted(component: ReportPageComponent) = {

    val filterDiscrep =
      component.lastSubmittedFilters.forall(component.selectedFilters contains _) &&
        component.selectedFilters.forall(component.lastSubmittedFilters contains _)

    val categoryDiscrep = component.lastSubmittedCategories == component.selectedCategories

    val noDiscrepancies: Boolean = filterDiscrep && categoryDiscrep

    val selectedReports = component.selectedReport == component.lastSubmittedReport

    (selectedReports && noDiscrepancies)

  }

  def isItIcon(component: ReportPageComponent) = {
    component.$root.$vuetify.breakpoint.name.toString match {
      case "xs" => true
      case "sm" => true
      case "md" => false
      case "lg" => false
      case "xl" => false
    }
  }

  def buildReportSelector(
      reportPageData: ReportPageComponent,
      apiCalls: ApiCalls,
      loaderEventBus: Vue,
      tenant: Tenant
  )(implicit sludgToken: SludgToken) = {
    vContainer(
      vLayout(
        RenderOptions(`class` = List(Left("justify-center"))),
        vFlex(
          RenderOptions(`class` =
            List(Left("xs8"), Left("sm8"), Left("md8"), Left("lg8"), Left("xl6"))
          ),
          vCardText(
            RenderOptions(
              `class` = List(Left("headline"), Left("info-text"))
            ),
            "Getting Started"
          ),
          p(
            RenderOptions(
              `class` = List(Left("info-text"))
            ),
            "Start by choosing a preset report, or one of your saved reports."
          ),
          reportSelector(
            RenderOptions(
              props = Some(
                ReportSelectorProps(
                  tenant.id,
                  displayPresets = true,
                  withSaving = true,
                  filterInput = reportPageData.selectedFilters,
                  categoryInput = reportPageData.selectedCategories,
                  selectedHeadersInput = reportPageData.selectedHeaders,
                  toolbarize = true
                )
              ),
              on = Some(
                ReportSelectorEvents(
                  reportSelected = e => {
                    logger.debug("Updating filters")
                    val hasDateFilter = e._2
                      .collectFirst {
                        case _: DateFilter | _: RelativeDateFilter => true
                        case _ => false
                      }
                      .getOrElse(false)

                    val d =
                      if (hasDateFilter) e._2
                      else
                        FilterHelpers
                          .getBlankFilter[RelativeDateFilter]
                          .map(_ :: e._2)
                          .getOrElse(e._2)

                    reportPageData.selectedFilters = d
                    reportPageData.selectedCategories = e._3
                    reportPageData.selectedHeaders = e._4
                    reportPageData.selectedReport = e._1
                  }
                )
              )
            )
          )
        ),
        vFlex(
          RenderOptions(
            `class` = List(
              Left("xs4"),
              Left("sm3"),
              Left("md4"),
              Left("lg3"),
              Left("xl3"),
              Left("align-self-end")
            ),
            style = Some(
              js.Dynamic.literal(
                "padding-left" -> "8px"
              )
            )
          ),
          makeRunAndDownloadButtons(reportPageData, tenant, apiCalls, loaderEventBus)
        )
      )
    )
  }

  def makeRunAndDownloadButtons(
      reportPageData: ReportPageComponent,
      tenant: Tenant,
      apiCalls: ApiCalls,
      loaderEventBus: Vue
  )(implicit sludgToken: SludgToken) = {
    div(
      addLoadRefreshSubmitButton(reportPageData, tenant, apiCalls, loaderEventBus),
      vButton(
        if (isItIcon(reportPageData)) vIcon("cloud_download") else "Download",
        RenderOptions(
          props = Some(
            VButtonProps(
              loading = Some(reportPageData.loading),
              disabled = Some(reportPageData.loading),
              icon = Some(isItIcon(reportPageData)),
              fab = Some(isItIcon(reportPageData)),
              small = Some(isItIcon(reportPageData))
            )
          ),
          on = Some(
            EventBindings(
              click = js.defined(e => {
                reportPageData.dialogVisible = true
              })
            )
          )
        )
      )
    )
  }

  def itemisationDialog(
      tenant: Tenant,
      component: ReportPageComponent,
      data: ReportPageData,
      apiCalls: ApiCalls,
      loader: Vue
  )(implicit sludgToken: SludgToken) = {

    def disableButtons: Boolean = !component.includePDF && !component.includeSpreadsheet

    div(
      vDialog(
        RenderOptions(
          style = Some(
            js.Dynamic.literal(
              "text-align" -> "center",
              "box-shadow" -> "0px"
            )
          ),
          props = Some(
            VDialogProps(
              value = Some(data.dialogVisible),
              width = Some(Right(200)),
              scrollable = Some(false),
              `max-width` = Some(Right(450))
            )
          ),
          on = Some(
            EventBindings(
              input = js.defined(e => {
                data.dialogVisible = e.asInstanceOf[Boolean]
              })
            )
          )
        ),
        vCard(
          vCardTitle(
            RenderOptions(
              `class` = List(Left("headline"))
            ),
            "Report downloader"
          ),
          vDivider,
          vFlex(
            vCardText(
              vAlert(
                if (disableButtons) "You must select at least one type of file to download."
                else nothing
              )(
                RenderOptions(
                  props = Some(
                    VAlertProps(
                      `type` = Some("warning"),
                      value = Some(disableButtons)
                    )
                  )
                )
              ),
              vList(
                makeSwitch(
                  component,
                  component.includePDF,
                  "PDF",
                  "Include PDF file format",
                  EventBindings(change = js.defined(e => {
                    component.includePDF = e.asInstanceOf[Boolean]
                  }))
                ),
                makeSwitch(
                  component,
                  component.includeSpreadsheet,
                  "Spreadsheet",
                  "Include spreadsheet xlsx file format",
                  EventBindings(change = js.defined(e => {
                    component.includeSpreadsheet = e.asInstanceOf[Boolean]
                  }))
                ),
                makeSwitch(
                  component,
                  component.includeItemisation,
                  "Call Itemisation",
                  "Include itemised call history",
                  EventBindings(change = js.defined(e => {
                    component.includeItemisation = e.asInstanceOf[Boolean]
                  }))
                )
              )
            )
          ),
          downloadSection(tenant, component, data, apiCalls, loader, disableButtons)
        )(
          RenderOptions(
            props = Some(
              VCardProps(
                `max-width` = Some(Right("450px"))
              )
            )
          )
        )
      )
    )
  }

  private def makeSwitch(
      component: ReportPageComponent,
      switchValue: Boolean,
      header: String,
      subHeader: String,
      eventBindings: EventBindings
  ) = {
    vListTile(
      vListTileAction(
        vSwitch(
        )(
          RenderOptions(
            props = Some(
              VSwitchProps(
                `input-value` = Some(switchValue)
              )
            ),
            on = Some(
              eventBindings
            )
          )
        )
      ),
      vListTileContent(
        vListTileTitle(header),
        vListTileSubTitle(subHeader)
      )
    )
  }

  private def downloadSection(
      tenant: Tenant,
      component: ReportPageComponent,
      data: ReportPageData,
      apiCalls: ApiCalls,
      loader: Vue,
      disableButtons: Boolean
  )(implicit sludgToken: SludgToken) = {
    vLayout(
      vFlex(
        RenderOptions(
          `class` = List("xs12", "sm5", "md5").map(Left.apply),
          style = Some(
            js.Dynamic.literal(
              "margin-bottom" -> "15px"
            )
          )
        ),
        vButton("Download")(
          RenderOptions(
            props = Some(
              VButtonProps(
                disabled = Some(disableButtons),
                target = Some("download")
              )
            ),
            on = Some(EventBindings(click = js.defined(e => {
              component.dialogVisible = false
              component.loading = !component.loading
              LoadingFuture
                .withLoading(
                  loader,
                  (for {
                    zip <- EitherT(
                      apiCalls.getReportPdf(
                        tenant.id,
                        component.selectedFilters,
                        component.selectedCategories,
                        component.selectedHeaders,
                        component.includeItemisation,
                        component.selectedReport.map(_.name),
                        component.includePDF,
                        component.includeSpreadsheet
                      )
                    )
                  } yield {
                    zip
                  }).value
                )
                .onComplete(e => {
                  e.getOrElse(Array()) match {
                    case Right(value) =>
                      component.loading = !component.loading
                      FileSaver.saveAs(
                        new Blob(
                          js.Array(
                            value.asInstanceOf[js.Array[js.Any]]
                          ),
                          BlobPropertyBag(
                            `type` = "application/zip"
                          )
                        ),
                        "VTSL_Report"
                      )
                      e.failed.foreach(_.printStackTrace())
                    case Left(error) => logger.debug("Access forbidden")
                  }
                })
            })))
          )
        )
      )
    )
  }

  def buildGroupingComponent(data: ReportPageData) = {
    vContainer(
      vLayout(
        RenderOptions(
          `class` = List(Left("justify-center"))
        ),
        vFlex(
          RenderOptions(`class` =
            List(Left("xs12"), Left("sm11"), Left("md12"), Left("lg11"), Left("xl9"))
          ),
          vCardTitle(
            RenderOptions(
              `class` = List(Left("headline"), Left("info-text"))
            ),
            "View Report By"
          ),
          p(
            RenderOptions(
              `class` = List(Left("info-text"))
            ),
            "Select how you want your data displayed. Preset and saved reports have existing Views and Filters which you can change to create a customised report. Save a custom report by typing a name in the box above. You can also make a customised copy of a preset or existing report by giving it a new name."
          ),
          vSlideXTransition(
            groupSelector(
              RenderOptions(
                props = Some(
                  GroupSelectorProps(
                    currentlySelectedGroupings = data.selectedCategories,
                    fullGroupingList = js.defined(
                      availableCategories
                        .sorted(Ordering.by((x: Category[_]) => x.present))
                        .toJSArray
                    ) //suspect
                  )
                ),
                on = Some(
                  GroupSelectorEvents(
                    groupSelected = js.defined(e => {
                      data.selectedCategories = e
                    })
                  )
                )
              )
            )
          )
        )
      )
    )
  }

  def addFilterManager(
      tenantSubs: List[SilhouetteModels.Subscriber],
      tenantAa: List[SilhouetteModels.AutoAttendant],
      tenantCg: List[SilhouetteModels.CallGroup],
      component: ReportPageComponent,
      apiCalls: ApiCalls,
      loaderEventBus: Vue
  ) = {
    vContainer(
      RenderOptions(`class` = List(Left("grid-list-md"))),
      vLayout(
        RenderOptions(`class` = List(Left("justify-center"))),
        vFlex(
          RenderOptions(`class` =
            List(Left("xs12"), Left("sm11"), Left("md12"), Left("lg11"), Left("xl9"))
          ),
          filterManager(
            RenderOptions(
              props = Some(
                FilterManagerProps(
                  currentFilters = component.selectedFilters,
                  extensionLength = Some(3),
                  tenantExtensions = tenantSubs,
                  tenantAutoAttendants = tenantAa,
                  tenantGroups = tenantCg
                )
              ),
              on = Some(
                FilterManagerEvents(
                  filterListUpdated = js.defined(e => {
                    ReportPageRenderFunctions.logger.debug(s"Selected filters: $e")
                    component.selectedFilters = e
                  })
                )
              )
            )
          )
        )
      )
    )
  }

  def lowerButtons(
      component: ReportPageComponent,
      tenant: Tenant,
      apiCalls: ApiCalls,
      loaderEventBus: Vue
  )(implicit
      sludgToken: SludgToken
  ) = {
    vContainer(
      RenderOptions(`class` = List(Left("grid-list-md"))),
      vLayout(
        RenderOptions(`class` = List(Left("justify-center"))),
        vFlex(
          RenderOptions(`class` =
            List(Left("xs12"), Left("sm11"), Left("md12"), Left("lg11"), Left("xl9"))
          ),
          makeRunAndDownloadButtons(component, tenant, apiCalls, loaderEventBus)
        )
      )
    )
  }

  def addLoadRefreshSubmitButton(
      data: ReportPageComponent,
      tenant: Tenant,
      apiCalls: ApiCalls,
      loaderEventBus: Vue
  )(implicit sludgToken: SludgToken) = {
    val bounce = List(Left("heartBeat animated"))
    val isIcon: Boolean = isItIcon(data)
    vButton(
      if (isIcon) {
        if (data.reportData.isEmpty || data.selectedReport != data.lastSubmittedReport)
          vIcon("send")
        else if (noDescrepanciesSubmitted(data)) vIcon("send")
        else vIcon("refresh")
      } else {
        if (data.reportData.isEmpty || data.selectedReport != data.lastSubmittedReport) "Run Report"
        else if (noDescrepanciesSubmitted(data)) "Run Report"
        else "Refresh"
      }
    )(
      RenderOptions(
        `class` = if (noDescrepanciesSubmitted(data)) Nil else bounce,
        props = Some(
          VButtonProps(
            small = if (isIcon) Some(true) else Some(false),
            loading = Some(data.submitloading),
            disabled = Some(data.submitloading),
            color = if (noDescrepanciesSubmitted(data)) None else Some("primary"),
            fab = if (isIcon) Some(true) else Some(false),
            icon = if (isIcon) Some(true) else Some(false)
          )
        ),
        on = Some(
          EventBindings(
            click = js.defined(e => {
              data.submitloading = !data.submitloading
              data.$emit("submit", "submit")

              ReportPage
                .queryCallReport(
                  apiCalls,
                  tenant.id,
                  if (data.selectedFilters.nonEmpty) data.selectedFilters else List(),
                  data.selectedCategories,
                  loaderEventBus
                )
                .onComplete {
                  case Success((reportData, cdrData)) => {

                    data.submitloading = !data.submitloading
                    data.reportData = Some(reportData)
                    data.cdrData = cdrData.to(Vector)
                    data.$root.$vuetify.goTo("#graphManager")

                    data.lastSubmittedFilters = data.selectedFilters
                    data.lastSubmittedCategories = data.selectedCategories
                    data.lastSubmittedReport = data.selectedReport
                  };
                  case Failure(error) => {
                    data.submitloading = !data.submitloading
                  };
                }
            })
          )
        )
      )
    )
  }

  def buildGraphManager(
      data: ReportPageData,
      apiCalls: ApiCalls,
      tenant: Tenant,
      loadingEventBus: Vue
  ) = {
    ReportPageRenderFunctions.logger.debug("BUILDING GRAPH")

    graphManager(
      RenderOptions(
        props = Some(
          GraphManagerProps(
            graphDataList = if (data.reportData.isDefined) data.reportData.get else js.undefined,
            selectedHeaders = js.defined(data.selectedHeaders.toJSArray),
            cdrData = js.defined(data.cdrData.to(List)),
            filters = js.defined(data.lastSubmittedFilters)
          )
        ),
        on = Some(
          js.Dynamic
            .literal(
              "headersSelected2" -> ((e => {
                ReportPageRenderFunctions.logger.debug("Headers received in report page")

                data.selectedHeaders = e.map(t => {
                  ReportColumn(
                    0,
                    t.customName,
                    t.sorting,
                    t.listOfCategoryData,
                    t.projectionType,
                    data.selectedReport.map(_.id).getOrElse(0)
                  )
                })
              }): js.Function1[List[TableColumnsWithSorting], Unit])
            )
            .asInstanceOf[EventBindings]
        )
      )
    )
  }

}
