package com.sludg

import com.sludg.auth0.SludgToken
import com.sludg.client.Client
import com.sludg.client.pages.ReportPageRenderFunctions
import com.sludg.model.Models.{AnalyticsConfig => LocalAnalyticsConfig}
import com.sludg.services.{ApiCalls => ServiceApiCalls}
import com.sludg.vuetify._
import fr.hmil.roshttp.HttpRequest
import org.log4s._
import play.api.libs.json.{JsObject, JsString, Json}

import scala.util.{Failure, Success}

/** @author dpoliakas
  *         Date: 13/11/2018
  *         Time: 14:54
  */
object Entry extends App {
  import com.sludg.model.Models.analyticsConfigFormat
  import com.sludg.json.Deserializer._

  private[this] val logger = getLogger

  import monix.execution.Scheduler.Implicits.global

  val originUrl = org.scalajs.dom.window.location.origin

  def getConfig = HttpRequest(s"$originUrl/config").send().map { r =>
    Json.parse(r.body).validate[LocalAnalyticsConfig].asOpt
  }

  def initLogging(level: Option[LogLevel]): Unit = {
    import org.log4s.log4sjs.Log4sConfig._
    level match {
      case Some(logLevel) => setLoggerThreshold("", logLevel)
      case _ => setLoggerThreshold("", OffThreshold)
    }
  }

  (for (configOpt <- getConfig) yield configOpt match {
    case Some(config) =>
      // Setup log level
      initLogging(config.logLevel)

      (for {
        maybeSecurity <- Security.initialise(
          Auth0Config(
            config.auth0.domain,
            config.auth0.clientID,
            config.auth0.audience,
            originUrl.get
          )
        )
      } yield {
        maybeSecurity match {
          case Left(Security.NotLoggedIn(redirectToLogin)) =>
            logger.info("User not logged in. Redirecting.")
            redirectToLogin.run()
          case Right(sec) =>
            logger.info("Security session initialised. Bootings...")

            sec.checkSession().onComplete {
              case Success(tokenJs) =>
                Client.initialise(
                  sec,
                  config,
                  config.otherApps,
                  new ServiceApiCalls(config.apiConfig),
                  "main"
                )(new SludgToken(tokenJs.get.accessToken))
              case Failure(error) =>
                logger.debug("Could not refresh the token")
                error.printStackTrace()
                logger.debug("Logging out.")
                sec.logout()
            }
        }

      }).onComplete {
        case Success(_) => logger.debug("Running application")
        case Failure(e) =>
          logger.error("failed to start the application")
          e.printStackTrace()
          logger.debug(e.getMessage)
      }

    case None => logger.error("Config not supplied or could not be parsed")

  }).onComplete {
    case Success(_) => logger.debug("running")
    case Failure(e) =>
      logger.error("failed to start the application")
      e.printStackTrace()
      println(e.getMessage)
  }

}
