package com.sludg.client.components

import com.sludg.helpers.ChartFormatters
import com.sludg.util.PresenterSyntax._
import com.sludg.util.ApiModelPresenters._
import com.sludg.util.{ChildProjection, Projection => UtilProjection}
import com.sludg.util.models.GroupingModels.Category.{HourOfDay, Week}
import com.sludg.vue.RenderHelpers.{p, _}
import com.sludg.vue.{RenderHelpers, _}
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components.{VCardProps, VSubheaderProps, VTabProps, VTabsProps}
import org.scalajs.dom
import org.scalajs.dom.document

import scala.collection.mutable.ListBuffer
import scala.scalajs.js
import scala.scalajs.js.{Dynamic, JSON}
import scala.scalajs.js.JSConverters._

object ChartCreator {
  //A stream of numbers in seconds incremented by five that are divisible by either 25 or 60 until one hour(171), after that values of every 30 mins
  //e.g (25,50,60,75,100,120,125)
  val TIME_DATA_STREAM: LazyList[Int] =
    LazyList.iterate(5)(_ + 5).filter(x => x % 25 == 0 || x % 60 == 0).take(171) #::: LazyList
      .iterate(
        3600
      )(_ + 1800)
  val TOTAL_DATA_STREAM: LazyList[Int] = LazyList.iterate(2)(_ + 2)

  def projectionAndGraphBoss(fullProjection: ProjectionChoicesWithData[_]): GraphPropValues = {
    fullProjection.lowerButton match {
      case Some("SUM") =>
        GraphPropValues(
          0,
          List(0, 1, 2, 3),
          isSunburstDisabled = false,
          isColumnDisabled = false,
          isAreaDisabled = false,
          isLineDisabled = false
        )
      case Some("MIN") =>
        GraphPropValues(
          0,
          List(0, 1, 2),
          isSunburstDisabled = true,
          isColumnDisabled = false,
          isAreaDisabled = false,
          isLineDisabled = false
        )
      case Some("MAX") =>
        GraphPropValues(
          0,
          List(0, 1, 2),
          isSunburstDisabled = true,
          isColumnDisabled = false,
          isAreaDisabled = false,
          isLineDisabled = false
        )
      case Some("AVG") =>
        GraphPropValues(
          0,
          List(0, 1, 2),
          isSunburstDisabled = true,
          isColumnDisabled = false,
          isAreaDisabled = false,
          isLineDisabled = false
        )
      case _ =>
        GraphPropValues(
          0,
          List(0, 1, 2, 3),
          isSunburstDisabled = false,
          isColumnDisabled = false,
          isAreaDisabled = false,
          isLineDisabled = false
        )
    }
  }

  def chartCreatorRenderer(registrationName: String) =
    namedTag[ChartCreatorProps, ChartCreatorEvents, ScopedSlots]("ChartCreator")

  val sunburstGraph = SunburstGraph.sunburstGraphRenderrer("SunburstGraph")
  val gChart = namedTag[VueProps, EventBindings, ScopedSlots]("GChart")

  def chartCreatorComponent() = {
    VueComponent.builder
      .withScopedSlots[ScopedSlots]
      .withData(new ChartCreatorData())
      .withProps(ChartCreatorProps())
      .withMethods(new ChartCreatorMethods)
      .build(
        components = js.Dynamic.literal(
          "SunburstGraph" -> SunburstGraph.sunburstGraphComponent()
        ),
        templateOrRender = Right((component, createElement) => {
          //TODO charts can flicker when mouseover is on tooltip itself, css change `svg > g > g:last-child { pointer-events: none }` is supposed to fix
          //Unable to replicate this anymore
          div(
            component.fullProjectionData match {
              case list if list.get.projectionData.children.isEmpty =>
                vListTile(
                  vSubheader(
                    "There are no grouped calls to display with this combination of filters and groupings.",
                    RenderOptions[VSubheaderProps, EventBindings, ScopedSlots](
                      style = Some(
                        js.Dynamic.literal(
                          "text-align" -> "center",
                          "margin" -> "0 auto"
                        )
                      )
                    )
                  )
                )
              case list =>
                val graphPropValues: GraphPropValues = projectionAndGraphBoss(list.get)
                val graphTickCount =
                  if (list.get.projectionData.children.map(_.value.toString.toDouble).max < 3600) 5
                  else 6
                div(
                  vTabs(
                    makeTab(component, "Column Chart", graphPropValues.isColumnDisabled),
                    makeTab(component, "Area Chart", graphPropValues.isAreaDisabled),
                    makeTab(component, "Line Chart", graphPropValues.isLineDisabled),
                    makeTab(component, "Sunburst Graph", graphPropValues.isSunburstDisabled),
                    makeChartTabContent(component, "ColumnChart", graphTickCount, 0),
                    makeChartTabContent(component, "AreaChart", graphTickCount, 1),
                    makeChartTabContent(component, "LineChart", graphTickCount, 2),
                    makeChartTabContent(component, "Sunburst", graphTickCount, 3)
                  )(
                    RenderOptions(
                      props = Some(tabsPropSwitcher(component, graphPropValues)),
                      on = Some(EventBindings(change = js.defined(e => {
                        component.currentTab = e.asInstanceOf[Int]
                      })))
                    )
                  )
                )
            }
          ).render(createElement)
        })
      )
  }

  def makeTab(component: ChartCreatorData, tabName: String, disabledStatus: Boolean) = {
    vTab(tabName)(
      RenderOptions(
        props = Some(
          js.Dynamic
            .literal(
              "disabled" -> disabledStatus
            )
            .asInstanceOf[VTabProps]
        )
      )
    )
  }

  private def makeChartTabContent(
      component: ChartCreatorData with ChartCreatorMethods with ChartCreatorProps,
      graphType: String,
      tickCount: Int,
      tabId: Int
  ) = {
    graphType match {
      case "Sunburst" =>
        vTabItem(
          vCard(
            vLayout(
              sunburstGraph(
                RenderOptions[SunburstGraphProps, EventBindings, CustomScopedSlots](
                  props = Some(
                    new SunburstGraphProps(
                      fullProjectionData = component.fullProjectionData.get
                    )
                  )
                )
              )
            )
          )(
            RenderOptions(
              props = Some(
                VCardProps(
                  height = Some(Right("500px")),
                  flat = Some(true)
                )
              )
            )
          )
        )
      case _ =>
        vTabItem(
          vCard(
            aggregateAndStructureChartData(component, graphType, tickCount, tabId)
          )(
            RenderOptions(
              props = Some(
                VCardProps(
                  height = Some(Right("500px")),
                  flat = Some(true)
                )
              )
            )
          )
        )
    }
  }

  def tabsPropSwitcher(
      component: ChartCreatorData,
      graphPropValues: GraphPropValues
  ): VTabsProps = {
    if (graphPropValues.allowedIndexes.contains(component.currentTab)) {
      js.Dynamic
        .literal(
          "max" -> "750",
          "grow" -> true,
          "value" -> component.currentTab
        )
        .asInstanceOf[VTabsProps]
    } else {
      component.currentTab = graphPropValues.defaultIndex
      js.Dynamic
        .literal(
          "max" -> "750",
          "grow" -> true,
          "value" -> graphPropValues.defaultIndex
        )
        .asInstanceOf[VTabsProps]
    }
  }

  private def aggregateAndStructureChartData(
      component: ChartCreatorProps with ChartCreatorMethods with ChartCreatorData,
      graphType: String,
      tickCount: Int,
      tabId: Int
  ) = {
    val graphTitle =
      component.fullProjectionData.get.projectionData.children.head.category.category.present
    val reorderedProjection =
      component.reorderProjection(component.fullProjectionData.get.projectionData.children)
    var rawGraphData: List[js.Array[_]] = List()

    //Separating and then converting necessary data for custom vertical axis
    val projectionValues = reorderedProjection.map(_.value.toString.toDouble)
    val max = projectionValues.max
    val maxTotalValue = TOTAL_DATA_STREAM.find(x => x > max && x % tickCount == 0)
    val maxDurationValue = TIME_DATA_STREAM.dropWhile(x => x < max && x % 5 == 0).headOption

    val totalData = generateVerticalSeries(projectionValues, max, maxTotalValue, tickCount)
    val durationData = generateVerticalSeries(projectionValues, max, maxDurationValue, tickCount)
    val durationDataWithText =
      DurationFormat(durationData, durationData.map(ChartFormatters.secondsToTime))

    val vAxis = component.fullProjectionData.get.upperButton match {
      case Some("Total") =>
        rawGraphData = component.convertProjectionToTotal(reorderedProjection, graphTitle)
        //Totals takes the same tooltip for value and text
        setVerticalAxis(totalData, totalData.map(_.toString), tickCount)
      case _ =>
        rawGraphData = component.convertProjectionToDuration(reorderedProjection, graphTitle)
        //Duration tooltips need a hh:mm:ss conversion
        setVerticalAxis(durationDataWithText.value, durationDataWithText.text, tickCount)
    }

    if (component.currentTab == tabId) {
      makeChart(component, graphType, graphTitle, rawGraphData, vAxis)
    } else div()
  }

  def generateVerticalSeries(
      projectionValues: List[Double],
      max: Double,
      maxChartRange: Option[Int],
      tickCount: Int
  ): ListBuffer[Double] = {
    val tickSpacing = ListBuffer[Double]()
    val notchSize = maxChartRange.getOrElse(0) / tickCount

    var count = 0
    for (_ <- 1 to tickCount) {
      count = count + notchSize
      tickSpacing += count
    }

    tickSpacing
  }

  def setVerticalAxis(
      dataValue: ListBuffer[Double],
      dataText: ListBuffer[String],
      tickCount: Int
  ): (String, js.Object with Dynamic) = {
    var tickArray = js.Array[js.Object with Dynamic]()
    for (i <- 0 until tickCount) {
      tickArray(i) = js.Dynamic.literal(
        "v" -> dataValue(i),
        "f" -> dataText(i)
      )
    }

    "vAxis" -> js.Dynamic.literal(
      "minorGridlines" -> js.Dynamic.literal(
        "count" -> tickCount
      ),
      "ticks" -> tickArray
    )
  }

  private def makeChart(
      component: ChartCreatorProps with ChartCreatorMethods with ChartCreatorData,
      graphType: String,
      graphTitle: String,
      graphData: List[js.Array[_]],
      vAxis: (VFlexSize, js.Object with Dynamic)
  ): RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots] = {
    gChart(
      RenderOptions(
        props = Some(
          js.Dynamic
            .literal(
              "type" -> graphType,
              "data" -> graphData.toJSArray,
              "options" -> js.Dynamic.literal(
                "height" -> 425,
                "resizeDebounce" -> "50",
                "animation" -> js.Dynamic.literal(
                  "startup" -> true,
                  "duration" -> 300,
                  "easing" -> "in"
                ),
                "colors" -> js.Array("#6F90A7"),
                "title" -> (graphTitle.toLowerCase.capitalize + " - " + ChartFormatters
                  .formatTitle(component.fullProjectionData.get)),
                "legend" -> js.Dynamic.literal(
                  "position" -> "none"
                ),
                "hAxis" -> js.Dynamic.literal(
                  "title" -> graphTitle
                ),
                vAxis
              )
            )
            .asInstanceOf[VueProps]
        )
      )
    )
  }

}

case class DurationFormat(value: ListBuffer[Double], text: ListBuffer[String])

case class GraphPropValues(
    defaultIndex: Int,
    allowedIndexes: List[Int],
    isSunburstDisabled: Boolean,
    isColumnDisabled: Boolean,
    isAreaDisabled: Boolean,
    isLineDisabled: Boolean
)

class ChartCreatorData extends js.Object {
  var currentTab = 0
}

class ChartCreatorMethods extends js.Object {

  def convertProjectionToTotal(projection: List[ChildProjection[_]], title: String) = {
    //first array must be headers
    js.Array(title, "Total Calls") :: projection.map(x =>
      js.Array(
        ChartFormatters.formatShortGraphText(x.category),
        //Tooltip text
        x.value.toString.toDouble
      )
    )
  }

  def convertProjectionToDuration(projection: List[ChildProjection[_]], title: String) = {
    //Object to customise tooltip in header, 3rd column is tooltip data
    js.Array(
      title,
      "Durations",
      js.Dynamic.literal("type" -> "string", "role" -> "tooltip")
    ) :: projection
      .map(x =>
        js.Array(
          ChartFormatters.formatShortGraphText(x.category),
          x.value.toString.toDouble,
          //Tooltip text
          ChartFormatters.formatShortGraphText(x.category) + "\n Duration: " + ChartFormatters
            .secondsToTime(x.value.toString.toDouble)
        )
      )
  }

  def reorderProjection(projection: List[ChildProjection[_]]): List[ChildProjection[_]] = {
    projection.head.category.category match {
      case x if x == Week || x == HourOfDay => projection.sortBy(_.category.data.toString.toInt)
      //("11" < "2") == true - so HourOfDay doesn't sort properly in the _ case
      case _ => projection.sorted(Ordering.by((x: ChildProjection[_]) => x.category.data.toString))
    }
  }
}

trait ChartCreatorProps extends VueProps {
  var fullProjectionData: js.UndefOr[ProjectionChoicesWithData[_]] = js.undefined
}

object ChartCreatorProps {
  def apply(
      fullProjectionData: js.UndefOr[ProjectionChoicesWithData[_]] = js.undefined
  ): ChartCreatorProps = {
    js.Dynamic
      .literal(
        "fullProjectionData" -> fullProjectionData.asInstanceOf[js.Any]
      )
      .asInstanceOf[ChartCreatorProps]
  }
}

trait ChartCreatorEvents extends EventBindings {}

object ChartCreatorEvents {}
