package com.sludg.client.components.tables

import com.sludg.util.models.GroupingModels.Category.{HourOfDay, Week}
import com.sludg.util.models.ReportModels.ProjectionType._

import scala.math.Ordering
import scala.scalajs.js

class DataDisplayerMethods extends js.Object {

  def chipRemove(selectedToRemove: HeaderWithGrouping): Unit = {
    GroupingDataTable.logger.debug("Removing chips")
    val data = this.asInstanceOf[DataDisplayerData]
    val indexOfSelected = data.currentlySelectedHeaders.indexOf(selectedToRemove)
    GroupingDataTable.logger.debug(s"Index of selection: $indexOfSelected")
    data.currentlySelectedHeaders = data.currentlySelectedHeaders.patch(indexOfSelected, Nil, 1)
  }

  def customSort(
      sortingHeader: NodePath,
      descending: Boolean,
      rawTableData: List[ExtractedTableData]
  ): Unit = {
    GroupingDataTable.logger.debug("Custom Sort")
    val data = this.asInstanceOf[DataDisplayerData]

    val sorted = rawTableData.sorted(
      Ordering.by((a: ExtractedTableData) => a.mapOfHeadersWithData.get(sortingHeader))(
        Ordering.Option(Ordering.by {
          case IntCellData(x, _) => x.toDouble
          case DoubleCellData(x, _) => x
          case EmptyCell(x) => 0
        })
      )
    )

    data.rawTableData = if (descending) sorted.reverse else sorted
  }

  def defaultSort(rawTableData: List[ExtractedTableData]): Unit = {
    GroupingDataTable.logger.debug("Default sort")
    val data = this.asInstanceOf[DataDisplayerData]
    if (rawTableData.headOption.nonEmpty) rawTableData.head.categoryData.category match {
      case x if x == Week || x == HourOfDay =>
        data.rawTableData = rawTableData.sortBy(_.categoryData.data.toString.toInt).reverse
      case _ =>
        data.rawTableData = rawTableData
          .sorted(Ordering.by((a: ExtractedTableData) => a.categoryData.data.toString))
          .reverse
    }
  }

  def removeTalkTimeProjection(h: HeaderWithGrouping): Boolean = h.projectionType.get match {
    case d if d == TotalTalkTime || d == AvgTalkTime || d == MinTalkTime || d == MaxTalkTime =>
      false
    case _ => true
  }
}
