package com.sludg.client.components.scheduling

import com.sludg.auth0.SludgToken
import com.sludg.client.components.scheduling.ScheduleTable.ScheduleTableEvents
import com.sludg.client.components.scheduling.period.TimeframeSelector
import com.sludg.helpers.LoadingFuture
import com.sludg.model.Models.AnalyticsConfig
import com.sludg.scalajs.DynamicHelper
import com.sludg.services.ApiCalls
import com.sludg.util.models.ReportModels
import com.sludg.util.models.ReportModels.ReportSchedule
import com.sludg.util.models.SilhouetteModels.Tenant
import com.sludg.vue.RenderHelpers._
import com.sludg.vue._
import com.sludg.vuetify.components._
import cron4s.Cron
import cron4s.expr.CronExpr
import org.log4s.getLogger
import org.scalajs.dom.Event
import org.scalajs.dom.raw.Event

import scala.scalajs.js

object ParentScreen {

  private[this] val logger = getLogger

  type ParentScreenComponent = VueComponent[_ <: ParentScreenProps, _ <: Slots, _ <: ScopedSlots]
    with ParentScreenData
    with ParentScreenMethods
    with js.Object
    with ParentScreenProps

  def parentScreenRenderer(registrationName: String) =
    namedTag[ParentScreenProps, ParentScreenEvents, ScopedSlots]("SchedulingApp")

  val schedulingTable = ScheduleTable.scheduleTableRenderer("ScheduleTable")

  def parentScreenComponent(config: AnalyticsConfig, apiCalls: ApiCalls, loadingBus: Vue)(implicit
      sludgToken: SludgToken
  ) = {
    VueComponent.builder
      .withData(new ParentScreenData)
      .withMethods(new ParentScreenMethods())
      .withProps(ParentScreenProps())
      .build(
        components = js.Dynamic.literal(
          "ScheduleTable" -> ScheduleTable.scheduleTableComponent(config, apiCalls, loadingBus)
        ),
        templateOrRender = Right((component, renderer) => {
          div(
            //p("SchedulingApp selected tenantId: " + component.selectedTenant),
            schedulingTable(
              RenderOptions(
                props = Some(ScheduleTableProps(component.selectedTenant, component.addRow)),
                on = Some(
                  js.Dynamic
                    .literal(
                      "item" -> ((e => {
                        component.reportScheduleItemisation = e.asInstanceOf[Boolean]
                      }): js.Function1[Event, Unit]),
                      "schedule" -> ((e => {
                        component.addRow = false

                        logger
                          .debug("[Stateholder event]---CronExpression received in stateholder.")
                        val schedule = e.toString
                        component.cronEx = Some(Cron.unsafeParse(schedule))
                      }): js.Function1[Event, Unit]),
                      "emails" -> ((e => {
                        val emails = e.toString.split(",").toList
                        logger.debug("[Stateholder event]---Emails received in stateholder.")
                        component.emails = Some(emails)
                      }): js.Function1[Event, Unit]),
                      "report" -> ((e => {
                        logger.debug(
                          "[Stateholder event]---Report received in stateholder." + e.toString
                        )
                        component.selectedReport = Some(e.toString.toInt)
                      }): js.Function1[Event, Unit]),
                      "save" -> ((e => {
                        logger.debug("[Stateholder event]---Api Call.")

                        /* Api call */
                        (component.selectedReport, component.cronEx, component.emails) match {
                          case (Some(y), Some(cronExpr), Some(emails)) => {
                            component.addRow = true
                            logger.debug(
                              s"${component.selectedTenant}--${component.selectedReport}--${component.cronEx}--${component.emails}"
                            )
                            component.createSchedule(
                              apiCalls,
                              component.selectedTenant.get,
                              y,
                              ReportModels.ReportSchedule(
                                0,
                                y,
                                component.selectedTenant.get,
                                cronExpr,
                                emails,
                                component.reportScheduleItemisation
                              ),
                              loadingBus
                            )
                          }
                          case _ => {
                            component.addRow = false
                            logger.debug("Report schedule not created.")
                            logger.debug(
                              s"${component.selectedReport}--${component.cronEx}--${component.emails}"
                            )
                          }
                        }
                      }): js.Function1[Event, Unit])
                    )
                    .asInstanceOf[ScheduleTableEvents]
                )
              )
            )
          ).render(renderer)
        })
      )
  }

  trait ParentScreenEvents extends EventBindings {
    def test(e: Event): Unit
  }

  object ParentScreenEvents {
    def apply() = {
      "".asInstanceOf[ParentScreenEvents]
    }
  }

  class ParentScreenMethods extends js.Object {

    import monix.execution.Scheduler.Implicits.global

    def createSchedule(
        apiCalls: ApiCalls,
        tenantId: Int,
        reportId: Int,
        reportSchedule: ReportSchedule,
        loadingEventBus: Vue
    )(implicit sludgToken: SludgToken) = {
      LoadingFuture.withLoading(
        loadingEventBus,
        apiCalls
          .createTenantSchedule(tenantId, reportSchedule)
          .map(result => {
            result match {
              case Left(x) => logger.debug("Oh no" + x)
              case Right(x) => logger.debug("Worked?" + x)
            }
            logger.info("Schedule created")
          })
      )
    }

  }

  trait ParentScreenProps extends VueProps {
    val selectedTenant: js.UndefOr[Int] = js.undefined
  }

  object ParentScreenProps {
    def apply(
        selectedTenant: js.UndefOr[Int] = js.undefined
    ): ParentScreenProps = {
      js.Dynamic
        .literal(
          "selectedTenant" -> selectedTenant
        )
        .asInstanceOf[ParentScreenProps]
    }
  }

  class ParentScreenData extends js.Object {
    var emails: Option[List[String]] = None
    var cronEx: Option[CronExpr] = None
    var selectedReport: Option[Int] = None
    var selectedTenant: Option[Tenant] = None
    var reportScheduleItemisation: Boolean = false

    var addRow: Boolean = false
  }

}
