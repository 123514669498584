package com.sludg.client

import com.sludg._
import com.sludg.auth0.SludgToken
import com.sludg.client.pages.{ReportPage, SchedulingPage}
import com.sludg.model.Models.AnalyticsConfig
import com.sludg.models.Config.AppLink
import com.sludg.services.ApiCalls
import com.sludg.util.models.SilhouetteModels.Tenant
import com.sludg.vue._
import com.sludg.vuetify._
import org.log4s.getLogger

import scala.scalajs.js

object Client {

  import com.sludg.helpers.Client.initialize

  private[this] val logger = getLogger

  private val vuetifyAssets = com.sludg.vuetify.VuetifyAssets

  def initialise(
      security: Security,
      config: AnalyticsConfig,
      otherApps: List[AppLink],
      apiCalls: ApiCalls,
      appContainer: String
  )(implicit token: SludgToken) = {
    logger.debug("Client processes. We don't care about security >:)")

    Vue.use(VueGoogleCharts)

    val apiCallBus = new Vue(js.undefined)

    val reportPage = ReportPage.reportPageComponent(apiCalls, security, apiCallBus, otherApps)
    val schedulingPage =
      SchedulingPage.schedulingPageComponent(config, apiCalls, security, apiCallBus, otherApps)

    initialize(
      config.vuetify,
      appContainer,
      List(
        VueRoute("/", name = Some("Analytics"), component = Some(reportPage)),
        VueRoute("/scheduling", name = Some("Scheduling"), component = Some(schedulingPage)),
        VueRoute("*", redirect = Some("/"))
      )
    )

  }
}
