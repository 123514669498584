package com.sludg.client.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.util.{
  Projection => UtilProjection,
  ReportProjections,
  RootProjection => UtilRootProjection
}
import com.sludg.util.models.GroupingModels.{ReportData, RootReportData, Stats}
import com.sludg.vue.{ScopedSlots, _}
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components.{VButtonProps, VButtonToggleProps}
import org.log4s.getLogger
import org.scalajs.dom.Event

import scala.scalajs.js

object ProjectionSelector {

  private[components] val logger = getLogger

  import com.sludg.vue.RenderHelpers._

  def projectionSelectorRenderer(registrationName: String) =
    namedTag[ProjectionSelectorProps, ProjectionSelectorEvents, ScopedSlots]("ProjectionSelector")

  def projectionSelectorComponent() = {
    VueComponent.builder
      .withData(new ProjectionSelectorData())
      .withProps(ProjectionSelectorProps())
      .withComputed(ProjectionSelectorComputed())
      .build(
        watch = new ProjectionSelectorWatcher(),
        updated = js.defined(c => {
          logger.debug("Projection selector updated")
        }),
        mounted = js.defined(component => {
          val converted: UtilRootProjection[Int] =
            ReportProjections.projectReport(component.graphDataList.get, _.total)
          component.upperButtonGroup = Some("Total")
          component.firstLevel = true
          val data = ProjectionChoicesWithData(Some("Total"), None, None, converted)
          component.$emit("projectionChoicesWithData", data.asInstanceOf[Event])
        }),
        templateOrRender = Right((component, renderer) => {
          if (component.graphDataList.isDefined) {
            logger.debug("converted defined")
            if (component.graphDataList.get.subGroups.isEmpty) {
              logger.debug("Children empty")
              div(nothing).render(renderer)
            } else {
              logger.debug("Children not empty")
              div(
                vContainer(
                  vDivider,
                  vSubheader(
                    "Select report content to view",
                    RenderOptions(style =
                      Some(
                        js.Dynamic.literal(
                          "margin" -> "-5px -16px -9px"
                        )
                      )
                    )
                  ),
                  vLayout(
                    vCard(
                      vButtonToggle(
                        vButton(
                          "Total Calls",
                          RenderOptions(
                            props = Some(
                              VButtonProps(
                                block = Some(true)
                              )
                            ),
                            on = Some(
                              EventBindings(
                                click = js.defined(e => {
                                  component.upperButtonGroup = Some("Total")
                                  component.firstLevel = true
                                  component.middleLevel = false
                                  component.bottomLevel = false

                                  component.durationDisable = false

                                  component.middleButtonGroup = None
                                  component.lowerButtonGroup = None
                                })
                              )
                            )
                          )
                        ),
                        vButton("Durations")(
                          RenderOptions(
                            props = Some(
                              VButtonProps(
                                block = Some(true),
                                disabled = Some(component.durationDisable)
                              )
                            ),
                            on = Some(
                              EventBindings(
                                click = js.defined(e => {
                                  component.upperButtonGroup = Some("Durations")
                                  component.firstLevel = false
                                  component.middleLevel = true

                                })
                              )
                            )
                          )
                        )
                      )(
                        RenderOptions(
                          props = Some(
                            js.Dynamic
                              .literal(
                                "value" -> 0
                              )
                              .asInstanceOf[VButtonToggleProps]
                          ),
                          style = Some(
                            js.Dynamic.literal(
                              "width" -> "200px"
                            )
                          )
                        )
                      ),
                      vSpacer,
                      if (component.middleLevel) {
                        Seq(
                          vButtonToggle(
                            makeStatButton("Totals", component),
                            makeStatButton("Talk", component),
                            makeStatButton("Ring", component)
                          )(
                            RenderOptions(
                              props = Some(
                                js.Dynamic
                                  .literal(
                                    "mandatory" -> true
                                  )
                                  .asInstanceOf[VButtonToggleProps]
                              ),
                              style = Some(
                                js.Dynamic.literal(
                                  "width" -> "200px"
                                )
                              )
                            )
                          ),
                          vSpacer
                        )
                      } else {
                        nothing
                      },
                      if (component.bottomLevel) {
                        vButtonToggle(
                          makeProjectionButton("SUM", component),
                          makeProjectionButton("MIN", component),
                          makeProjectionButton("MAX", component),
                          makeProjectionButton("AVG", component)
                        )(
                          RenderOptions(
                            props = Some(
                              js.Dynamic
                                .literal(
                                  "mandatory" -> true
                                )
                                .asInstanceOf[VButtonToggleProps]
                            ),
                            style = Some(
                              js.Dynamic.literal(
                                "width" -> "200px"
                              )
                            )
                          )
                        )
                      } else {
                        logger.debug("Converted not defined")
                        nothing
                      }
                    )
                  )
                )
              ).render(renderer)
            }
          } else {
            div(nothing).render(renderer)
          }
        })
      )
  }

  def makeStatButton(buttonName: String, data: ProjectionSelectorData) = {
    vButton(buttonName)(
      RenderOptions(
        props = Some(
          VButtonProps(
            block = Some(true)
          )
        ),
        on = Some(
          EventBindings(
            click = js.defined(e => {
              data.bottomLevel = true
              data.middleButtonGroup = Some(buttonName)
              data.upperButtonGroup = None
              data.durationDisable = true
            })
          )
        )
      )
    )
  }

  def makeProjectionButton(buttonName: String, data: ProjectionSelectorData) = {
    vButton(buttonName)(
      RenderOptions(
        props = Some(
          VButtonProps(
            block = Some(true)
          )
        ),
        on = Some(EventBindings(click = js.defined(e => {
          data.bottomLevel = true
          data.lowerButtonGroup = Some(buttonName)
          data.upperButtonGroup = None
          data.durationDisable = true
        })))
      )
    )
  }

}

case class ProjectionChoicesWithData[A](
    upperButton: Option[String],
    middleButton: Option[String],
    lowerButton: Option[String],
    projectionData: UtilRootProjection[A]
)

class ProjectionSelectorWatcher() extends js.Object {

  def computeProjectionTotal(
      newVal: Option[UtilRootProjection[_]],
      oldVal: Option[UtilRootProjection[_]]
  ): Unit = {
    if (newVal.isDefined) {
      val data = ProjectionChoicesWithData(
        this.asInstanceOf[ProjectionSelectorData].upperButtonGroup,
        this.asInstanceOf[ProjectionSelectorData].middleButtonGroup,
        this.asInstanceOf[ProjectionSelectorData].lowerButtonGroup,
        newVal.get
      )
      this
        .asInstanceOf[VueComponent[_, _, _]]
        .$emit("projectionChoicesWithData", data.asInstanceOf[Event])
      ProjectionSelector.logger.debug("Computed changed")
    } else if (newVal.isEmpty)
      ProjectionSelector.logger.warn("None happened at watcher for Projection")
  }
}

class ProjectionSelectorData extends js.Object {
  var firstLevel = false
  var middleLevel = false
  var bottomLevel = false
  var converted: Option[UtilProjection[Int]] = None

  var durationDisable = false

  var upperButtonGroup: Option[String] = None
  var middleButtonGroup: Option[String] = None
  var lowerButtonGroup: Option[String] = None

  var allProjectionInfo: Option[ProjectionChoicesWithData[_]] = None
}

trait ProjectionSelectorComputed extends js.Object {
  def computeProjectionTotal: Option[UtilRootProjection[_]]
}

object ProjectionSelectorComputed {

  def computeProjectionTotal[A]
      : js.ThisFunction1[ProjectionSelectorData, ProjectionSelectorProps, Option[
        UtilProjection[_]
      ]] = { (a, b) =>
    val statButton = a.middleButtonGroup
    val projectionButton = a.lowerButtonGroup

    def statButtonFetcher(s: String): ReportData => Stats = s match {
      case "Totals" => _.totalStats
      case "Ring" => _.ringStats
      case "Talk" => _.talkStats
    }

    def projectionButtonFetcher(s: String): Stats => Any = s match {
      case "SUM" => _.sum
      case "MIN" => _.min.getOrElse(0)
      case "MAX" => _.max.getOrElse(0)
      case "AVG" => _.average.getOrElse(0)
    }

    a.firstLevel match {
      case false if a.upperButtonGroup.contains("Durations") =>
        Some(ReportProjections.projectReport(b.graphDataList.get, _.totalStats.sum))
      case false if (a.middleButtonGroup.isDefined && a.lowerButtonGroup.isEmpty) =>
        a.middleButtonGroup match {
          case Some("Ring") =>
            Some(ReportProjections.projectReport(b.graphDataList.get, _.ringStats.sum))
          case Some("Talk") =>
            Some(ReportProjections.projectReport(b.graphDataList.get, _.talkStats.sum))
          case Some("Totals") =>
            Some(ReportProjections.projectReport(b.graphDataList.get, _.totalStats.sum))
          case x =>
            ProjectionSelector.logger.error(
              s"A button was selected which could not be recognised: $x"
            )
            None
        }
      case false =>
        for {
          definitelyStatButton <- statButton
          definitelyProjectionButton <- projectionButton
          data <- b.graphDataList.toOption
        } yield {
          val statFetcher = statButtonFetcher(definitelyStatButton)
          val statValueFetcher = projectionButtonFetcher(definitelyProjectionButton)
          ReportProjections.projectReport(data, statValueFetcher.compose(statFetcher))
        }
      case true => Some(ReportProjections.projectReport(b.graphDataList.get, _.total))
    }
  }

  def apply(): ProjectionSelectorComputed = DynamicHelper.buildViaDynamic(
    "computeProjectionTotal" -> computeProjectionTotal
  )
}

trait ProjectionSelectorProps extends VueProps {
  val graphDataList: js.UndefOr[RootReportData] = js.undefined
}

object ProjectionSelectorProps {
  def apply(
      graphDataList: js.UndefOr[RootReportData] = js.undefined
  ): ProjectionSelectorProps = {

    js.Dynamic
      .literal(
        "graphDataList" -> graphDataList.map(_.asInstanceOf[js.Any])
      )
      .asInstanceOf[ProjectionSelectorProps]
  }
}

trait ProjectionSelectorEvents extends EventBindings {
  def projectionChoicesWithData(e: ProjectionChoicesWithData[_]): Unit
}

object ProjectionSelectorEvents {
  def apply(
      bindings: EventBindings = EventBindings(),
      projectionChoicesWithData: js.UndefOr[js.Function1[ProjectionChoicesWithData[_], Unit]] =
        js.undefined
  ) = {
    if (projectionChoicesWithData.isDefined)
      bindings
        .asInstanceOf[js.Dynamic]
        .updateDynamic("projectionChoicesWithData")(projectionChoicesWithData)
    val selectedBinding = bindings.asInstanceOf[ProjectionSelectorEvents]
    selectedBinding
  }
}
