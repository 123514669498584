package com.sludg.client.components

import cats.data.EitherT
import cats.implicits._
import com.sludg.auth0.SludgToken
import com.sludg.client.components.ReportSelector.{ReportOption, ReportSelectorOption}
import com.sludg.client.components.tables.GroupingTableModels.TableColumnsWithSorting
import com.sludg.client.pages.{ReportPageData}
import com.sludg.components.Snackbar.SnackBarMessage
import com.sludg.helpers.LoadingFuture
import com.sludg.models.Models.AccessForbidden
import com.sludg.services.ApiCalls
import com.sludg.util.models.CallModels
import com.sludg.util.models.GroupingModels.Category
import com.sludg.util.models.ReportModels.Filter.{AnswerFilter, DirectionFilter, TerminationFilter}
import com.sludg.util.models.ReportModels.ProjectionType._
import com.sludg.util.models.ReportModels.{Filter, Report, ReportColumn, Sort}
import com.sludg.vue._
import com.sludg.vuetify.VuetifyComponents.vSubheader
import com.sludg.vuetify.components._
import monix.execution.Scheduler.Implicits.global
import org.log4s.getLogger

import scala.concurrent.Future
import scala.reflect.ClassTag
import scala.scalajs.js
import scala.scalajs.js.JSConverters._
import scala.scalajs.js.{UndefOr, |}
import org.scalajs.dom.raw.Event

import scala.collection.immutable.List

/** @author dpoliakas
  *         Date: 29/11/2018
  *         Time: 09:20
  */
object ReportSelector {

  private[components] val logger = getLogger

  sealed trait ReportSelectorOption

  type ReportSelectorComponent =
    VueComponent[_ <: ReportSelectorProps, _ <: Slots, _ <: ScopedSlots]
      with ReportSelectorData
      with ReportSelectorMethods
      with js.Object
      with ReportSelectorProps

  case class ReportOption(report: Report) extends ReportSelectorOption

  case class PresetOption(
      presetName: String,
      presetFilters: List[Filter],
      presetGroupings: List[Category[_]],
      selectedHeaders: List[ReportColumn]
  ) extends ReportSelectorOption

  val defaultColumnList = List(
    ReportColumn(201, "Total Calls", Sort.Neutral, List(), TotalCalls, 2),
    ReportColumn(202, "Total Talk Time", Sort.Neutral, List(), TotalTalkTime, 1),
    ReportColumn(203, "Average Talk Time", Sort.Neutral, List(), AvgTalkTime, 1),
    ReportColumn(204, "Min Talk Time", Sort.Neutral, List(), MinTalkTime, 1),
    ReportColumn(205, "Max Talk Time", Sort.Neutral, List(), MaxTalkTime, 1),
    ReportColumn(206, "Total Ring Time", Sort.Neutral, List(), TotalRingTime, 1),
    ReportColumn(207, "Average Ring Time", Sort.Neutral, List(), AvgRingTime, 1),
    ReportColumn(208, "Min Ring Time", Sort.Neutral, List(), MinRingTime, 1),
    ReportColumn(209, "Max Ring Time", Sort.Neutral, List(), MaxRingTime, 1)
  )

  val presets = List(
    PresetOption(
      "Missed Calls",
      List(DirectionFilter(List(CallModels.Direction.Inbound)), AnswerFilter(false)),
      List(Category.LastExtension),
      List(
        ReportColumn(101, "Total Calls", Sort.Neutral, List(), TotalCalls, 1),
        ReportColumn(102, "Total Ring Time", Sort.Neutral, List(), TotalRingTime, 1),
        ReportColumn(103, "Min Ring Time", Sort.Neutral, List(), MinRingTime, 1),
        ReportColumn(104, "Max Ring Time", Sort.Neutral, List(), MaxRingTime, 1),
        ReportColumn(105, "Average Ring Time", Sort.Neutral, List(), AvgRingTime, 1)
      )
    ),
    PresetOption(
      "Busiest Time Of Day (Inbound)",
      List(DirectionFilter(List(CallModels.Direction.Inbound))),
      List(Category.HourOfDay),
      defaultColumnList
    ),
    PresetOption(
      "Busiest Time Of Day (Outbound)",
      List(DirectionFilter(List(CallModels.Direction.Outbound))),
      List(Category.HourOfDay),
      defaultColumnList
    ),
    //    PresetOption("Busiest Caller", List(DirectionFilter(List(CallModels.Direction.Outbound))), List(Category.LastExtension), defaultColumnList),
    PresetOption(
      "Top Callers",
      List(DirectionFilter(List(CallModels.Direction.Outbound))),
      List(Category.LastExtension),
      defaultColumnList
    ),
    PresetOption(
      "Top Answerers",
      List(
        DirectionFilter(List(CallModels.Direction.Inbound)),
        TerminationFilter(List(CallModels.TerminationPoint.Human)),
        AnswerFilter(true)
      ),
      List(Category.LastExtension),
      defaultColumnList
    ),
    PresetOption(
      "Busiest Users",
      List(AnswerFilter(true)),
      List(Category.LastExtension),
      defaultColumnList
    ),
    PresetOption(
      "User Activity Report",
      List(DirectionFilter(List(CallModels.Direction.Inbound, CallModels.Direction.Outbound))),
      List(Category.LastExtension, Category.Direction, Category.Answer),
      defaultColumnList
    )
    //    PresetOption("Busiest Number", List(DirectionFilter(List(CallModels.Direction.Inbound)), AnswerFilter(true)), List(Category.None), Nil),
  ).map(p => Right(p))

  val reportPresets = List(
    PresetOption
  )

  import com.sludg.vue.RenderHelpers._
  import com.sludg.vuetify.VuetifyComponents._

  def reportSelectorRenderer(registrationName: String) =
    namedTag[ReportSelectorProps, ReportSelectorEvents, ScopedSlots](registrationName)

  def noMatchItemRenderer(currentInput: Option[String], save: () => Unit) = {
    vListTile(
      currentInput match {
        case Some(input) =>
          Seq(
            vListTileTitle(
              "Unsaved Report: " + input
            ),
            vListTileAction(
              vButton(
                click(_ => save())
              )(
                span("Save "),
                vIcon("save")
              )
            )
          )
        case None =>
          Seq(vListTileTitle("It seems you have no reports saved. Start typing to save a new one."))
      }
    )
  }

  def reportItemRenderer(
      item: Report,
      component: ReportSelectorData
  ): js.Array[RenderFunction[VNode]] = {
    js.Array(
      vListTileContent(
        item.name
      ),
      vSpacer,
      vButton(
        RenderOptions(
          props = Some(VButtonProps(icon = Some(true))),
          on = Some(
            EventBindings(
              click = js.defined(e => {
                component.vDialogVisible = true
                component.reportToBeDeleted = Some(item)
                e.stopPropagation()
                e.preventDefault()
              })
            )
          )
        ),
        vIcon("delete")
      )
    )
  }

  def setNewFiltersAndGroupings(
      newlySelectedOption: Option[ReportSelectorOption],
      data: ReportSelectorData,
      component: VueComponent[_, _, _],
      filters: List[Filter],
      groupings: List[Category[_]],
      selectedHeaders: List[ReportColumn]
  ): Unit = {
    data.selectedReport = newlySelectedOption
    data.lastSavedFiltersOfCurrentReport = filters
    data.lastSavedCategoriesOfCurrentReport = groupings
    data.lastSavedSelectedHeadersOfCurrentReport = selectedHeaders
    component.$emit(
      "reportSelected",
      (
        newlySelectedOption.collect {
          case ReportOption(report) =>
            report
        },
        filters,
        groupings,
        selectedHeaders
      )
    )
  }

  def inputHandler(
      eventBus: Vue,
      api: ApiCalls,
      data: ReportSelectorData,
      component: VueComponent[_, _, _]
  )(implicit
      sludgToken: SludgToken
  ): js.Function1[js.UndefOr[ReportSelectorOption | String], Unit] =
    event => {

      def clearSelection() = {
        setNewFiltersAndGroupings(None, data, component, Nil, Nil, Nil)
        logger.debug("Report selection cleared")
      }

      if (event.isDefined) {
        val eventUntyped = event.get
        (eventUntyped: Any) match {
          case eee: ReportSelectorOption =>
            data.currentlyLoading = true
            eee match {
              case a @ PresetOption(_, filters, groupings, headers) =>
                setNewFiltersAndGroupings(Some(a), data, component, filters, groupings, headers)
              case a @ ReportOption(report) =>
                LoadingFuture.withLoading(
                  eventBus,
                  (for {
                    filters <- EitherT(api.getCallReportWithFilters(report.tenantId, report.id))
                    groupings <- EitherT(api.getCurrentGrouping(report.tenantId, report.id))
                    selectedHeaders <- EitherT(api.getTableColumns(report.tenantId, report.id))
                  } yield {
                    data.currentlyLoading = false
                    logger.debug(s"Selected Headers: $selectedHeaders")
                    filters.foreach(f =>
                      setNewFiltersAndGroupings(
                        Some(a),
                        data,
                        component,
                        f.filters,
                        groupings,
                        selectedHeaders
                      )
                    )
                  }).value.map {
                    case Right(_) =>
                      logger.debug("Successfully fetched the filters and groupings for the user")
                    case Left(AccessForbidden(m)) =>
                      logger.error(
                        "The currently logged in user was denied the request to get the filters/groupings: " + m
                      )
                  }
                )
            }
          case eee: String =>
            logger.info("String input!")
            data.selectedReport = None
          case _ => clearSelection()
        }
      } else {
        clearSelection()
      }
    }

  def saveOrUpdate(
      apiCalls: ApiCalls,
      eventBus: Vue,
      data: ReportSelectorData,
      methods: ReportSelectorMethods,
      props: ReportSelectorProps
  )(implicit sludgToken: SludgToken): Unit = {

    data.selectedReport match {
      case Some(ReportOption(report)) =>
        data.lastSavedReport = Some(report)
        methods.updateReport(
          apiCalls,
          eventBus,
          data,
          report,
          props.filterInput,
          props.categoryInput,
          props.selectedHeadersInput
        )
      case Some(PresetOption(presetName, presetFilters, presetGroupings, selectedHeaders)) =>
        methods.createReport(
          apiCalls,
          eventBus,
          data,
          props.selectedTenantId,
          s"Copy of: $presetName",
          props.filterInput,
          props.categoryInput,
          props.selectedHeadersInput
        )
      case None if data.currentInput.exists(_.nonEmpty) =>
        methods.createReport(
          apiCalls,
          eventBus,
          data,
          props.selectedTenantId,
          data.currentInput.get,
          props.filterInput,
          props.categoryInput,
          props.selectedHeadersInput
        )
      case None =>
        // otherwise we have no reasonable course of action
        ()
    }

  }

  // GLORY TO CHNAG THE RADIANT DARK
  def unsavedChnagesExist(dataAndProps: ReportSelectorData with ReportSelectorProps): Boolean = {

    // Mapped because report column id can't be guessed!
    val tableColumnDataAndProps = dataAndProps.lastSavedSelectedHeadersOfCurrentReport.map(a => {
      TableColumnsWithSorting(a.name, a.sort, a.columns, a.projectionType)
    })

    val tableColumnInput = dataAndProps.selectedHeadersInput.map(a => {
      TableColumnsWithSorting(a.name, a.sort, a.columns, a.projectionType)
    })

    val tableHeaderDiscrep = tableColumnDataAndProps.forall(tableColumnInput contains _) &&
      tableColumnInput.forall(tableColumnDataAndProps contains _)

    val filterDiscrep =
      dataAndProps.lastSavedFiltersOfCurrentReport.forall(dataAndProps.filterInput contains _) &&
        dataAndProps.filterInput.forall(dataAndProps.lastSavedFiltersOfCurrentReport contains _)

    val categoryDiscrep =
      dataAndProps.lastSavedCategoriesOfCurrentReport == dataAndProps.categoryInput

    val noDiscrepancies: Boolean = tableHeaderDiscrep && filterDiscrep && categoryDiscrep

    val somethingWasSelected = dataAndProps.selectedReport.isDefined

    (somethingWasSelected && !noDiscrepancies) || (!somethingWasSelected && dataAndProps.currentInput
      .exists(_.nonEmpty))

  }

  class ReportPageWatcher() extends js.Object {
    def selectedTenantId(tid: Int, tid2: Int): Unit = {

      if (tid != tid2) {
        val r = this.asInstanceOf[ReportSelectorComponent]
        (r.apiCalls, r.loader, r.token).mapN((a, l, t) => {
          {
            r.$emit(
              "reportSelected",
              (
                None,
                Nil,
                Nil,
                Nil
              )
            )
            r.selectedReport = None
            r.getCallReports(a, l, tid)(t)
          }
        })
      }
    }
  }

  def reportSelectorComponent(apiCalls: ApiCalls, loader: Vue)(implicit
      token: SludgToken
  ) = {
    VueComponent.builder
      .withData(new ReportSelectorData())
      .withProps[ReportSelectorProps](
        js.Array(
          "selectedTenantId",
          "displayPresets",
          "toolbarize",
          "filterInput",
          "withSaving",
          "categoryInput",
          "selectedHeadersInput",
          "token"
        ).asInstanceOf[ReportSelectorProps]
      )
      .withMethods(new ReportSelectorMethods)
      .build(
        watch = new ReportPageWatcher(),
        created = js.defined(c => {
          c.apiCalls = Some(apiCalls)
          c.loader = Some(loader)
          c.asInstanceOf[ReportSelectorComponent]
            .getCallReports(apiCalls, loader, c.selectedTenantId)
        }),
        templateOrRender = Right((component, renderer) => {

          def createReport(): Unit = {
            component.currentInput match {
              case Some(input) =>
                component.createReport(
                  apiCalls,
                  loader,
                  component,
                  component.selectedTenantId,
                  input,
                  component.filterInput,
                  component.categoryInput,
                  component.selectedHeadersInput
                )
              case None =>
                ReportSelector.logger.warn("Tried to save a report with no name. Why?!")
            }
          }

          def assembleSelectorOptions()
              : List[Either[VComboboxProps.StaticItem, ReportSelectorOption]] = {

            val preset = if (component.displayPresets) {
              Left(new VComboboxProps.StaticItem("PRESET REPORTS")) :: presets
            } else Nil

            val reports = if (component.reportList.nonEmpty) {
              Left(new VComboboxProps.StaticItem("SAVED REPORTS")) :: component.reportList
                .map((ReportOption.apply _).andThen(r => Right(r)))
            } else {
              Nil
            }

            preset ::: reports
          }

          div(
            vCombobox[ReportSelectorOption](
              RenderOptions(
                props = Some(
                  VComboboxProps[ReportSelectorOption](
                    items = Some(assembleSelectorOptions()),
                    value = component.selectedReport.map(Right.apply),
                    clearable = Some(true),
                    flat = Some(component.toolbarize),
                    `solo-inverted` = Some(component.toolbarize),
                    `hide-details` = Some(component.toolbarize),
                    placeholder = Some("Report"),
                    `item-text` = Some(Right {
                      case PresetOption(presetName, _, _, _) => presetName
                      case ReportOption(Report(_, _, name, _)) => name
                    }),
                    `item-value` = Some(Right(r => r))
                  )
                ),
                domProps = Some(new DomProps(value = component.selectedReport.orUndefined)),
                scopedSlots = Some(new VComboboxScopedSlots[ReportSelectorOption] {
                  override val item: UndefOr[js.Function1[VComboboxItemSlotScope[
                    ReportSelectorOption
                  ], VNode | js.Array[VNode]]] = js.defined(itemData =>
                    (itemData.item match {
                      case PresetOption(
                            presetName,
                            presetFilters,
                            presetGroupings,
                            selectedHeaders
                          ) =>
                        vListTileContent(presetName).render(renderer)
                      case ReportOption(report) =>
                        reportItemRenderer(report, component).map(_.render(renderer))
                    })
                  )
                }),
                on = Some(
                  VAutocompleteEventBindings(
                    input = js
                      .defined(event =>
                        inputHandler(loader, apiCalls, component, component)(implicitly)(
                          event.asInstanceOf[ReportSelectorOption | String]
                        )
                      ),
                    search = js.defined(input => {
                      component.currentInput = Option(input).map(_.toString).filter(_.nonEmpty)
                    })
                  )
                )
              )
            )(
              template(RenderOptions(slot = Some("no-data")))(
                noMatchItemRenderer(
                  component.currentInput,
                  (() => createReport())
                )
              ),
              if (component.withSaving) {
                template(RenderOptions(slot = Some("prepend-inner")))(
                  vSlideXTransition(
                    if (!component.currentlyLoading && unsavedChnagesExist(component)) {
                      vButton(
                        RenderOptions(
                          props = Some(VButtonProps(icon = Some(true))),
                          slot = Some("prepend-inner"),
                          on = Some(click(e => {
                            ReportSelector.logger.trace("Save button clicked")
                            e.stopPropagation()
                            e.preventDefault()
                            saveOrUpdate(apiCalls, loader, component, component, component)
                          }))
                        ),
                        vIcon("save")
                      )
                    } else
                      nothing
                  )
                )
              } else {
                nothing
              }
            ),
            makeDeletionDialog(apiCalls, loader, component)
          ).render(renderer)
        })
      )
  }

  private def makeDeletionDialog(
      apiCalls: ApiCalls,
      eventBus: Vue,
      component: ReportSelectorData with ReportSelectorMethods
  )(implicit sludgToken: SludgToken) = {
    vDialog(
      RenderOptions(
        style = Some(
          js.Dynamic.literal(
            "text-align" -> "center",
            "box-shadow" -> "0px"
          )
        ),
        props = Some(
          VDialogProps(
            value = Some(component.vDialogVisible),
            width = Some(Right(200)),
            scrollable = Some(false),
            //                  persistent = Some(true),
            `max-width` = Some(Right(550))
          )
        ),
        on = Some(
          EventBindings(
            input = js.defined(e => {
              component.vDialogVisible = e.asInstanceOf[Boolean]
            })
          )
        )
      ),
      vCard(
        vCardTitle(
          RenderOptions(
            `class` = List(Left("headline"))
          ),
          s"Delete Report: ${component.reportToBeDeleted.getOrElse(Report(0, 0, " ", List())).name}"
        ),
        vDivider,
        vCardText(
          "Are you sure you want to delete this report? Deleting it will also delete any schedules associated with it."
        ),
        vSubheader("Would you like to proceed?"),
        vCardActions(
          vSpacer,
          vButton(
            EventBindings(
              click = js.defined(e => {
                component.vDialogVisible = false
              })
            ),
            "Cancel"
          ),
          vButton(
            RenderOptions(
              props = Some(
                VButtonProps(
                  color = Some("red")
                )
              ),
              on = Some(
                EventBindings(
                  click = js.defined(e => {
                    component.vDialogVisible = false
                    if (component.reportToBeDeleted.isDefined) {
                      component.deleteReport(
                        apiCalls,
                        eventBus,
                        component.reportToBeDeleted.get,
                        component
                      )
                    } else {
                      println("Error: The specified report could not be found for deletion")
                    }
                    component.reportToBeDeleted = None
                  })
                )
              )
            ),
            "Delete"
          )
        )
      )
    )
  }
}

class ReportSelectorMethods extends js.Object {

  def getSelectedHeaders(apiCalls: ApiCalls, tenantId: Int, reportId: Int, eventBus: Vue)(implicit
      sludgToken: SludgToken
  ) = {
    LoadingFuture.withLoading(
      eventBus,
      apiCalls.getTableColumns(tenantId, reportId).flatMap {
        case Right(result) =>
          ReportSelector.logger.debug("Received selected headers from api")
          this.asInstanceOf[ReportPageData].selectedHeaders = result
          Future.successful(result)
        case Left(error) =>
          Future.failed(LoadingFuture.AccessForbiddenFailure("Cannot fetch selected headers "))
      }
    )
  }

  def getCallReports(apiCalls: ApiCalls, eventBus: Vue, tenantId: Int)(implicit
      sludgToken: SludgToken
  ) = {
    ReportSelector.logger.debug("Getting reports")
    LoadingFuture.withLoading(
      eventBus,
      apiCalls.getAllCallReports(tenantId = tenantId).flatMap {
        case Right(result) =>
          this.asInstanceOf[ReportSelectorData].reportList = result
          Future.successful(result)
        case Left(error) =>
          ReportSelector.logger.error("Error getting call reports: " + error.toString)
          Future.failed(
            LoadingFuture
              .AccessForbiddenFailure("Cannot fetch call reports using given user credentials")
          )
      }
    )
  }

  def updateReport(
      apiCalls: ApiCalls,
      eventBus: Vue,
      data: ReportSelectorData,
      report: Report,
      filterInput: List[Filter],
      categoryInput: List[Category[_]],
      selectedHeaders: List[ReportColumn]
  )(implicit sludgToken: SludgToken): Unit = {

    LoadingFuture.withLoading(
      eventBus,
      (for {
        _ <- EitherT(apiCalls.updateGroupings(report.tenantId, report.id, categoryInput))
        _ <- EitherT(apiCalls.updateCallReport(report.tenantId, report.id, filterInput))
        _ <- EitherT(apiCalls.updateTableColumns(report.tenantId, report.id, selectedHeaders))
      } yield {
        ReportSelector.logger.debug(s"Selected headers $selectedHeaders")
        data.lastSavedCategoriesOfCurrentReport = categoryInput
        data.lastSavedFiltersOfCurrentReport = filterInput
        data.lastSavedSelectedHeadersOfCurrentReport = selectedHeaders
      }).value
    )
  }

  def createReport(
      apiCalls: ApiCalls,
      eventBus: Vue,
      data: ReportSelectorData,
      tenantId: Int,
      reportName: String,
      currentFilters: List[Filter],
      currentCategories: List[Category[_]],
      selectedHeaders: List[ReportColumn]
  )(implicit sludgToken: SludgToken) = {
    LoadingFuture.withLoading(
      eventBus,
      (for {
        newReportId <- EitherT(apiCalls.createCallReport(tenantId, reportName, currentFilters))
        _ <- EitherT(apiCalls.updateGroupings(tenantId, newReportId, currentCategories))
        _ <- EitherT(apiCalls.updateTableColumns(tenantId, newReportId, selectedHeaders))
      } yield {
        val newReport = Report(newReportId, tenantId, reportName, Nil)
        data.selectedReport = Some(ReportOption(newReport))
        data.reportList = newReport :: data.reportList
        data.lastSavedCategoriesOfCurrentReport = currentCategories
        data.lastSavedFiltersOfCurrentReport = currentFilters
        data.lastSavedSelectedHeadersOfCurrentReport = selectedHeaders
        ReportSelector.logger.info("All good")
      }).value
    )
  }

  def deleteReport(
      apiCalls: ApiCalls,
      eventBus: Vue,
      report: Report,
      reportSelectorData: ReportSelectorData
  )(implicit sludgToken: SludgToken) = {
    LoadingFuture.withLoading(
      eventBus,
      (for {
        _ <- EitherT(apiCalls.deleteCallReport(report.tenantId, report.id))
      } yield {
        if (reportSelectorData.selectedReport.contains(report))
          reportSelectorData.selectedReport = None
        reportSelectorData.reportList = reportSelectorData.reportList.filterNot(_ == report)
      }).value
    )
  }

}

sealed trait ReportSelectorProps extends VueProps {
  var selectedTenantId: Int

  val displayPresets: Boolean
  val toolbarize: Boolean
  val withSaving: Boolean

  val filterInput: List[Filter]
  val categoryInput: List[Category[_]]
  val selectedHeadersInput: List[ReportColumn]
  val token: Option[SludgToken]

}

object ReportSelectorProps {
  def apply(
      selectedTenantId: Int,
      displayPresets: Boolean = false,
      toolbarize: Boolean = false,
      withSaving: Boolean = false,
      filterInput: List[Filter] = Nil,
      categoryInput: List[Category[_]] = Nil,
      selectedHeadersInput: List[ReportColumn] = Nil,
      token: Option[SludgToken] = None
  ): ReportSelectorProps = {
    js.Dynamic
      .literal(
        "selectedTenantId" -> selectedTenantId,
        "displayPresets" -> displayPresets,
        "toolbarize" -> toolbarize,
        "withSaving" -> toolbarize,
        "filterInput" -> filterInput.asInstanceOf[js.Object],
        "categoryInput" -> categoryInput.asInstanceOf[js.Object],
        "selectedHeadersInput" -> selectedHeadersInput.asInstanceOf[js.Object],
        "token" -> token.asInstanceOf[js.Object]
      )
      .asInstanceOf[ReportSelectorProps]
  }
}

class ReportSelectorData extends js.Object {
  var token: Option[SludgToken] = None
  var apiCalls: Option[ApiCalls] = None
  var loader: Option[Vue] = None

  var reportList: List[Report] = List()
  var selectedReport: Option[ReportSelectorOption] = None
  var lastSavedReport: Option[Report] = None
  var lastSavedFiltersOfCurrentReport: List[Filter] = Nil
  var lastSavedCategoriesOfCurrentReport: List[Category[_]] = Nil
  var lastSavedSelectedHeadersOfCurrentReport: List[ReportColumn] = Nil
  var vDialogVisible = false
  var reportToBeDeleted: Option[Report] = None

  var currentInput: Option[String] = None
  var currentlyLoading: Boolean = false
}

trait ReportSelectorEvents extends EventBindings {
  def reportSelected(e: (Option[Report], List[Filter], List[Category[_]], List[ReportColumn])): Unit

}

object ReportSelectorEvents {
  def apply(
      bindings: EventBindings = EventBindings(),
      reportSelected: js.Function1[
        (Option[Report], List[Filter], List[Category[_]], List[ReportColumn]),
        Unit
      ]
  ) = {
    bindings.asInstanceOf[js.Dynamic].updateDynamic("reportSelected")(reportSelected)
    val reportSelectedBinding = bindings.asInstanceOf[ReportSelectorEvents]
    reportSelectedBinding
  }
}
