package com.sludg.client.pages

import cats._
import cats.data.EitherT
import cats.implicits._
import com.sludg.client.components._
import com.sludg.components.filters.FilterHelpers
import com.sludg.client.pages.ReportPageRenderFunctions._
import com.sludg.client.AnalyticsApp
import com.sludg.helpers.AppSetup.{VtslAppProps, VtslAppScopedSlots}
import com.sludg.helpers.LoadingFuture
import com.sludg.models.Config.AppLink
import com.sludg.util.models.CallModels.CDR
import com.sludg.util.models.GroupingModels._
import com.sludg.util.models.ReportModels.Filter.RelativeDateFilter
import com.sludg.util.models.ReportModels._
import com.sludg.util.models.SilhouetteModels.Tenant
import com.sludg.util.models.{GroupingModels, SilhouetteModels}
import com.sludg.vue.RenderHelpers._
import com.sludg.vue.{EventBindings, RenderOptions, Vue, _}
import com.sludg.Security
import com.sludg.auth0.SludgToken
import com.sludg.model.Models
import com.sludg.model.Models.SubscriberWithAdminStatus
import com.sludg.models.Models.AccessForbidden
import com.sludg.services.ApiCalls
import com.sludg.util.models.RoleModels.{AssignedRoles, Role}
import com.sludg.util.models.RoleModels.Role.{AnalyticsAdmin, DashboardAdmin}
import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.vuetify.VuetifyComponents.{vCardText, vContainer, vIcon}
import monix.execution.Scheduler.Implicits.global
import org.log4s.getLogger
import org.scalajs.dom.Event
import org.scalajs.dom.raw.Blob

import scala.concurrent.Future
import scala.scalajs.js
import com.sludg.util.AsyncFetcher

object ReportPage {

  private[pages] val logger = getLogger

  type ReportPageComponent = VueComponent[_ <: VueProps, _ <: Slots, _ <: ScopedSlots]
    with ReportPageData
    with js.Object
    with VueProps
    with WithRefs[_ <: Refs]

  def reportPageRenderer(registrationName: String) =
    namedTag[VueProps, EventBindings, ScopedSlots]("ReportPage")

  val reportSelector = ReportSelector.reportSelectorRenderer("ReportSelector")
  val filterManager = FilterManager.filterManagerRenderer("FilterManager")
  val sunburstGraph = SunburstGraph.sunburstGraphRenderrer("SunburstGraph")
  val groupSelector = GroupSelector.groupSelectorRenderer("GroupSelector")
  val graphManager = GraphManager.graphManagerRenderer("GraphManager")
  val analyticsApp = AnalyticsApp.analyticsAppRenderer("AnalyticsApp")
  val fileSaver = namedTag[VueProps, EventBindings, ScopedSlots]("saveAs")
  val tenantData = AsyncFetcher.rendererSimplified[(Tenant, SludgToken), Either[
    AccessForbidden,
    (
        List[SilhouetteModels.Subscriber],
        List[SilhouetteModels.AutoAttendant],
        List[SilhouetteModels.CallGroup]
    )
  ]]("TenantDataFetcher")

  def isReportSelected(data: ReportPageData) = data.selectedReport.isDefined

  def reportPageComponent(
      apiCalls: ApiCalls,
      security: Security,
      loader: Vue,
      otherApps: List[AppLink]
  )(implicit token: SludgToken) = {

    val data = new ReportPageData()

    VueComponent.builder
      .withData(data)
      .build(
        created = js.defined(p => {
          token.subscribers.headOption match {
            case Some(user) if user.admin || token.isSuperUser => setLoadingOptions(p, true)
            case Some(user) if !user.admin =>
              LoadingFuture.withLoading(
                loader,
                apiCalls.getUserRoles(user.tid.toInt, user.sid)(implicitly, token).map {
                  case Right(Some(AssignedRoles(_, roles)))
                      if roles.contains(Role.AnalyticsAdmin) =>
                    setLoadingOptions(p, true)
                  case _ => setLoadingOptions(p, false)
                }
              )
            case _ => div("")
          }

        }),
        components = js.Dynamic.literal(
          "AnalyticsApp" -> AnalyticsApp
            .analyticsAppComponent(apiCalls, security, loader, otherApps),
          "ReportSelector" -> ReportSelector.reportSelectorComponent(apiCalls, loader),
          "FilterManager" -> FilterManager.filterManagerComponent(),
          "SunburstGraph" -> SunburstGraph.sunburstGraphComponent(),
          "GroupSelector" -> GroupSelector.groupSelectorComponent(apiCalls, loader),
          "GraphManager" -> GraphManager.graphManagerComponent(),
          "TenantDataFetcher" -> AsyncFetcher[
            (Tenant, SludgToken),
            Either[
              AccessForbidden,
              (
                  List[SilhouetteModels.Subscriber],
                  List[SilhouetteModels.AutoAttendant],
                  List[SilhouetteModels.CallGroup]
              )
            ]
          ](tnt => loadNewTenantData(tnt._1, apiCalls, loader, tnt._2), loader, true)
        ),
        templateOrRender = Right((p, renderer) => {
          analyticsApp(
            RenderOptions(
              on = Some(
                js.Dynamic
                  .literal(
                    "submit" -> ((e => {}): js.Function1[Event, Unit])
                  )
                  .asInstanceOf[EventBindings]
              ),
              props = Some(
                new VtslAppProps(
                  title = js.defined("Analytics"),
                  spacedToolbar = true,
                  token = Some(token)
                )
              ),
              scopedSlots = Some(
                new VtslAppScopedSlots {
                  override val default: js.UndefOr[js.Function1[Option[Tenant], VNode]] =
                    js.defined(tenantOpt => {
                      tenantOpt match {
                        case Some(tenant) if (p.isUserChecked && p.adminAccess) =>
                          loadAnalytics(token, apiCalls, loader, p, tenant, renderer)
                        case Some(tenant) if (p.isUserChecked && !p.adminAccess) =>
                          accessRestricted(renderer)
                        case _ =>
                          div().render(renderer)
                      }
                    })
                }
              )
            )
          ).render(renderer)
        })
      )

  }

  private def setLoadingOptions(
      p: ReportPageComponent,
      adminAccess: Boolean,
      isUserChecked: Boolean = true
  ) = {
    p.adminAccess = adminAccess
    p.isUserChecked = isUserChecked
  }

  private def accessRestricted(renderer: CreateElement) = {
    div(
      vContainer(
        vCardText(
          "Access to Analytics requires administrator privileges.",
          RenderOptions(`class` = List(Left("headline")))
        ),
        p(
          RenderOptions(style = Some(js.Dynamic.literal("padding-left" -> "16px"))),
          "Please talk to your administrator if you believe this to be an error."
        ),
        p(
          RenderOptions(style = Some(js.Dynamic.literal("padding-left" -> "16px"))),
          "Your administrator can grant you access through the User Portal."
        )
      )
    ).render(renderer)
  }

  private def loadAnalytics(
      token: SludgToken,
      apiCalls: ApiCalls,
      loader: Vue,
      component: ReportPageComponent,
      tenant: Tenant,
      renderer: CreateElement
  ) = {

    (tenantData(tenant, token) {
      case None => div()
      case Some(Left(_)) => div("You do not have access to this tenant's data.")
      case Some(Right((tenantSubs, tenantAa, tenantCg))) =>
        div(
          buildReportSelector(component, apiCalls, loader, tenant)(token),
          buildGroupingComponent(component),
          addFilterManager(tenantSubs, tenantAa, tenantCg, component, apiCalls, loader),
          lowerButtons(component, tenant, apiCalls, loader)(token),
          itemisationDialog(tenant, component, component, apiCalls, loader)(token),
          if (component.reportData.isDefined)
            buildGraphManager(component, apiCalls, tenant, loader)
          else nothing
        )
    }).render(renderer)
  }

  def loadNewTenantData(newTenant: Tenant, apiCalls: ApiCalls, eventBus: Vue, token: SludgToken) = {
    implicit val t = token

    LoadingFuture.withLoading(
      eventBus,
      (for {
        subs <- EitherT(apiCalls.getSubscribers(newTenant.id))
        autoAttendants <- EitherT(apiCalls.getAutoAttendants(newTenant.id))
        callGroups <- EitherT(apiCalls.getCallGroups(newTenant.id))
      } yield {
        (
          subs.sortBy(_.extension),
          autoAttendants.sortBy(_.extension),
          callGroups.sortBy(_.extension)
        )
      }).value
    )
  }

  def queryCallReport(
      apiCalls: ApiCalls,
      tenantId: Int,
      filters: List[Filter],
      groupings: List[GroupingModels.Category[_]],
      eventBus: Vue
  )(implicit
      token: SludgToken
  ): Future[(RootReportData, List[CDR])] = {
    ReportPage.logger.debug("Querying in state")
    val tuple = (filters, groupings)
    LoadingFuture
      .withLoading(
        eventBus,
        for {
          callReportResponse <- apiCalls.queryCallReport(tenantId, tuple)
          cdrResponse <- apiCalls.queryCalls(tenantId, filters)
        } yield {
          Applicative[Future].tuple2(
            callReportResponse match {
              case Right(Some(data)) => Future.successful(data)
              case Left(AccessForbidden(message)) =>
                Future.failed(
                  new Exception("You are not allowed to view the calls with the given parameters")
                )
              case _ => Future.failed(new Exception("Data fetch due to unknown reasons"))
            },
            cdrResponse match {
              case Right(cdrs) => Future.successful(cdrs)
              case Left(AccessForbidden(message)) =>
                Future.failed(
                  new Exception("You are not allowed to view the calls with the given parameters")
                )
              case _ => Future.failed(new Exception("Data fetch due to unknown reasons"))
            }
          )
        }
      )
      .flatten
  }

  def createSchedule(
      apiCalls: ApiCalls,
      tenantId: Int,
      reportId: Int,
      reportSchedule: ReportSchedule
  )(implicit token: SludgToken) = {
    apiCalls
      .createTenantSchedule(tenantId, reportSchedule)
      .map(result => {
        result match {
          case Left(x) => ReportPage.logger.error("Oh no" + x)
          case Right(x) => ReportPage.logger.debug("Worked?" + x)
        }
        ReportPage.logger.debug("Schedule created")
      })
  }

}

class ReportPageData extends js.Object {
  var loading: Boolean = false
  var submitloading: Boolean = false
  var selectedReport: Option[Report] = None

  var lastSubmittedFilters: List[Filter] = FilterHelpers.getBlankFilter[RelativeDateFilter].toList
  var lastSubmittedCategories: List[Category[_]] = Nil
  var lastSubmittedReport: Option[Report] = None

  var selectedFilters: List[Filter] = FilterHelpers.getBlankFilter[RelativeDateFilter].toList
  var selectedCategories: List[Category[_]] = Nil
  var selectedHeaders: List[ReportColumn] = Nil

  var reportData: Option[RootReportData] = None
  var cdrData: Vector[CDR] = Vector()

  var dialogVisible = false

  var includeItemisation: Boolean = false
  var includePDF: Boolean = true
  var includeSpreadsheet: Boolean = false

  var adminAccess = false
  var isUserChecked = false
}

class ReportPageComputed extends js.Object {}
