package com.sludg.client.components.tables

import com.sludg.client.components.tables.GroupingDataTable.GroupingDataTableComponent
import com.sludg.util.RootProjection
import com.sludg.util.models.GroupingModels.{CategoryData, RootReportData}
import com.sludg.util.models.ReportModels.{ProjectionType, Sort}
import com.sludg.util.models.SilhouetteModels.Tenant
import com.sludg.vue.{ScopedSlots, VNode, VueProps}

import scala.scalajs.js
import scala.scalajs.js.JSConverters._

object GroupingTableModels {

  case class GroupingTableInfo(sortBy: NodePathWithData, isDescending: Boolean)

  case class GroupingTableItem(categoryValue: String, mapping: Map[Option[String], CellData])

  sealed trait HeaderWithData extends js.Object {
    val header: js.UndefOr[String] = js.undefined
    val data: js.UndefOr[String] = js.undefined
  }

  object HeaderWithData {
    def apply(
        header: Option[String] = None,
        data: Option[String] = None
    ) = {
      js.Dynamic
        .literal(
          "header" -> header.map(js.Any.fromString).orUndefined,
          "data" -> data.map(js.Any.fromString).orUndefined
        )
        .asInstanceOf[HeaderWithData]
    }
  }

  sealed trait ItemScopedSlotProps extends js.Object {
    val parent: GroupingDataTableComponent
    val item: js.Dynamic
    val tile: js.Dynamic
  }

  trait DataDisplayerProps extends VueProps {
    val projection: RootProjection[_]
    val projectionData: RootReportData
  }

  trait SelectionScopedSlotProps[A] extends VueProps {
    val parent: GroupingDataTableComponent
    val item: A
    val index: Int
    val selected: Boolean
    val disabled: Boolean
  }

  trait VSelectProps extends VueProps {
    val toolbar: js.UndefOr[js.Function1[Option[Tenant], VNode]]
  }

  case class TableColumnsWithSorting(
      customName: String,
      sorting: Sort,
      listOfCategoryData: List[CategoryData[_]],
      projectionType: ProjectionType
  )

}
