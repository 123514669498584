package com.sludg.client.components

import com.sludg.{
  Actions,
  BreadCrumbTrailProps,
  BreadcrumbTrail,
  HighlightOnHover,
  NodeInfoDisplayer,
  NodeInfoDisplayerProps,
  Nodes,
  SunburstChildrenProps,
  VueD3,
  VueD3Props,
  ZoomOnClick
}
import com.sludg.helpers.ChartFormatters
import com.sludg.vue.RenderHelpers._
import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.util.PresenterSyntax._
import com.sludg.util.ApiModelPresenters._
import com.sludg.util.{ChildProjection, Projection => UtilProjection, RootProjection}
import com.sludg.vue._
import com.sludg.vuetify.components.VSubheaderProps
import com.sludg.vuetify.components.grid.VGridProps
import org.log4s.getLogger
import com.sludg.FieldExtractor

import scala.scalajs.js
import scala.scalajs.js.JSConverters._
import scala.scalajs.js.UndefOr

object CustomNodeInfoDisplayer {
  private[this] val logger = getLogger

  import com.sludg.vuetify.VuetifyComponents._

  val nodeInfoDisplayer =
    namedTag[NodeInfoDisplayerProps, EventBindings, ScopedSlots]("nodeInfoDisplayer")

  def CustomNodeInfoDisplayerRenderer(registeredName: String) =
    namedTag[CustomNodeInfoProps, EventBindings, ScopedSlots](registeredName)

  def CustomNodeInfoDisplayerComponent() = {
    VueComponent.builder
      .withScopedSlots[ScopedSlots]
      .withProps(CustomNodeInfoProps())
      .build(
        components = js.Dynamic.literal(
          "NodeInfoDisplayer" -> NodeInfoDisplayer
        ),
        updated = js.defined(c => {
          logger.debug("SunburstGraph updated")
        }),
        templateOrRender = Right((component, renderer) => {
          vContainer(
            RenderOptions(`class` = List(Left("fill-height"))),
            vLayout(
              RenderOptions(
                `class` = List("align-center", "justify-center", "row").map(Left.apply)
              ),
              vFlex(
                if (component.customCurrentNode != null) {
                  Seq(
                    p(component.nodeDescription.get.toString + component.nodeValue),
                    p("" + component.nodeName)
                  )
                } else {
                  Seq(
                    p("Hover to see more"),
                    p("Click to zoom in")
                  )
                }
              )
            )
          ).render(renderer)
        })
      )
  }

}

trait CustomNodeInfoProps extends NodeInfoDisplayerProps {
  val customCurrentNode: js.UndefOr[js.Object] = js.undefined
  val customRoot: js.UndefOr[js.Object] = js.undefined
  val customClicked: js.UndefOr[js.Object] = js.undefined

  val nodeName: js.UndefOr[String] = js.undefined
  val nodeDescription: js.UndefOr[String] = js.undefined
  val nodeValue: js.UndefOr[String] = js.undefined

}

object CustomNodeInfoProps {
  def apply(
      customCurrentNode: js.UndefOr[js.Object] = js.undefined,
      customRoot: js.UndefOr[js.Object] = js.undefined,
      customClicked: js.UndefOr[js.Object] = js.undefined,
      nodeName: js.UndefOr[String] = js.undefined,
      nodeDescription: js.UndefOr[String] = js.undefined,
      nodeValue: js.UndefOr[String] = js.undefined
  ): CustomNodeInfoProps = {
    js.Dynamic
      .literal(
        "customCurrentNode" -> customCurrentNode,
        "customRoot" -> customRoot,
        "customClicked" -> customClicked,
        "nodeName" -> nodeName,
        "nodeDescription" -> nodeDescription,
        "nodeValue" -> nodeValue
      )
      .asInstanceOf[CustomNodeInfoProps]
  }
}

object SunburstGraph {
  private val sunburstCSS = com.sludg.VueD3Assets

  import com.sludg.vuetify.VuetifyComponents._

  def sunburstGraphRenderrer(registrationName: String) =
    namedTag[SunburstGraphProps, EventBindings, CustomScopedSlots]("SunburstGraph")

  val sunburst = namedTag[VueD3Props, EventBindings, ScopedSlots]("sunburst")
  val highlightOnHover =
    namedTag[SunburstChildrenProps, EventBindings, ScopedSlots]("highlightOnHover")
  val nodeInfoDisplayer =
    namedTag[NodeInfoDisplayerProps, EventBindings, ScopedSlots]("nodeInfoDisplayer")
  val customNodeInfoDisplayer =
    CustomNodeInfoDisplayer.CustomNodeInfoDisplayerRenderer("CustomNodeInfoDisplayer")
  val zoomOnClick = namedTag[SunburstChildrenProps, EventBindings, ScopedSlots]("zoomOnClick")
  val breadcrumbTrail =
    namedTag[BreadCrumbTrailProps, EventBindings, ScopedSlots]("breadcrumbTrail")

  def sunburstGraphComponent()
      : VueComponent[_ <: SunburstGraphProps, _ <: Slots, _ <: CustomScopedSlots] = {
    VueComponent.builder
      .withScopedSlots[CustomScopedSlots]
      .withData(new SunburstGraphData())
      .withPropsAs[SunburstGraphProps]
      .withComputed(SunburstGraphComputed())
      .withMethods(new SunburstGraphMethods)
      .build(
        components = js.Dynamic.literal(
          "Sunburst" -> VueD3,
          "HighlightOnHover" -> HighlightOnHover,
          "NodeInfoDisplayer" -> NodeInfoDisplayer,
          "CustomNodeInfoDisplayer" -> CustomNodeInfoDisplayer.CustomNodeInfoDisplayerComponent(),
          "ZoomOnClick" -> ZoomOnClick,
          "BreadcrumbTrail" -> BreadcrumbTrail
        ),
        templateOrRender = Right((element, createElement) => {
          val graphData = element.asInstanceOf[SunburstGraphData]
          val graphProps = element.asInstanceOf[SunburstGraphProps]
          val graphMethods = element.asInstanceOf[SunburstGraphMethods]

          vContainer(
            element.sunburstData match {
              case x if x.children.nonEmpty =>
                sunburst(
                  RenderOptions[VueD3Props, EventBindings, ScopedSlots](
                    props = Some(
                      VueD3Props(
                        data = Some(x),
                        inAnimationDuration = Some(500)
                      )
                    ),
                    style = Some(
                      js.Dynamic.literal(
                        "height" -> "450px"
                        //"display" -> "block"
                      )
                    ),
                    scopedSlots = Some(new CustomScopedSlots {
                      override val default: UndefOr[js.Function1[SunburstChildrenProps, VNode]] =
                        js.defined(n => {
                          createDefaultSlotComponents(createElement, n)
                        })
                      override val top: js.UndefOr[js.Function1[SunburstChildrenProps, VNode]] =
                        js.defined((n) => {
                          createCustomNodeInfoDisplayer(createElement, n, graphProps, graphMethods)
                        })
                      override val legend: js.UndefOr[js.Function1[SunburstChildrenProps, VNode]] =
                        js.defined(n => {
                          createBreadCrumbTrail(createElement, n)
                        })
                    })
                  )
                )
              case _ =>
                vSubheader(
                  "There is no grouping data to display with this combination of filters and groupings",
                  RenderOptions[VSubheaderProps, EventBindings, ScopedSlots](
                    style = Some(
                      js.Dynamic.literal(
                        "text-align" -> "center",
                        "margin" -> "0 auto"
                      )
                    )
                  )
                )
            },
            RenderOptions[VGridProps, EventBindings, ScopedSlots](
              style = Some(
                js.Dynamic.literal(
                  "max-width" -> "100%"
                )
              )
            )
          ).render(createElement)
        })
      )
  }

  private def createCustomNodeInfoDisplayer(
      createElement: CreateElement,
      n: SunburstChildrenProps,
      componentProps: SunburstGraphProps,
      componentMethods: SunburstGraphMethods
  ) = {
    val mousedOver = n.nodes.get.mouseOver
    var defaultProps: Option[CustomNodeInfoProps] = None
    if (mousedOver != null) {
      val currentValue = n.nodes.get.mouseOver.get.value.toString
      defaultProps = Some(
        CustomNodeInfoProps(
          nodeName = n.nodes.get.mouseOver.get.data.get.name,
          customCurrentNode = n.nodes.get.mouseOver,
          customRoot = n.nodes.get.root,
          nodeValue = componentMethods
            .convertValueToTime(currentValue, componentProps.fullProjectionData),
          nodeDescription = ChartFormatters.formatTitle(componentProps.fullProjectionData)
        )
      )
    } else {
      defaultProps = Some(
        CustomNodeInfoProps(
          nodeName = "",
          customCurrentNode = n.nodes.get.mouseOver,
          customRoot = n.nodes.get.root,
          nodeValue = "",
          nodeDescription = ""
        )
      )
    }
    div(
      customNodeInfoDisplayer(
        RenderOptions[CustomNodeInfoProps, EventBindings, ScopedSlots](
          props = defaultProps,
          `class` = List("title", "text-xs-center").map(Left.apply),
          style = Some(
            js.Dynamic.literal(
              "position" -> "absolute",
              "pointer-events" -> "none"
            )
          )
        )
      )
    ).render(createElement)
  }

  private def createDefaultSlotComponents(
      createElement: CreateElement,
      n: SunburstChildrenProps
  ) = {
    div(
      highlightOnHover(
        RenderOptions[SunburstChildrenProps, EventBindings, ScopedSlots](
          props = Some(
            SunburstChildrenProps(
              nodes = n.nodes,
              actions = n.actions
            )
          )
        )
      ),
      zoomOnClick(
        RenderOptions[SunburstChildrenProps, EventBindings, ScopedSlots](
          props = Some(
            SunburstChildrenProps(
              nodes = n.nodes,
              actions = n.actions
            )
          )
        )
      )
    ).render(createElement)
  }

  private def createBreadCrumbTrail(createElement: CreateElement, n: SunburstChildrenProps) = {
    div(
      breadcrumbTrail(
        RenderOptions[BreadCrumbTrailProps, EventBindings, ScopedSlots](
          props = Some(
            BreadCrumbTrailProps(
              current = n.nodes.get.mouseOver,
              root = n.nodes.get.root,
              from = n.nodes.get.clicked,
              colorGetter = n.colorGetter.get,
              width = 750, //TODO rethink something better than hardcoded values for width etc
              order = 0,
              itemWidth = 130,
              spacing = 3
            )
          ),
          slot = Some("legend")
        )
      )
    ).render(createElement)
  }
}

trait CustomScopedSlots extends ScopedSlots {
  val top: js.UndefOr[js.Function1[SunburstChildrenProps, VNode]]
  val default: js.UndefOr[js.Function1[SunburstChildrenProps, VNode]]
  val legend: js.UndefOr[js.Function1[SunburstChildrenProps, VNode]]
}

class SunburstGraphProps(val fullProjectionData: ProjectionChoicesWithData[_]) extends VueProps

class SunburstGraphMethods extends js.Object {

  def convertValueToTime(
      graphTime: js.UndefOr[String],
      fullData: ProjectionChoicesWithData[_]
  ): String = {
    fullData.upperButton match {
      case Some("Total") => graphTime.get.toString
      case _ if (graphTime.get != "") =>
        ChartFormatters.secondsToTime(graphTime.get.toDouble)
      case _ => ""
    }
  }
}

trait SunburstData extends js.Object {
  val name: String
}

trait SunburstBranchData extends SunburstData {
  val children: js.UndefOr[js.Array[SunburstData]]
}

trait SunburstLeafData extends SunburstData {
  val size: js.UndefOr[Float]
}

object SunburstData {
  def leaf(name: String, size: Float): SunburstLeafData =
    js.Dynamic
      .literal(
        name = name,
        size = size
      )
      .asInstanceOf[SunburstLeafData]

  def branch(name: String, children: js.Array[SunburstData]): SunburstBranchData =
    js.Dynamic
      .literal(
        name = name,
        children = children
      )
      .asInstanceOf[SunburstBranchData]

}

class SunburstGraphData extends js.Object {

  var defaultGraphData =
    js.Dynamic.literal(
      name = "Analytics",
      children = js.Array(
        js.Dynamic.literal(
          name = "First",
          children = js.Array(
            js.Dynamic.literal(name = "Between1", size = 2),
            js.Dynamic.literal(name = "Between2", size = 1)
          )
        ),
        js.Dynamic.literal(
          name = "Second",
          children = js.Array(
            js.Dynamic.literal(name = "Bet", size = 3),
            js.Dynamic.literal(name = "Bet2", size = 2)
          )
        )
      )
    )
  var description = "CDR Analytics"
}

trait SunburstGraphComputed extends js.Object {
  def sunburstData: SunburstBranchData
}

object SunburstGraphComputed {

  def convertDataToSunburstData(data: RootProjection[_]): SunburstBranchData = {
    def convert(c: ChildProjection[_]): SunburstData = {

      val name = c.category.present

      if (c.children.isEmpty)
        SunburstData.leaf(
          name = name,
          size = c.value.toString().toFloat
        )
      else
        SunburstData.branch(
          name = name,
          c.children.map(convert).toJSArray
        )

    }

    SunburstData.branch(
      name = "Total",
      children = data.children.map(convert).toJSArray
    )
  }

  def apply(): SunburstGraphComputed = {
    js.Dynamic
      .literal(
        "sunburstData" -> ((
            (
                data,
                props
            ) => convertDataToSunburstData(props.fullProjectionData.projectionData)
        ): js.ThisFunction1[SunburstGraphData, SunburstGraphProps, SunburstBranchData])
      )
      .asInstanceOf[SunburstGraphComputed]
  }
}

trait VueD3ScopedSlot extends js.Object {
  val nodes: js.UndefOr[Nodes] = js.undefined
  val actions: js.UndefOr[Actions] = js.undefined
}
