package com.sludg.client.components.scheduling

import java.io._
import java.nio.charset.{Charset, StandardCharsets}

import com.sludg.auth0.SludgToken
import com.sludg.client.components.scheduling.EmailAdder.EnterEmailProps
import com.sludg.client.components.scheduling.ScheduleTable.TableItems
import com.sludg.client.components.scheduling.helpers.CronConverter
import com.sludg.client.components.scheduling.period.CreateScheduleSelector.{
  CreateScheduleEvents,
  CreateScheduleProps
}
import com.sludg.client.components.scheduling.period.TimeframeSelector.{
  TimeframeEvents,
  TimeframeProps
}
import com.sludg.client.components.scheduling.period.{CreateScheduleSelector, TimeframeSelector}
import com.sludg.helpers.LoadingFuture
import com.sludg.model.Models.AnalyticsConfig
import com.sludg.scalajs.DynamicHelper
import com.sludg.services.ApiCalls
import com.sludg.util.models.ReportModels
import com.sludg.vue.RenderHelpers._
import com.sludg.vue._
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components._
import cron4s.Cron
import cron4s.expr.CronExpr
import monix.execution.Scheduler
import org.log4s.getLogger
import org.scalajs.dom.Event
import org.scalajs.dom
import org.scalajs.dom.raw.{BlobPropertyBag, URL}
import org.scalajs.dom.{Blob, Event}

import scala.concurrent.{ExecutionContext, Future}
import scala.scalajs.js
import scala.scalajs.js.JSConverters._
import scala.scalajs.js.{ThisFunction0, URIUtils, UndefOr}

object ScheduleTable {

  private[this] val logger = getLogger

  type ScheduleTableComponent = VueComponent[_ <: ScheduleTableProps, _ <: Slots, _ <: ScopedSlots]
    with ScheduleTableData
    with ScheduleTableMethods
    with js.Object
    with ScheduleTableProps

  def scheduleTableRenderer(registrationName: String) =
    namedTag[ScheduleTableProps, ScheduleTableEvents, ScopedSlots]("ScheduleTable")

  val createSchedule = CreateScheduleSelector.createScheduleRenderer("CreateSchedule")

  val timeFrame = TimeframeSelector.timeframeRenderer("Timeframe")
  val enterEmail = EmailAdder.enterEmailRenderer("EnterEmail")

  def deletionDialog(component: ScheduleTableComponent, apiCalls: ApiCalls, loader: Vue)(implicit
      sludgToken: SludgToken
  ) = {
    vDialog(
      RenderOptions(
        style = Some(
          js.Dynamic.literal(
            "text-align" -> "center",
            "box-shadow" -> "0px"
          )
        ),
        props = Some(
          VDialogProps(
            value = Some(component.vDialogVisible),
            width = Some(Right(200)),
            scrollable = Some(false),
            //                  persistent = Some(true),
            `max-width` = Some(Right(550))
          )
        ),
        on = Some(
          EventBindings(
            input = js.defined(e => {
              component.vDialogVisible = e.asInstanceOf[Boolean]
            })
          )
        )
      ),
      vCard(
        vCardTitle(
          RenderOptions(
            `class` = List(Left("headline"))
          ),
          "Delete Schedule"
        ),
        vDivider,
        vCardText(
          vList(
            VListProps(
              `two-line` = Some(true)
            ),
            vListTile(
              vListTileContent(
                vListTileTitle(
                  component.scheduledReportName
                ),
                vListTileSubTitle(
                  "Report Name"
                )
              )
            ),
            vListTile(
              vListTileContent(
                vListTileTitle(
                  component.scheduledReportSchedule
                ),
                vListTileSubTitle(
                  "Schedule"
                )
              )
            ),
            vListTile(
              vListTileContent(
                vListTileTitle(
                  component.emailList.take(5).mkString(", ")
                ),
                vListTileSubTitle(
                  "Recipient(s)"
                )
              )
            )
          )
        ),
        vCardActions(
          vSpacer,
          vButton(
            EventBindings(
              click = js.defined(e => {
                component.vDialogVisible = false
              })
            ),
            "Cancel"
          ),
          vButton(
            RenderOptions(
              props = Some(
                VButtonProps(
                  color = Some("red")
                )
              ),
              on = Some(
                EventBindings(
                  click = js.defined(e => {
                    component.vDialogVisible = false
                    component.deleteSchedule(apiCalls, component.scheduleId, component, loader)
                  })
                )
              )
            ),
            "Delete"
          )
        )
      )
    )
  }
  def loadData(
      loader: Vue,
      apiCalls: ApiCalls,
      token: SludgToken,
      c: ScheduleTableComponent,
      newTenant: Int
  ) = {
    import monix.execution.Scheduler.Implicits.global

    LoadingFuture.withLoading(
      loader,
      for {
        _ <- updateCallReports(c, apiCalls, newTenant)(token)
        _ <- getAllTenantSchedules(c, apiCalls, newTenant)(token)
      } yield ()
    )
  }

  class ScheduleTableWatcher() extends js.Object {

    def selectedTenant(newTenant: Int, oldValue: Int): Unit = {
      if (newTenant != oldValue) {
        logger.info("ScheduleTableWatcher: New Tenant Selected! " + newTenant.toString())

        val c = this.asInstanceOf[ScheduleTableComponent]

        /*
        When selecting a new tenant, the "state" is reset to original parameters
        Data in the table is then refreshed using the new tenant id, if found
         */

        c.showEmailMenu = false
        c.thereAreEmailsPresent = true
        c.showTimeFrameMenu = false
        c.pos = 0
        c.scheduleId = "0"
        c.scheduledReportName = "ReportName"
        c.scheduledReportSchedule = "Schedule"

        c.emails = None
        c.cronEx = None
        c.selectedReport = None

        c.schedules = Some(List(TableItems()))
        c.schedulesEditable = List(false)
        c.schedulesEnglish = List("")
        c.schedulesCron = List()
        c.currentCron = js.undefined
        c.savedDate = js.undefined

        c.selectedReportId = 0
        c.reportNames = List("")
        c.reportIds = List("")
        c.reportNameDictionary = Map(0 -> "Temp")

        c.emails = None
        c.emailInput = ""
        c.oldEmailsInCaseYouCancel = "old"
        c.newEmailsYouMightSave = "new"

        c.emailList = List[String]("This@gmail.com")
        c.emailsAboutToBeSentIn = List[String]().toJSArray

        c.schedule = Cron.unsafeParse("0 10 12 ? * *")
        c.vDialogVisible = false
        c.callItemisation = false

        (c.loader, c.apiCalls, c.token) match {
          case (Some(loader), Some(apiCalls), Some(token)) =>
            loadData(loader, apiCalls, token, c, newTenant)
          case _ => logger.debug("Missing values for tenant selection change.")
        }
      }
    }

  }

  def scheduleTableComponent(config: AnalyticsConfig, apiCalls: ApiCalls, loader: Vue)(implicit
      token: SludgToken
  ) = {
    implicit val ec = ExecutionContext.global

    def isRowEditable(
        items: VDataTableItemSlotData[TableItems],
        component: ScheduleTableComponent
    ): Boolean = {
      items.item.position.map(_.toInt).map(component.schedulesEditable).getOrElse(false)
    }

    VueComponent.builder
      .withData(new ScheduleTableData)
      .withMethods(new ScheduleTableMethods())
      .withProps(ScheduleTableProps())
      .build(
        watch = new ScheduleTableWatcher(),
        created = js.defined(c => {
          c.apiCalls = Some(apiCalls)
          c.loader = Some(loader)
          c.token = Some(token)

          println("selected " + c.selectedTenant)

          c.selectedTenant.toOption match {
            case Some(t) => loadData(loader, apiCalls, token, c, t)
            case None => {
              logger.debug("")
            }
          }

        }),
        components = js.Dynamic.literal(
          "Timeframe" -> TimeframeSelector.timeframeComponent(),
          "EnterEmail" -> EmailAdder.enterEmailComponent(),
          "CreateSchedule" -> CreateScheduleSelector
            .createScheduleComponent(config, apiCalls, loader)
        ),
        templateOrRender = Right((component, renderer) => {
          div(
            vDataTable(
              RenderOptions[
                VDataTableProps[TableItems, VDataTableHeader],
                EventBindings,
                VDataTableScopedSlots[TableItems, VDataTableHeader]
              ](
                props = Some(
                  VDataTableProps[TableItems, VDataTableHeader](
                    headers = Some(
                      List(
                        new VDataTableHeader(sortable = false, text = "Reports"),
                        new VDataTableHeader(sortable = false, text = "Schedule"),
                        new VDataTableHeader(sortable = false, text = "Recipients"),
                        new VDataTableHeader(sortable = false, text = "Include Call Itemisation"),
                        new VDataTableHeader(sortable = false, text = ""),
                        new VDataTableHeader(sortable = false, text = "")
                      )
                    ),
                    items = component.schedules,
                    `hide-actions` = Some(true)
                  )
                ),
                scopedSlots = Some(
                  new VDataTableScopedSlots[TableItems, VDataTableHeader](
                    items = js.defined(items => {
                      val scheduleInEnglish: String = items.item.schedule.toString
                      val emails = items.item.recipients.toString
                      val position = items.item.position.toString

                      if (!isRowEditable(items, component)) {
                        tr(
                          td(
                            items.item.reports.toString
                          ),
                          td(
                            p(
                              RenderOptions(
                                style = Some(
                                  js.Dynamic.literal(
                                    "margin-top" -> "15px"
                                  )
                                )
                              ),
                              component.schedulesEnglish(position.toInt)
                            )
                          ),
                          td(
                            items.item.recipients.toString
                          ),
                          td(
                            if (items.item.calls.toString == "true") {
                              "Yes"
                            } else {
                              "No"
                            }
                          ),
                          td(
                            vButton(
                              vIcon("edit"),
                              RenderOptions(
                                on = Some(
                                  component.editHandler(
                                    items,
                                    apiCalls,
                                    items.item.reports.toString,
                                    items.item.position.toString,
                                    component
                                  )
                                ),
                                props = Some(
                                  VButtonProps(
                                    icon = Some(true),
                                    small = Some(true),
                                    right = Some(true)
                                  )
                                )
                              )
                            )
                          ),
                          td(
                            vButton(
                              vIcon("delete"),
                              RenderOptions(
                                on = Some(
                                  EventBindings(
                                    click = js.defined(e => {
                                      component.scheduledReportSchedule = scheduleInEnglish
                                      component.scheduledReportName = items.item.reports.toString
                                      component.scheduleId = items.item.scheduleId.toString
                                      component.vDialogVisible = true
                                    })
                                  )
                                ),
                                props = Some(VButtonProps(icon = Some(true), small = Some(true)))
                              )
                            )
                          )
                        ).render(renderer)
                      } else {
                        tr(
                          td(
                            //vAutocomplete(
                            //  RenderOptions(
                            //    props = Some(VAutocompleteProps(
                            //      items = Some(component.reportNames),
                            //      itemValue = Some(Right(((s: (String)) => {
                            //        "0"
                            //      }))
                            //    ))),
                            //    on = Some(component.reportSelected(component))
                            //  )
                            //)

                            /*
                            Removed until you can update reports
                            vAutocomplete(
                              vLayout(
                              )
                            )(
                              RenderOptions(
                                props = Some(
                                  js.Dynamic.literal(
                                    "items" -> component.reportNameDictionary.map(_.swap).toJSArray,
                                    "auto-complete" -> true,
                                    "placeholder" -> "Select a report",
                                    "item-text" -> (
                                      (s: (String, Int)) => {
                                        s._1
                                      }),
                                    "item-value" -> ((s: (String, Int)) => {
                                      s._2
                                    })
                                  ).asInstanceOf[VAutocompleteProps]
                                ),
                                on = Some(component.setSelectedReport(component))
                              )
                            )
                             */
                            items.item.reports.toString
                          ),
                          td(
                            vLayout(
                              vFlex(
                                vMenu(
                                  VMenuProps(
                                    value = Some(component.showTimeFrameMenu),
                                    closeOnClick = Some(true),
                                    closeOnContentClick = Some(false),
                                    `full-width` = Some(true)
                                  ),
                                  vButton(vIcon("edit"))(
                                    js.Dynamic
                                      .literal(
                                        "slot" -> "activator",
                                        "props" -> VButtonProps(
                                          icon = Some(true)
                                        ),
                                        "on" -> component.activateTimeFrameMenu(component)
                                      )
                                      .asInstanceOf[RenderOptions[
                                        VButtonProps,
                                        EventBindings,
                                        ScopedSlots
                                      ]]
                                  ),
                                  vCard(
                                    timeFrame(
                                      RenderOptions(
                                        props = Some(
                                          TimeframeProps(
                                            savedCron = component.currentCron,
                                            selectedTenant = component.selectedTenant
                                          )
                                        ),
                                        on = Some(
                                          js.Dynamic
                                            .literal(
                                              "scheduleEvent" -> ((e => {
                                                val schedule = e.toString
                                                val cronExr = Cron.unsafeParse(schedule)

                                                component.currentCron =
                                                  component.schedulesCron(position.toInt)
                                                component.schedule =
                                                  if (
                                                    component.showEmailMenu || (!component.showEmailMenu && !component.showTimeFrameMenu)
                                                  )
                                                    component.currentCron.get
                                                  else cronExr
                                                component.pos = position.toInt
                                              }): js.Function1[Event, Unit])
                                            )
                                            .asInstanceOf[TimeframeEvents]
                                        )
                                      )
                                    ),
                                    vCard(
                                      vLayout(
                                        RenderOptions(
                                          style = Some(
                                            js.Dynamic.literal(
                                              "text-align" -> "center"
                                            )
                                          )
                                        ),
                                        vButton(
                                          vIcon("save"),
                                          RenderOptions(
                                            props = Some(
                                              VButtonProps(
                                                flat = Some(true),
                                                left = Some(true),
                                                block = Some(true)
                                              )
                                            ),
                                            on = Some(
                                              component
                                                .updateTheEnglishAndCloseMenu(component, apiCalls)
                                            )
                                          )
                                        ),
                                        vButton(
                                          vIcon("cancel"),
                                          RenderOptions(
                                            props = Some(
                                              VButtonProps(
                                                flat = Some(true),
                                                left = Some(true),
                                                block = Some(true)
                                              )
                                            ),
                                            on = Some(
                                              component.cancelAndCloseMenu(component, apiCalls)
                                            )
                                          )
                                        )
                                      )
                                    )
                                  )
                                )
                              ),
                              vFlex(
                                p(
                                  RenderOptions(
                                    style = Some(
                                      js.Dynamic.literal(
                                        "margin-bottom" -> "15px",
                                        "margin-top" -> "15px"
                                      )
                                    )
                                  ),
                                  component.schedulesEnglish(position.toInt)
                                )
                              )
                            )
                          ),
                          td(
                            vLayout(
                              vFlex(
                                vMenu(
                                  VMenuProps(
                                    value = Some(component.showEmailMenu),
                                    closeOnContentClick = Some(false),
                                    closeOnClick = Some(false),
                                    `full-width` = Some(true)
                                  ),
                                  vButton(
                                    vIcon("edit"),
                                    RenderOptions(
                                      props = Some(
                                        VButtonProps(
                                          left = Some(true),
                                          icon = Some(true)
                                        )
                                      ),
                                      slot = Some("activator"),
                                      on = Some(
                                        EventBindings(
                                          click = js.defined(e => {
                                            component.showEmailMenu = true
                                            if (
                                              component.emailsAboutToBeSentIn
                                                .filter(_.nonEmpty)
                                                .length == 0
                                            ) {
                                              component.thereAreEmailsPresent = false
                                            } else {
                                              component.thereAreEmailsPresent = true
                                            }
                                          })
                                        )
                                      )
                                    )
                                  ),
                                  vCard(
                                    enterEmail(
                                      RenderOptions(
                                        on = Some(
                                          js.Dynamic
                                            .literal(
                                              "emailListEvent" -> ((e => {
                                                e.toString.trim.size match {
                                                  case 0 =>
                                                    component.emailsAboutToBeSentIn =
                                                      List("").toJSArray;
                                                    component.thereAreEmailsPresent = false
                                                  case _ =>
                                                    logger.debug(
                                                      "-----" + component.emailsAboutToBeSentIn.toString
                                                    )
                                                    component.thereAreEmailsPresent = true
                                                    component.emails = Some(component.emailList)
                                                    component.emailList =
                                                      e.toString.split(",").toList
                                                    logger.debug(
                                                      "Emails received: " + component.emailList
                                                        .toString()
                                                    )
                                                }
                                              }): js.Function1[Event, Unit])
                                            )
                                            .asInstanceOf[EmailAdder.EnterEmailEvents]
                                        ),
                                        props = Some(
                                          EnterEmailProps(
                                            selectedTenant = component.selectedTenant,
                                            emails =
                                              Some(component.emailsAboutToBeSentIn).orUndefined
                                          )
                                        )
                                      )
                                    ),
                                    vFlex(
                                      vButton(
                                        "Confirm",
                                        RenderOptions(
                                          props = Some(
                                            VButtonProps(disabled =
                                              Some(!component.thereAreEmailsPresent)
                                            )
                                          ),
                                          on = Some(component.confirmEmailMenu(component, position))
                                        )
                                      )
                                    )
                                  )
                                )
                              ),
                              vFlex(
                                p(
                                  RenderOptions(
                                    style = Some(
                                      js.Dynamic.literal(
                                        "margin-bottom" -> "15px",
                                        "margin-top" -> "15px"
                                      )
                                    )
                                  ),
                                  emails
                                )
                              )
                            )
                          ),
                          td(
                            vCheckBox(
                              RenderOptions(
                                on = Some(
                                  EventBindings(
                                    change = js.defined(e => {
                                      if (component.callItemisation) {
                                        component.callItemisation = false
                                      } else {
                                        component.callItemisation = true
                                      }
                                    })
                                  )
                                ),
                                props = Some(
                                  VCheckBoxProps(
                                    `input-value` = Some(items.item.calls.toString.toBoolean)
                                  )
                                ),
                                style = Some(
                                  js.Dynamic.literal(
                                    "padding-left" -> "25px",
                                    "padding-top" -> "25px",
                                    "font-size" -> "16px"
                                  )
                                )
                              )
                            )
                          ),
                          td(
                            vButton(
                              vIcon("save"),
                              RenderOptions(
                                on = Some(
                                  component.saveHandler(
                                    apiCalls,
                                    items.item.scheduleId.toString,
                                    items.item.position.toString,
                                    component,
                                    loader
                                  )
                                ),
                                props = Some(
                                  VButtonProps(
                                    icon = Some(true),
                                    small = Some(true),
                                    right = Some(true)
                                  )
                                )
                              )
                            )
                          ),
                          td(
                            vButton(
                              vIcon("cancel"),
                              RenderOptions(
                                on = Some(
                                  component.cancelHandler(items.item.position.toString, component)
                                ),
                                props = Some(
                                  VButtonProps(
                                    icon = Some(true),
                                    small = Some(true),
                                    right = Some(true)
                                  )
                                )
                              )
                            )
                          )
                        ).render(renderer)
                      }
                    })
                  )
                )
              )
            ),
            createSchedule(
              RenderOptions(
                props = Some(CreateScheduleProps(component.selectedTenant.get)),
                on = Some(
                  js.Dynamic
                    .literal(
                      "schedule" -> ((e => {
                        component.$emit("schedule", e);
                        val schedule = e.toString
                        component.cronEx = Some(Cron.unsafeParse(schedule))
                      }): js.Function1[Event, Unit]),
                      "emails" -> ((e => {
                        component.$emit("emails", e);
                        val emailssss = e.toString.split(",").toList
                        component.emails = Some(emailssss);
                      }): js.Function1[Event, Unit]),
                      "report" -> ((e => {
                        component.$emit("report", e);
                        component.selectedReport = Some(e.toString.toInt)
                      }): js.Function1[Event, Unit]),
                      "save" -> ((e => {
                        component.refreshData(component, apiCalls, loader)

                      }): js.Function1[Event, Unit]),
                      "callItem" -> ((e => {
                        component.callItemisation = e.asInstanceOf[Boolean]

                      }): js.Function1[Event, Unit])
                    )
                    .asInstanceOf[CreateScheduleEvents]
                )
              )
            ),
            deletionDialog(component, apiCalls, loader)
          ).render(renderer)
        })
      )
  }

  trait TableItems extends js.Object {
    val schedule: js.UndefOr[String] = js.undefined
    val scheduleId: js.UndefOr[Int] = js.undefined
    val reports: js.UndefOr[String] = js.undefined
    var calls: js.UndefOr[Boolean] = js.undefined
    var recipients: js.UndefOr[String] = js.undefined
    val position: js.UndefOr[String] = js.undefined
  }

  object TableItems {

    import scala.scalajs.js.JSConverters._

    def apply(
        schedule: Option[String] = None,
        scheduleId: Option[Int] = None,
        reports: Option[String] = None,
        calls: Option[Boolean] = None,
        recipients: Option[String] = None,
        position: Option[String] = None
    ): TableItems = {

      DynamicHelper.buildViaDynamic(
        "schedule" -> schedule.map(js.Any.fromString).orUndefined,
        "scheduleId" -> scheduleId.map(js.Any.fromInt).orUndefined,
        "reports" -> reports.map(js.Any.fromString).orUndefined,
        "calls" -> calls.map(js.Any.fromBoolean).orUndefined,
        "recipients" -> recipients.map(js.Any.fromString).orUndefined,
        "position" -> position.map(js.Any.fromString).orUndefined
      )
    }
  }

  trait ScheduleTableEvents extends EventBindings {}

  object ScheduleTableEvents {
    def apply() = {
      "".asInstanceOf[ScheduleTableEvents]
    }
  }

  trait ScheduleTableComputed extends js.Object {
    def schedule: CronExpr
  }

  object ScheduleTableComputed {
    def apply(): ScheduleTableComputed =
      js.Dynamic
        .literal(
          "schedule" -> ((_.schedule): ThisFunction0[ScheduleTableData, CronExpr])
        )
        .asInstanceOf[ScheduleTableComputed]
  }

  class ScheduleTableMethods extends js.Object {

    def refreshData(component: ScheduleTableComponent, apiCalls: ApiCalls, loader: Vue)(implicit
        sludgToken: SludgToken
    ): Unit = {
      val repo = ReportModels.ReportSchedule(
        0,
        component.selectedReport.get,
        component.selectedTenant.get,
        component.cronEx.get,
        component.emails.get,
        component.callItemisation
      )

      import monix.execution.Scheduler.Implicits.global
      LoadingFuture.withLoading(
        loader,
        apiCalls
          .createTenantSchedule(component.selectedTenant.get, repo)(
            token = sludgToken,
            scheduler = implicitly[Scheduler]
          )
          .map(result => {
            result match {
              case Left(x) => logger.debug("Oh no" + x)
              case Right(x) => {
                logger.debug("Worked?" + x)

                (component.selectedTenant.toOption) match {
                  case Some(t) => {
                    LoadingFuture.withLoading(
                      loader,
                      for {
                        _ <- updateCallReports(component, apiCalls, t)
                        _ <- getAllTenantSchedules(component, apiCalls, t)
                      } yield ()
                    )
                  }
                  case None => logger.debug("No tenant selected.")
                }

              }
            }
            logger.debug("Schedule created")
          })
      )

    }

    def activateTimeFrameMenu(component: ScheduleTableComponent): EventBindings = {
      EventBindings(
        click = js.defined(e => {
          component.showTimeFrameMenu = true
        })
      )
    }

    def updateTheEnglishAndCloseMenu(
        component: ScheduleTableComponent,
        apiCalls: ApiCalls
    ): EventBindings = {
      EventBindings(
        click = js.defined(e => {
          logger.debug("Updating english translation" + e.toString)
          component.showTimeFrameMenu = false
          component.updateEnglish(component.pos, component)
        })
      )
    }

    def cancelAndCloseMenu(component: ScheduleTableComponent, apiCalls: ApiCalls): EventBindings = {
      EventBindings(
        click = js.defined(e => {
          logger.debug("Closing menu" + e.toString)
          component.showTimeFrameMenu = false
        })
      )
    }

    def setSelectedReport(component: ScheduleTableComponent): EventBindings = {
      EventBindings(
        change = js.defined(e => {
          logger.debug("---___---___---___---___" + e.toString)
          component.selectedReportId = e.toString.toInt
        })
      )
    }

    def getReports(apiCalls: ApiCalls, component: ScheduleTableComponent)(implicit
        sludgToken: SludgToken
    ): Future[Unit] = {
      import monix.execution.Scheduler.Implicits.global

      apiCalls
        .getAllCallReports(component.selectedTenant.get)
        .map({
          case Right(result) =>
            component.reportNameDictionary = result.map(a => (a.id, a.name)).toMap
          case Left(result) => logger.debug("oh no" + result)
        })
    }

    def updateSchedule(
        apiCalls: ApiCalls,
        scheduleId: Int,
        reportSchedule: ReportModels.ReportSchedule,
        component: ScheduleTableComponent
    )(implicit sludgToken: SludgToken) = {
      import monix.execution.Scheduler.Implicits.global

      apiCalls
        .updateTenantSchedule(component.selectedTenant.get, scheduleId, reportSchedule)
        .map({
          case Right(result) => logger.debug("It worked" + result)
          case Left(result) => logger.debug("oh no" + result)
        })
    }

    def deleteSchedule(
        apiCalls: ApiCalls,
        scheduleId: String,
        component: ScheduleTableComponent,
        loadingBus: Vue
    )(implicit sludgToken: SludgToken) = {
      import monix.execution.Scheduler.Implicits.global

      logger.debug("Event--[deleteSchedule]--Id: " + scheduleId)

      LoadingFuture.withLoading(
        loadingBus,
        for {
          _ <-
            apiCalls
              .deleteTenantSchedule(component.selectedTenant.get, scheduleId.toInt)(
                token = sludgToken,
                scheduler = implicitly[Scheduler]
              )
              .map({
                case Right(result) =>
                  component.schedules = component.schedules match {
                    case Some(x) =>
                      logger.debug("Schedule id" + scheduleId)
                      logger.debug("Schedule id" + x.map(a => a.scheduleId.get).toString())

                      logger.debug("It has been deleted!!!! " + x.toString());
                      logger.debug(
                        "It has beeas!2@! " + x
                          .filterNot(_.scheduleId.get.toString == scheduleId.toString)
                      )
                      Some(x.filterNot(_.scheduleId.get.toString == scheduleId.toString))
                    case None => None
                  }
                case Left(result) => logger.debug("oh no" + result)
              })
        } yield ()
      )

    }

    def updateEnglish(pos: Int, component: ScheduleTableComponent): Unit = {
      val temp = component.schedulesEnglish.splitAt(pos)
      //List wont update unless it is entirely replaced!???
      component.schedulesEnglish =
        (temp._1 ::: List(CronConverter.cronToEnglish(component.schedule)) ::: temp._2.drop(1))
    }

    def confirmEmailMenu(component: ScheduleTableComponent, position: String): EventBindings = {
      EventBindings(
        click = js.defined(e => {
          component.oldEmailsInCaseYouCancel =
            component.schedules.getOrElse(List[TableItems]())(position.toInt).recipients.get
          component.schedules.getOrElse(List[TableItems]())(position.toInt).recipients =
            component.emailList.mkString(",")

          component.showEmailMenu = false
        })
      )
    }

    def edit(
        apiCalls: ApiCalls,
        scheduleId: String,
        pos: String,
        component: ScheduleTableComponent,
        loadingBus: Vue
    )(implicit sludgToken: SludgToken): EventBindings = {
      implicit val ec = ExecutionContext.global
      EventBindings(
        click = js.defined(e => {

          (component.selectedTenant.toOption) match {
            case Some(t) => {
              LoadingFuture.withLoading(
                loadingBus,
                for {
                  _ <- updateSchedule(
                    apiCalls,
                    scheduleId.toInt,
                    ReportModels.ReportSchedule(
                      scheduleId.toInt,
                      component.selectedReportId,
                      component.selectedTenant.get,
                      component.schedule,
                      component.emailList,
                      component.callItemisation
                    ),
                    component
                  )
                  _ <- updateCallReports(component, apiCalls, t)
                  _ <- getAllTenantSchedules(component, apiCalls, t)
                } yield ()
              )
            }
            case None => logger.debug("No tenant selected.")
          }

        })
      )
    }

    def saveHandler(
        apiCalls: ApiCalls,
        scheduleId: String,
        pos: String,
        component: ScheduleTableComponent,
        loadingBus: Vue
    )(implicit sludgToken: SludgToken): EventBindings = {
      implicit val ec = ExecutionContext.global

      EventBindings(
        click = js.defined(e => {

          (component.schedules, component.selectedTenant.toOption) match {
            case (Some(x), Some(y)) =>
              LoadingFuture.withLoading(
                loadingBus,
                for {
                  _ <- LoadingFuture.withLoading(
                    loadingBus,
                    updateSchedule(
                      apiCalls,
                      scheduleId.toInt,
                      ReportModels.ReportSchedule(
                        scheduleId.toInt,
                        component.selectedReportId,
                        y,
                        component.schedule,
                        x(pos.toInt).recipients.get.split(",").toList,
                        component.callItemisation
                      ),
                      component
                    )
                  )
                  _ <- updateCallReports(component, apiCalls, y)
                  _ <- getAllTenantSchedules(component, apiCalls, y)
                } yield ()
              )

            case _ => logger.debug("oh no")
          }

        })
      )
    }

    def resetRows(pos: String, component: ScheduleTableComponent): Unit = {
      val lowerLimit = pos.toInt
      val upperLimit = component.schedulesEditable.length - (pos.toInt + 1)
      component.schedulesEditable =
        (List.tabulate(lowerLimit)(n => false) ::: List(false) ::: List.tabulate(upperLimit)(n =>
          false
        )).toList
    }

    def editHandler(
        items: VDataTableItemSlotData[TableItems],
        apiCalls: ApiCalls,
        scheduleId: String,
        pos: String,
        component: ScheduleTableComponent
    ): EventBindings = {
      EventBindings(
        click = js.defined(e => {
          component.oldEmailsInCaseYouCancel = component.schedules.get(pos.toInt).recipients.get
          val myEmails = component.schedules.get(pos.toInt).recipients.toString.split(",").toJSArray
          component.emailList = myEmails.toList

          /* Updating the call itemisation value */
          component.callItemisation = items.item.calls.getOrElse(false)

          component.schedules.get(pos.toInt).recipients = component.emailList.mkString(",")
          component.emailsAboutToBeSentIn =
            component.schedules.get(pos.toInt).recipients.toString.split(",").toJSArray
          val lowerLimit = pos.toInt
          val upperLimit = component.schedulesEditable.length - (pos.toInt + 1)

          component.schedulesEditable = (List.tabulate(lowerLimit)(n => false) ::: List(
            true
          ) ::: List.tabulate(upperLimit)(n => false)).toList
        })
      )
    }

    def cancelHandler(pos: String, component: ScheduleTableComponent): EventBindings = {
      EventBindings(
        click = js.defined(e => {
          val lowerLimit = pos.toInt
          val upperLimit = component.schedulesEditable.length - (pos.toInt + 1)

          component.schedules.getOrElse(List[TableItems]())(pos.toInt).recipients =
            component.oldEmailsInCaseYouCancel
          component.newEmailsYouMightSave = ""
          component.showEmailMenu = false
          component.schedulesEditable = (List.tabulate(lowerLimit)(n => false) ::: List(
            false
          ) ::: List.tabulate(upperLimit)(n => false)).toList
        })
      )
    }
  }

  def updateCallReports(data: ScheduleTableData, apiCalls: ApiCalls, t: Int)(implicit
      sludgToken: SludgToken
  ): Future[Unit] = {
    import monix.execution.Scheduler.Implicits.global
    apiCalls
      .getAllCallReports(t)
      .map({
        case Right(result) =>
          data.reportNames = result.toList.map(a => a.name)
          data.reportNameDictionary = result.map(a => (a.id, a.name)).toMap
          logger.debug("Getting schedules" + result)

        case Left(result) => logger.debug("oh no" + result)
      })
  }

  def getAllTenantSchedules(component: ScheduleTableData, apiCalls: ApiCalls, t: Int)(implicit
      sludgToken: SludgToken
  ): Future[Unit] = {
    import monix.execution.Scheduler.Implicits.global

    logger.debug("getting all now")

    apiCalls
      .getAllTenantSchedules(t)
      .map({
        case Right(scheduleResult) =>
          component.schedulesEditable = List.tabulate(scheduleResult.length)(n => false)
          component.schedulesEnglish =
            scheduleResult.map(scheduleCron => CronConverter.cronToEnglish(scheduleCron.schedule))
          component.schedulesCron = scheduleResult.map(_.schedule)

          component.schedules = Some(scheduleResult.zipWithIndex.map {
            case (reportSchedule, index) =>
              TableItems(
                Some(component.schedulesEnglish(index)),
                Some(reportSchedule.id),
                Some(
                  component.reportNameDictionary
                    .getOrElse(reportSchedule.reportId, reportSchedule.reportId.toString)
                ),
                Some(reportSchedule.callItemisation),
                Some(reportSchedule.emails.mkString(",")),
                Some(index.toString)
              )
          })

        case Left(result) => logger.debug("oh no" + result)
      })
  }

}

trait ScheduleTableProps extends VueProps {
  var selectedTenant: js.UndefOr[Int] = js.undefined
  var addRow: js.UndefOr[Boolean] = js.undefined
}

object ScheduleTableProps {
  def apply(
      selectedTenant: js.UndefOr[Int] = js.undefined,
      addRow: js.UndefOr[Boolean] = js.undefined
  ): ScheduleTableProps = {
    js.Dynamic
      .literal(
        "selectedTenant" -> selectedTenant,
        "addRow" -> addRow
      )
      .asInstanceOf[ScheduleTableProps]
  }
}

class ScheduleTableData extends js.Object {
  var apiCalls: Option[ApiCalls] = None
  var loader: Option[Vue] = None
  var token: Option[SludgToken] = None

  var showEmailMenu = false
  var thereAreEmailsPresent = true
  var showTimeFrameMenu = false
  var pos: Int = 0
  var scheduleId = "0"
  var scheduledReportName = "ReportName"
  var scheduledReportSchedule = "Schedule"

  /* Create schedule data */
  var emails: Option[List[String]] = None
  var cronEx: Option[CronExpr] = None
  var selectedReport: Option[Int] = None

  /* data table */
  var schedules: Option[List[TableItems]] = Some(List(TableItems()))
  var schedulesEditable: List[Boolean] = List(false)
  var schedulesEnglish: List[String] = List("")
  var schedulesCron: List[CronExpr] = List()
  var currentCron: js.UndefOr[CronExpr] = js.undefined
  var savedDate: js.UndefOr[String] = js.undefined

  var selectedReportId = 0
  var reportNames = List("")
  var reportIds = List("")
  var reportNameDictionary: Map[Int, String] = Map(0 -> "Temp")

  /* emails */
  var emailInput: String = ""
  var oldEmailsInCaseYouCancel: String = "old"
  var newEmailsYouMightSave: String = "new"

  /* seperated due to infinite loops */
  var emailList = List[String]("This@gmail.com")
  var emailsAboutToBeSentIn = List[String]().toJSArray

  /* cronExpression */
  var schedule: CronExpr = Cron.unsafeParse("0 10 12 ? * *")
  var vDialogVisible = false
  var callItemisation = false
}

sealed trait ScheduleCreationStep

object ScheduleCreationStep {

  case object CreateSchedule extends ScheduleCreationStep

  case object SelectReports extends ScheduleCreationStep

  case object EnterEmail extends ScheduleCreationStep

}
