package com.sludg.client.components.scheduling.period

import com.sludg.auth0.SludgToken
import com.sludg.client.components.scheduling.EmailAdder.EnterEmailProps
import com.sludg.client.components.scheduling.ScheduleCreationStep.{
  CreateSchedule,
  EnterEmail,
  SelectReports
}
import com.sludg.client.components.scheduling.helpers.CronConverter.Period
import com.sludg.client.components.scheduling.helpers.CronConverter.Period.Monthly
import com.sludg.client.components.scheduling.period.TimeframeSelector.TimeframeEvents
import com.sludg.client.components.scheduling.{EmailAdder, ScheduleCreationStep}
import com.sludg.client.components.{ReportSelector, ReportSelectorEvents, ReportSelectorProps}
import com.sludg.model.Models.AnalyticsConfig
import com.sludg.services.ApiCalls
import com.sludg.util.models.SilhouetteModels.Tenant
import com.sludg.vue.RenderHelpers._
import com.sludg.vue.{RenderHelpers, _}
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components._
import cron4s.Cron
import cron4s.expr.CronExpr
import org.log4s._
import org.scalajs.dom.Event

import scala.scalajs.js

object CreateScheduleSelector {

  private[period] val logger = getLogger

  type CreateScheduleComponent =
    VueComponent[_ <: CreateScheduleProps, _ <: Slots, _ <: ScopedSlots]
      with CreateScheduleData
      with CreateScheduleMethods
      with js.Object
      with CreateScheduleProps

  def createScheduleRenderer(registrationName: String) =
    namedTag[CreateScheduleProps, CreateScheduleEvents, ScopedSlots](registrationName)

  val timeFrame = TimeframeSelector.timeframeRenderer("Timeframe")

  val reportSelector = ReportSelector.reportSelectorRenderer("ReportSelectorTheSecond")
  val enterEmail = EmailAdder.enterEmailRenderer("EnterEmail")

  def createScheduleComponent(config: AnalyticsConfig, apiCalls: ApiCalls, loadingBus: Vue)(implicit
      sludgToken: SludgToken
  ) = {
    VueComponent.builder
      .withData(new CreateScheduleData)
      .withMethods(new CreateScheduleMethods())
      .withProps(CreateScheduleProps())
      .build(
        watch = new SchedulingPageWatcher(),
        components = js.Dynamic.literal(
          "ReportSelectorTheSecond" -> ReportSelector.reportSelectorComponent(apiCalls, loadingBus),
          "EnterEmail" -> EmailAdder.enterEmailComponent(),
          "Timeframe" -> TimeframeSelector.timeframeComponent()
        ),
        templateOrRender = Right((component, renderer) => {
          div(
            vDivider,
            vFlex(
              vMenu(
                VMenuProps(
                  closeOnClick = Some(true),
                  closeOnContentClick = Some(false),
                  `full-width` = Some(true),
                  value = Some(component.showMenu)
                ),
                vButton(
                  vIcon("add"),
                  RenderOptions(
                    slot = Some("activator"),
                    props = Some(VButtonProps(fab = Some(true))),
                    on = Some(EventBindings(click = js.defined(e => {
                      component.showMenu = true
                    })))
                  )
                ),
                vCard(
                  vStepper(
                    VStepperProps(
                      value = Some(Left(component.mainStepperCurrentStep))
                    ),
                    vStepperHeader(
                      vStepperStep("Create Schedule", VStepperStepProps(step = Some(Left(1)))),
                      vDivider,
                      vStepperStep("Select Report", VStepperStepProps(step = Some(Left(2)))),
                      vDivider,
                      vStepperStep("Enter email", VStepperStepProps(step = Some(Left(3))))
                    ),
                    vStepperItems(
                      vStepperContent(
                        VStepperContentProps(step = Some(Left(1))),
                        component.timeFrameSelector(component)
                      ),
                      vStepperContent(
                        VStepperContentProps(step = Some(Left(2))),
                        component.reportSelectorManagment(
                          config,
                          component,
                          apiCalls,
                          component.selectedTenant.get,
                          Some(sludgToken)
                        )
                      ),
                      vStepperContent(
                        VStepperContentProps(step = Some(Left(3))),
                        component.emailManagment(component)
                      )
                    )
                  )
                ),
                vCard(
                  vLayout(
                    vFlex(vButton("Back", component.backHandler(component))),
                    vFlex(
                      vButton(
                        "Confirm",
                        RenderOptions(
                          props = Some(VButtonProps(disabled = Some(component.buttonDisabled))),
                          on = Some(component.nextHandler(component))
                        )
                      )
                    )
                  ),
                  RenderOptions(
                    style = Some(
                      js.Dynamic.literal(
                        "text-align" -> "center"
                      )
                    )
                  )
                )
              )
            )
          ).render(renderer)
        })
      )
  }

  trait CreateScheduleEvents extends EventBindings {
    def test(e: Event): Unit
  }

  object CreateScheduleEvents {
    def apply() = {
      "".asInstanceOf[CreateScheduleEvents]
    }
  }

  class CreateScheduleMethods extends js.Object {

    def reset(compoent: CreateScheduleComponent): Unit = {
      compoent.buttonDisabled = false

      /* stepper */
      compoent.mainStepperCurrentStep = 1
      compoent.mainState = ScheduleCreationStep.CreateSchedule
      compoent.currentState = Monthly

      compoent.showMenu = false
      compoent.schedule = Cron.unsafeParse("0 10 12 ? * *")
    }

    def timeFrameSelector(component: CreateScheduleComponent): RenderHelpers.NodeRenderer[
      TimeframeSelector.TimeframeProps,
      TimeframeEvents,
      ScopedSlots
    ] = {
      timeFrame(
        RenderOptions(
          on = Some(
            js.Dynamic
              .literal(
                "scheduleEvent" -> ((e => {
                  val schedule = e.toString
                  val cronExr = Cron.unsafeParse(schedule)

                  //Temporary fix to prevent cron expressions being set to an invalid time
                  if (cronExr == Cron.unsafeParse("0 */5 * ? * *"))
                    component.schedule = Cron.unsafeParse("0 */6 * ? * *")
                  else component.schedule = cronExr

                }): js.Function1[Event, Unit])
              )
              .asInstanceOf[TimeframeEvents]
          )
        )
      )
    }

    def emailManagment(component: CreateScheduleComponent): RenderHelpers.NodeRenderer[
      EmailAdder.EnterEmailProps,
      EmailAdder.EnterEmailEvents,
      ScopedSlots
    ] = {
      enterEmail(
        RenderOptions(
          props = Some(
            EnterEmailProps(
              sendEmailsToMeNow = component.sendEmailsToMeNow,
              selectedTenant = component.selectedTenant
            )
          ),
          on = Some(
            js.Dynamic
              .literal(
                "emailListEvent" -> ((e => {
                  val event: List[String] = e.toString.split(",").toList
                  component.emailList = event
                  if (component.emailList.isEmpty || !component.emailList.exists(_.nonEmpty)) {
                    component.buttonDisabled = true
                  } else {
                    component.buttonDisabled = false
                  }
                }): js.Function1[Event, Unit])
              )
              .asInstanceOf[EmailAdder.EnterEmailEvents]
          )
        )
      )
    }

    def warningDialog(
        config: AnalyticsConfig,
        apiCalls: ApiCalls,
        component: CreateScheduleComponent
    ) = {
      vDialog(
        RenderOptions(
          style = Some(
            js.Dynamic.literal(
              "text-align" -> "center",
              "box-shadow" -> "0px"
            )
          ),
          props = Some(
            VDialogProps(
              value = Some(component.vDialogVisible),
              width = Some(Right(200)),
              scrollable = Some(false),
              `max-width` = Some(Right(550))
            )
          ),
          on = Some(
            EventBindings(
              input = js.defined(e => {
                component.vDialogVisible = e.asInstanceOf[Boolean]
              })
            )
          )
        ),
        vCard(
          vCardTitle(
            RenderOptions(
              `class` = List(Left("headline"))
            ),
            "Warning: Too many table columns"
          ),
          vCardText(
            p(
              s"The number of tables columns exceeds the max that can be displayed. (${config.scheduledReport.maxColumns})"
            ),
            p("Excessive columns will be truncated.")
          ),
          vDivider
        )
      )
    }

    def reportSelectorManagment(
        config: AnalyticsConfig,
        component: CreateScheduleComponent,
        apiCalls: ApiCalls,
        tenantId: Int,
        token: Option[SludgToken]
    ) = {
      logger.debug("I made a report object with tenantId " + tenantId)
      vCard(
        VCardProps(
          `max-width` = Some(Left(650)),
          `min-width` = Some(Left(650)),
          `min-height` = Some(Left(650)),
          height = Some(Left(500)),
          width = Some(Left(500))
        ),
        p("Select your reports."),
        reportSelector(
          RenderOptions(
            props = Some(ReportSelectorProps(tenantId, token = token)),
            on = Some(
              ReportSelectorEvents(
                reportSelected = e => {
                  component.selectedReport = e._1.map(_.id)
                  component.buttonDisabled = false

                  if (e._4.length > config.scheduledReport.maxColumns.toInt) {
                    component.vDialogVisible = true
                  }
                }
              )
            )
          )
        ),
        warningDialog(config, apiCalls, component),
        vLayout(
          vFlex(
            p(
              "Include Call itemisation",
              RenderOptions(
                style = Some(
                  js.Dynamic.literal(
                    "padding-top" -> "21px"
                  )
                )
              )
            )
          ),
          vFlex(
            vSwitch(
              RenderOptions(
                props = Some(
                  VSwitchProps(
                    value = Some(component.callItemisation)
                  )
                ),
                style = Some(
                  js.Dynamic.literal(
                    "padding-left" -> "220px",
                    "font-size" -> "16px"
                  )
                ),
                on = Some(
                  EventBindings(
                    change = js.defined(e => {
                      if (component.callItemisation) {
                        component.callItemisation = false
                        component.$emit("callItem", component.callItemisation)
                      } else {
                        component.callItemisation = true
                        component.$emit("callItem", component.callItemisation)
                      }
                    })
                  )
                )
              )
            )
          )
        )
      )
    }

    def nextStep(component: CreateScheduleComponent) = {
      component.mainState match {
        case CreateSchedule => {
          component.$emit("schedule", component.schedule.toString)
          component.mainStepperCurrentStep += 1
          component.mainState = component.mainStates(component.mainStepperCurrentStep - 1)
          logger.debug("-----" + component.selectedReport.toString)
          if (component.selectedReport == None) {
            component.buttonDisabled = true
          }
        }
        case SelectReports => {
          component.showMenu = true
          logger.debug("The current selected report is " + component.selectedReport)
          component.$emit("report", component.selectedReport.getOrElse(0))
          component.mainStepperCurrentStep += 1
          component.mainState = component.mainStates(component.mainStepperCurrentStep - 1)

          if (component.emailList.isEmpty || component.emailList.filter(_.nonEmpty).isEmpty) {
            logger.debug("It is disabled!")
            component.buttonDisabled = true
          }
        }
        case EnterEmail => {
          if (component.emailList.nonEmpty) {
            component.$emit("emails", component.emailList.mkString(","))
            reset(component)
            component.$emit("save", "save")
          } else {
            component.buttonDisabled = true
          }
        }
      }

      logger.debug("Main state change" + component.mainStepperCurrentStep + component.mainState)
    }

    def disableNextButton(component: CreateScheduleComponent): Unit = {
      component.buttonDisabled = true
    }

    def enableNextButton(component: CreateScheduleComponent): Unit = {
      component.buttonDisabled = false
    }

    def previousStep(component: CreateScheduleComponent) = {
      component.buttonDisabled = false

      component.mainState match {
        case CreateSchedule => logger.debug("[previousStep]---Can't go futher back.")
        case _ => {
          component.mainStepperCurrentStep -= 1
          component.mainState = component.mainStates(component.mainStepperCurrentStep - 1)
          logger.debug("---" + component.mainState)
        }
      }

      logger.debug("Main state change" + component.mainStepperCurrentStep)
    }

    def stepperEvent(component: CreateScheduleComponent): EventBindings = {
      logger.debug("Event!-[Main StepperSS]")

      EventBindings(input = js.defined(e => {
        e.toString() match {
          case "M" => component.setScheduleType(Period.Monthly)
          case "D" => component.setScheduleType(Period.Daily)
          case "P" => component.setScheduleType(Period.Periodically)
        }
      }))
    }

    def setScheduleType(scheduleType: Period) = {
      logger.debug("Schedule manager state: " + scheduleType)
      this.asInstanceOf[CreateScheduleComponent].currentState = scheduleType
    }

    def nextHandler(component: CreateScheduleComponent): EventBindings = {
      EventBindings(
        click = js.defined(e => {
          component.nextStep(component)
        })
      )
    }

    def backHandler(component: CreateScheduleComponent): EventBindings = {
      EventBindings(
        click = js.defined(e => {
          logger.debug("The current selected tenant id is still " + component.selectedTenant.get)
          component.previousStep(component)
        })
      )
    }
  }

  trait CreateScheduleProps extends VueProps {
    val selectedTenant: js.UndefOr[Int] = js.undefined
  }

  object CreateScheduleProps {
    def apply(
        selectedTenant: js.UndefOr[Int] = js.undefined
    ): CreateScheduleProps = {
      js.Dynamic
        .literal(
          "selectedTenant" -> selectedTenant
        )
        .asInstanceOf[CreateScheduleProps]

    }
  }

  class SchedulingPageWatcher() extends js.Object {
    def selectedTenant(tid: Int, tid2: Int): Unit = {
      if (tid != tid2) {

        println("CreateScheduleSelector: " + tid)

        //Resetting state to default
        val r = this.asInstanceOf[CreateScheduleComponent]
        r.mainStepperCurrentStep = 1
        r.mainState = ScheduleCreationStep.CreateSchedule
        r.currentState = Monthly

        r.showMenu = false
        r.selectedReport = None
        r.schedule = Cron.unsafeParse("0 10 12 ? * *")
        r.emailList = List[String]()

        r.sendEmailsToMeNow = false
        r.buttonDisabled = true //Button disabled on refresh

        r.vDialogVisible = false
        r.callItemisation = false
      }
    }
  }

  class CreateScheduleData extends js.Object {
    /* stepper */
    var mainStepperCurrentStep: Int = 1
    var mainState: ScheduleCreationStep = ScheduleCreationStep.CreateSchedule
    val mainStates = List(CreateSchedule, SelectReports, EnterEmail)
    var currentState: Period = Monthly

    var showMenu = false
    var selectedReport: Option[Int] = None
    var schedule: CronExpr = Cron.unsafeParse("0 10 12 ? * *")
    var emailList: List[String] = List[String]()

    var sendEmailsToMeNow = false
    var buttonDisabled = false

    var vDialogVisible = false
    var callItemisation = false
  }

}
