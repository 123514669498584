package com.sludg.client.components.scheduling.period

import java.time.{LocalDate, LocalTime, Year, ZoneOffset}

import com.sludg.client.components.scheduling.helpers.CronConverter
import com.sludg.client.components.scheduling.helpers.CronConverter.{
  DailySelection,
  MonthlySelection,
  Period
}
import com.sludg.client.components.scheduling.period.DailyPage.{DailyPageEvents, DailyPageProps}
import com.sludg.client.components.scheduling.period.MonthlyPage.{
  MonthlyPageEvents,
  MonthlyPageProps
}
import com.sludg.client.components.scheduling.period.PeriodicallyPage.{
  PeriodicallyPageEvents,
  PeriodicallyPageProps
}
import com.sludg.vue.RenderHelpers._
import com.sludg.vue.{RenderHelpers, _}
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components._
import cron4s.Cron
import cron4s.expr.CronExpr
import org.log4s.getLogger
import org.scalajs.dom.Event

import scala.scalajs.js
import scala.scalajs.js.JSConverters._

object TimeframeSelector {

  private[period] val logger = getLogger

  type TimeframeComponent = VueComponent[_ <: TimeframeProps, _ <: Slots, _ <: ScopedSlots]
    with TimeframeData
    with TimeframeMethods
    with js.Object
    with TimeframeProps

  def timeframeRenderer(registrationName: String) =
    namedTag[TimeframeProps, TimeframeEvents, ScopedSlots](registrationName)

  val dailyPage = DailyPage.dailyPageRenderer("DailyPage")
  val periodicallyPage = PeriodicallyPage.periodicallyPageRenderer("PeriodicallyPage")
  val monthlyPage = MonthlyPage.monthlyPageRenderer("MonthlyPage")

  def timeframeComponent() = {
    VueComponent.builder
      .withData(new TimeframeData)
      .withMethods(new TimeframeMethods())
      .withProps(TimeframeProps())
      .build(
        components = js.Dynamic.literal(
          "DailyPage" -> DailyPage.dailyPageComponent(),
          "PeriodicallyPage" -> PeriodicallyPage.periodicallyPageComponent(),
          "MonthlyPage" -> MonthlyPage.monthlyPageComponent()
        ),
        templateOrRender = Right((component, renderer) => {
          div(
            vCard(
              VCardProps(color = Some("white")),
              vStepper(
                RenderOptions(
                  props = Some(
                    VStepperProps(
                      value = Some(Right("ThisWorksTechnically")),
                      vertical = Some(true)
                    )
                  ),
                  on = Some(component.stepperEvent(component))
                ),
                vStepperStep(
                  "Monthly",
                  small("Receive reports at monthly/quarterly intervals, or every few months"),
                  VStepperStepProps(step = Some(Right("M")), editable = Some(true))
                ),
                vStepperContent(VStepperContentProps(step = Some(Right("M"))), monthly(component)),
                vStepperStep(
                  "Daily",
                  small("Receive reports on specific days of the week"),
                  VStepperStepProps(step = Some(Right("D")), editable = Some(true))
                ),
                vStepperContent(VStepperContentProps(step = Some(Right("D"))), daily(component)),
                vStepperStep(
                  "Periodically",
                  small("Receive reports at hourly/minutely intervals"),
                  VStepperStepProps(step = Some(Right("P")), editable = Some(true))
                ),
                vStepperContent(
                  VStepperContentProps(step = Some(Right("P"))),
                  periodically(component)
                )
              )
            )
          ).render(renderer)
        })
      )
  }

  def periodically(component: TimeframeComponent) = {
    periodicallyPage(
      RenderOptions(
        props = Some(
          if (
            component.savedCron.isDefined && CronConverter
              .cronToTypes(component.savedCron.get)
              ._1 == Period.Periodically
          ) {
            PeriodicallyPageProps(
              savedCron = component.savedCron,
              selectedTenant = component.selectedTenant
            )
          } else {
            PeriodicallyPageProps(
              savedCron = js.undefined,
              selectedTenant = component.selectedTenant
            )
          }
        ),
        on = Some(
          js.Dynamic
            .literal(
              "updateSchedule" -> ((e => {
                val schedule = e.toString
                val cronExr = Cron.unsafeParse(schedule)

                component.periodicSchedule = cronExr
                component.emitCronExpressionEvent(component, component.periodicSchedule)
              }): js.Function1[Event, Unit])
            )
            .asInstanceOf[PeriodicallyPageEvents]
        )
      )
    )
  }

  def monthly(component: TimeframeComponent) = {
    monthlyPage(
      RenderOptions(
        props = Some(
          if (
            component.savedCron.isDefined && CronConverter
              .cronToTypes(component.savedCron.get)
              ._1 == Period.Monthly
          ) {
            MonthlyPageProps(
              savedCron = component.savedCron,
              selectedTenant = component.selectedTenant
            )
          } else {
            MonthlyPageProps(
              savedCron = js.undefined,
              selectedTenant = component.selectedTenant
            )
          }
        ),
        on = Some(
          js.Dynamic
            .literal(
              "updateSchedule" -> ((e => {
                val schedule = e.toString
                val cronExr = Cron.unsafeParse(schedule)

                component.monthlySchedule = cronExr
                component.emitCronExpressionEvent(component, component.monthlySchedule)
              }): js.Function1[Event, Unit])
            )
            .asInstanceOf[MonthlyPageEvents]
        )
      )
    )
  }

  def daily(
      component: TimeframeComponent
  ): RenderHelpers.NodeRenderer[DailyPage.DailyPageProps, DailyPageEvents, ScopedSlots] = {
    dailyPage(
      RenderOptions(
        props = Some(
          if (
            component.savedCron.isDefined && CronConverter
              .cronToTypes(component.savedCron.get)
              ._1 == Period.Daily
          ) {
            DailyPageProps(
              savedCron = component.savedCron,
              selectedTenant = component.selectedTenant
            )
          } else {
            DailyPageProps(
              savedCron = js.undefined,
              selectedTenant = component.selectedTenant
            )
          }
        ),
        on = Some(
          js.Dynamic
            .literal(
              "updateSchedule" -> ((e => {
                val schedule = e.toString
                val cronExr = Cron.unsafeParse(schedule)

                component.dailySchedule = cronExr
                component.emitCronExpressionEvent(component, component.dailySchedule)
              }): js.Function1[Event, Unit])
            )
            .asInstanceOf[DailyPageEvents]
        )
      )
    )

  }

  trait TimeframeEvents extends EventBindings {
    def test(e: Event): Unit
  }

  object TimeframeEvents {
    def apply() = {
      "".asInstanceOf[TimeframeEvents]
    }
  }

  class TimeframeMethods extends js.Object {

    def emitCronExpressionEvent(component: TimeframeComponent, cronExpr: CronExpr) = {
      logger.debug("CronEx generated: " + cronExpr)
      component.$emit("scheduleEvent", cronExpr.toString)
    }

    def updateEmail(component: TimeframeComponent): Option[EventBindings] = {
      Some(
        EventBindings(
          input = js.defined(e => {
            logger.debug("Event!---[updateEmail]---" + e.toString)
            component.emailInput = e.toString
          })
        )
      )
    }

    def stepperEvent(component: TimeframeComponent): EventBindings = {
      logger.debug("Event!-[Main StepperSS]")

      EventBindings(input = js.defined(e => {
        e.toString() match {
          case "M" =>
            component.setScheduleType(Period.Monthly);
            emitCronExpressionEvent(component, component.monthlySchedule)
          case "D" =>
            component.setScheduleType(Period.Daily);
            emitCronExpressionEvent(component, component.dailySchedule)
          case "P" =>
            component.setScheduleType(Period.Periodically);
            emitCronExpressionEvent(component, component.periodicSchedule)
          case _ =>
            component.setScheduleType(Period.Monthly);
            emitCronExpressionEvent(component, component.monthlySchedule)
        }
      }))
    }

    def setScheduleType(scheduleType: Period) = {
      logger.debug("Schedule manager state: " + scheduleType)
      this.asInstanceOf[TimeframeData].currentState = scheduleType
    }
  }

  trait TimeframeProps extends VueProps {
    val savedCron: js.UndefOr[CronExpr] = js.undefined
    val selectedTenant: js.UndefOr[Int] = js.undefined
  }

  class TimeFrameSelectorWatcher() extends js.Object {
    def selectedTenant(tid: Int, tid2: Int): Unit = {
      if (tid != tid2) {

        println("TimeFrameSelectorWatcher: " + tid)

        //Resetting state to default
        val r = this.asInstanceOf[TimeframeComponent]
        r.emailInput = "example@outlook.com"
        r.schedulesEditable = List(false)
        r.reportNames = List("")
        r.reportNameDictionary = Map(0 -> "Temp")
        r.showMenu = true
        r.currentState = Period.Monthly
        r.selectedReport = None
        r.dateHint = ""
        r.date = LocalDate.MIN
        r.isDateSelected = false
        r.time = LocalTime.NOON
        r.isArrivalTimeSelected = false
        r.arrivalTimeDisplay = ""
        r.monthlySelection = MonthlySelection.SpecificMonths

        import scala.scalajs.js

        r.monthlyOptionSelectionValue = List(
          "1st",
          "2nd",
          "3rd",
          "4th",
          "5th",
          "6th",
          "7th",
          "8th",
          "9th",
          "10th",
          "11th",
          "12th",
          "13th",
          "14th",
          "15th",
          "16th",
          "17th",
          "18th",
          "19th",
          "20th",
          "21st",
          "22nd",
          "23rd",
          "24th",
          "25th",
          "26th",
          "27th",
          "28th",
          "29th",
          "30th"
        ).toJSArray

        r.everyNumberOfMonths = 1 //Every month
        r.daysOfTheMonthSelected = List("3")
        r.selectedMonths = Array[String]().toJSArray
        r.dailySelection = DailySelection.EveryDay
        r.periodOfDays = 1

        r.everyMonday = false
        r.everyTuesday = false
        r.everyWednesday = false
        r.everyThursday = false
        r.everyFriday = false
        r.everySaturday = false
        r.everySunday = false

        r.everyHour = 0
        r.everyMinute = 0

        r.monthlySchedule = Cron.unsafeParse("0 0 0 1 1 ?")
        r.dailySchedule = Cron.unsafeParse("0 0 12 ? * 0")
        r.periodicSchedule = Cron.unsafeParse("0 */5 * ? * *")
      }
    }
  }

  object TimeframeProps {
    def apply(
        savedCron: js.UndefOr[CronExpr] = js.undefined,
        selectedTenant: js.UndefOr[Int] = js.undefined
    ): TimeframeProps = {
      js.Dynamic
        .literal(
          "savedCron" -> savedCron.asInstanceOf[js.Any],
          "selectedTenant" -> selectedTenant.asInstanceOf[js.Any]
        )
        .asInstanceOf[TimeframeProps]
    }
  }

  class TimeframeData extends js.Object {
    /* emails */
    var emailInput: String = "example@outlook.com"

    /* data table */
    var schedulesEditable: List[Boolean] = List(false)

    var reportNames = List("")
    var reportNameDictionary: Map[Int, String] = Map(0 -> "Temp")

    /* stepper */
    var showMenu = true

    /* state of schedule type */
    var currentState: Period = Period.Monthly
    var selectedReport: Option[Int] = None

    /* yearly */
    var dateHint: String = ""
    var date: LocalDate = LocalDate.MIN
    var isDateSelected: Boolean = false

    /* time */
    var time: LocalTime = LocalTime.NOON
    var isArrivalTimeSelected: Boolean = false
    var arrivalTimeDisplay: String = ""

    /* monthly */
    var monthlySelection: MonthlySelection = MonthlySelection.SpecificMonths

    import scala.scalajs.js

    var monthlyOptionSelectionValue: js.Array[String] = List(
      "1st",
      "2nd",
      "3rd",
      "4th",
      "5th",
      "6th",
      "7th",
      "8th",
      "9th",
      "10th",
      "11th",
      "12th",
      "13th",
      "14th",
      "15th",
      "16th",
      "17th",
      "18th",
      "19th",
      "20th",
      "21st",
      "22nd",
      "23rd",
      "24th",
      "25th",
      "26th",
      "27th",
      "28th",
      "29th",
      "30th"
    ).toJSArray

    var everyNumberOfMonths = 1 //Every month
    var daysOfTheMonthSelected: List[String] = List("3")
    var selectedMonths = Array[String]().toJSArray

    /* daily */
    var dailySelection: DailySelection = DailySelection.EveryDay
    var periodOfDays = 1

    var everyMonday = false
    var everyTuesday = false
    var everyWednesday = false
    var everyThursday = false
    var everyFriday = false
    var everySaturday = false
    var everySunday = false

    /* periodically */
    var everyHour = 0
    var everyMinute = 0

    /* cronExpression set to defaults */
    var monthlySchedule: CronExpr = Cron.unsafeParse("0 0 0 1 1 ?")
    var dailySchedule: CronExpr = Cron.unsafeParse("0 0 12 ? * 0")
    var periodicSchedule: CronExpr = Cron.unsafeParse("0 */5 * ? * *")
  }

}
