package com.sludg.client.components.scheduling.period

import java.time.LocalTime

import com.sludg.client.components.scheduling.helpers.CronConverter
import com.sludg.client.components.scheduling.helpers.CronConverter.Period
import com.sludg.client.components.scheduling.helpers.CronConverter.Period.Periodically
import com.sludg.vue.RenderHelpers._
import com.sludg.vue.{EventBindings, RenderOptions, _}
import com.sludg.vuetify.VSliderProps
import com.sludg.vuetify.VuetifyComponents.{vCard, vFlex, vLayout, _}
import com.sludg.vuetify.components.{VCardProps, _}
import cron4s.Cron
import cron4s.expr.CronExpr
import org.scalajs.dom.raw.Event
import org.log4s._
import scala.scalajs.js
import scala.scalajs.js.JSConverters._
import scala.scalajs.js.UndefOr

object PeriodicallyPage {

  private[period] val logger = getLogger

  type PeriodicallyComponent =
    VueComponent[_ <: PeriodicallyPageProps, _ <: Slots, _ <: PeriodicScopedSlots]
      with PeriodicallyPageData
      with PeriodicallyPageMethods
      with js.Object
      with PeriodicallyPageProps

  def periodicallyPageRenderer(registrationName: String) =
    namedTag[PeriodicallyPageProps, PeriodicallyPageEvents, PeriodicScopedSlots](registrationName)

  def periodicallyPageComponent() = {
    VueComponent.builder
      .withScopedSlots[PeriodicScopedSlots]
      .withData(new PeriodicallyPageData)
      .withMethods(new PeriodicallyPageMethods())
      .withProps(PeriodicallyPageProps())
      .build(
        watch = new PeriodicallyPageWatcher,
        components = js.Dynamic.literal(),
        templateOrRender = Right((component, renderer) => {
          div(
            vCard(
              VCardProps(
                flat = Some(true),
                `max-width` = Some(Left(650)),
                `min-height` = Some(Left(150)),
                height = Some(Left(160))
              ),
              vLayout(
                vFlex(
                  p(
                    RenderOptions(
                      style = Some(
                        js.Dynamic.literal(
                          "text-align" -> "center",
                          "margin-top" -> "25px",
                          "margin-bottom" -> "45px",
                          "font-size" -> "21px"
                        )
                      )
                    ),
                    "Every " + component.data(component.step).toString + (if (component.step > 6)
                                                                            " hours"
                                                                          else " minutes")
                  )
                )
              ),
              combined(component, renderer),
              vLayout(vFlex(p("")))
            )
          ).render(renderer)
        })
      )
  }

  def combined(component: PeriodicallyComponent, renderer: VueInstanceProperties.CreateElement) = {
    vListTile(
      RenderOptions(
        style = Some(
          js.Dynamic.literal(
            "text-align" -> "center"
          )
        )
      ),
      vButton(
        vIcon("remove"),
        RenderOptions(
          props = Some(VButtonProps(disabled = Some(component.decreaseButton), icon = Some(true))),
          on = Some(
            EventBindings(
              click = js.defined(e => {
                if (component.step == 1) {
                  component.step = component.step - 1
                  component.decreaseButton = true
                } else {
                  component.step = component.step - 1
                  component.increaseButton = false
                }

                if (component.step >= 7) {
                  component.everyHour = component.data(component.step)
                  component.everyMinute = 0
                  component.updateScheduleEvent(component)
                } else {
                  component.everyMinute = component.data(component.step)
                  component.everyHour = 0
                  component.updateScheduleEvent(component)
                }
              })
            )
          )
        )
      ),
      vSlider(
        RenderOptions(
          `class` = List(Left("align-center")),
          props = Some(
            VSliderProps(
              `thumb-label` = Some(Left(true)),
              max = Some(Left(13)),
              value = Some(Left(component.step)),
              `thumb-size` = Some(Left(40)),
              `hide-details` = Some(true),
              `tick-labels` = Some(
                List[js.Any](
                  "6m",
                  " ",
                  "10m",
                  " ",
                  "15m",
                  " ",
                  "30m",
                  "1h",
                  " ",
                  "3h",
                  " ",
                  "6h",
                  " ",
                  "12h"
                ).toJSArray
              )
            )
          ),
          on = Some(
            EventBindings(
              change = js.defined(e => {
                component.step = e.toString.toInt
                resetCombinedStepper(component)
                if (component.step >= 7) {
                  component.everyHour = component.data(component.step)
                  component.everyMinute = 0
                  component.updateScheduleEvent(component)
                } else {
                  component.everyMinute = component.data(component.step)
                  component.everyHour = 0
                  component.updateScheduleEvent(component)
                }
              })
            )
          ),
          scopedSlots = Some(new PeriodicScopedSlots {
            override val `thumb-label`: UndefOr[js.Function1[js.Dynamic, VNode]] =
              js.defined(props => {
                val time = (if (props.value.toString.toInt > 6) {
                              "h"
                            } else {
                              "m"
                            })
                div(p("" + component.data(props.value.toString.toInt) + time)).render(renderer)
              })
          })
        )
      ),
      vButton(
        vIcon("add"),
        RenderOptions(
          props = Some(VButtonProps(disabled = Some(component.increaseButton), icon = Some(true))),
          on = Some(
            EventBindings(
              click = js.defined(e => {

                if (component.step == 12) {
                  component.step = component.step + 1
                  component.increaseButton = true
                } else {
                  component.step = component.step + 1
                  component.decreaseButton = false
                }

                if (component.step >= 7) {
                  component.everyHour = component.data(component.step)
                  component.everyMinute = 0
                  component.updateScheduleEvent(component)
                } else {
                  component.everyMinute = component.data(component.step)
                  component.everyHour = 0
                  component.updateScheduleEvent(component)
                }
              })
            )
          )
        )
      )
    )
  }

  def resetCombinedStepper(component: PeriodicallyComponent): Unit = {
    component.step match {
      case 0 => {
        component.decreaseButton = true
        component.increaseButton = false
      }
      case 13 => {
        component.increaseButton = true
        component.decreaseButton = false
      }
      case _ => {
        component.increaseButton = false
        component.decreaseButton = false
      }
    }
  }

  def getPeriodFromCron(component: PeriodicallyPageData, cron: CronExpr) = {
    val hours = cron.hours.toString()
    val mins = cron.minutes.toString()

    if (hours.contains("*") && hours.contains("/") && hours.exists(_.isDigit)) {
      component.everyMinute = 0
      component.everyHour = component.data(component.data.indexOf(hours.filter(_.isDigit).toInt))

      hours.filter(_.isDigit).toInt
    } else {
      component.everyMinute = component.data(component.data.indexOf(mins.filter(_.isDigit).toInt))
      component.everyHour = 0

      mins.filter(_.isDigit).toInt
    }
  }

  trait PeriodicScopedSlots extends ScopedSlots {
    val `thumb-label`: js.UndefOr[js.Function1[js.Dynamic, VNode]]
  }

  trait PeriodicallyPageEvents extends EventBindings {
    def test(e: Event): Unit
  }

  object PeriodicallyPageEvents {
    def apply() = {
      "".asInstanceOf[PeriodicallyPageEvents]
    }
  }

  class PeriodicallyPageMethods extends js.Object {

    def generateSchedule(data: PeriodicallyComponent) = {
      val hourlyDimension = data.everyHour match {
        case 0 => "*";
        case _ => s"*/${data.everyHour}"
      }
      val minuteDimension = data.everyMinute match {
        case 0 => "0";
        case _ => s"*/${data.everyMinute}"
      }
      data.schedule = CronConverter.buildCron("0", minuteDimension, hourlyDimension, "?", "*", "*")
      logger.debug("CronEx generated: " + data.schedule)
    }

    def updateScheduleEvent(component: PeriodicallyComponent) = {
      generateSchedule(component)
      component.$emit("updateSchedule", component.schedule.toString)
    }

  }

  trait PeriodicallyPageProps extends VueProps {
    val savedCron: js.UndefOr[CronExpr] = js.undefined
  }

  object PeriodicallyPageProps {
    def apply(
        savedCron: js.UndefOr[CronExpr] = js.undefined,
        selectedTenant: js.UndefOr[Int] = js.undefined
    ): PeriodicallyPageProps = {
      js.Dynamic
        .literal(
          "savedCron" -> savedCron.asInstanceOf[js.Any],
          "selectedTenant" -> selectedTenant.asInstanceOf[js.Any]
        )
        .asInstanceOf[PeriodicallyPageProps]
    }
  }

  class PeriodicallyPageWatcher extends js.Object {

    def selectedTenant(newTenant: Int, oldValue: Int): Unit = {
      //Resetting state
      if (newTenant != oldValue) {
        logger.info("PeriodicallyPageWatcher: New Tenant Selected! " + newTenant.toString())

        val p = this.asInstanceOf[PeriodicallyPageData]

        p.schedule = Cron.unsafeParse("0 10 12 ? * *")

        p.time = LocalTime.NOON
        p.isArrivalTimeSelected = false
        p.arrivalTimeDisplay = ""

        p.step = 0

        p.increaseButton = false
        p.decreaseButton = true

        p.everyHour = 1
        p.everyMinute = 0

        p.vDialogModel = false
      }
    }

    def savedCron(newVal: js.UndefOr[CronExpr], oldVal: js.UndefOr[CronExpr]): Unit = {
      if (newVal.isDefined) {
        val comp = this.asInstanceOf[PeriodicallyComponent]
        val data = this.asInstanceOf[PeriodicallyPageData]
        val methods = this.asInstanceOf[PeriodicallyPageMethods]

        data.schedule = newVal.get
        val stepValue = getPeriodFromCron(data, newVal.get)
        //12 appears twice in list
        if (stepValue == 12 && data.everyHour == 12) {
          data.step = data.data.lastIndexOf(stepValue)
        } else {
          data.step = data.data.indexOf(stepValue)
        }

        methods.updateScheduleEvent(comp)
      }
    }
  }

  class PeriodicallyPageData extends js.Object {

    /* allowed minutes and hour values */
    val data = List(6, 10, 12, 15, 20, 30, 1, 2, 3, 4, 6, 8, 12)

    /* cronExpression */
    var schedule: CronExpr = Cron.unsafeParse("0 10 12 ? * *")

    /* time */
    var time: LocalTime = LocalTime.NOON
    var isArrivalTimeSelected: Boolean = false
    var arrivalTimeDisplay: String = ""

    /* stepper positions */
    var step = 0

    /* stepper buttons disablity/enability */
    var increaseButton = false
    var decreaseButton = true

    /* cron expression periodic value */
    var everyHour = 1
    var everyMinute = 0

    var vDialogModel = false
  }

}
