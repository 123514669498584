package com.sludg.client.components

import com.sludg.client.components.tables.GroupingTableModels.TableColumnsWithSorting
import com.sludg.client.components.tables._
import com.sludg.util.models.CallModels.CDR
import com.sludg.util.models.GroupingModels
import com.sludg.util.models.GroupingModels._
import com.sludg.util.models.ReportModels.{Filter, ReportColumn}
import com.sludg.vue._
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components._
import com.sludg.vuetify.components.grid.VGridProps
import org.log4s.getLogger

import scala.scalajs.js
import scala.scalajs.js.JSConverters._

object GraphManager {

  private[this] val logger = getLogger

  import com.sludg.vue.RenderHelpers._

  type GraphManagerComponent = VueComponent[_ <: GraphManagerProps, _ <: Slots, _ <: ScopedSlots]
    with GraphManagerData
    with GraphManagerMethods
    with js.Object
    with GraphManagerProps

  def graphManagerRenderer(registrationName: String) =
    namedTag[GraphManagerProps, EventBindings, ScopedSlots]("GraphManager")

  val projectionSelector = ProjectionSelector.projectionSelectorRenderer("ProjectionSelector")
  val sunburstGraph = SunburstGraph.sunburstGraphRenderrer("SunburstGraph")
  val groupingDataTable = GroupingDataTable.groupingDataTableRenderer("GroupingDataTable")
  val callsDataTable = CallsTable.callTableRenderer("CallsTable")
  val chartCreator = ChartCreator.chartCreatorRenderer("ChartCreator")

  def graphManagerComponent() = {
    VueComponent.builder
      .withData(new GraphManagerData())
      .withMethods(new GraphManagerMethods)
      .withProps(GraphManagerProps())
      .build(
        components = js.Dynamic.literal(
          "ProjectionSelector" -> ProjectionSelector.projectionSelectorComponent(),
          "SunburstGraph" -> SunburstGraph.sunburstGraphComponent(),
          "GroupingDataTable" -> GroupingDataTable.groupingDataTableComponent(),
          "CallsTable" -> CallsTable.callsTableComponent(),
          "ChartCreator" -> ChartCreator.chartCreatorComponent()
        ),
        created = js.defined(c => {
          logger.debug("Graph Manager created")
          c.$root.$vuetify.goTo("#graphManager")
        }),
        templateOrRender = Right((component, renderer) => {
          div(
            js.Dynamic
              .literal(
                "attrs" -> js.Dynamic.literal(
                  "id" -> "graphManager"
                )
              )
              .asInstanceOf[RenderOptions[VueProps, EventBindings, ScopedSlots]],
            if (component.graphDataList.isDefined) {
              projectionSelector(
                RenderOptions[ProjectionSelectorProps, ProjectionSelectorEvents, ScopedSlots](
                  props = Some(
                    ProjectionSelectorProps(
                      graphDataList = component.graphDataList
                    )
                  ),
                  on = Some(
                    ProjectionSelectorEvents(
                      projectionChoicesWithData = js.defined(e => {
                        component.listenToButtonsAndData(e)
                      })
                    )
                  )
                )
              )
            } else {
              logger.debug("GraphData not defined")
              nothing
            },
            if (
              component.fullProjectionData.isDefined && component.cdrData.isDefined && component.graphDataList.isDefined
            ) {
              val defaultItemisationSort: Option[Category[_]] =
                if (component.graphDataList.get.subGroups.nonEmpty)
                  Some(component.graphDataList.get.subGroups.head.categoryData.category)
                else None
              vContainer(
                chartCreator(
                  RenderOptions[ChartCreatorProps, ChartCreatorEvents, ScopedSlots](
                    props = Some(
                      ChartCreatorProps(
                        fullProjectionData = js.defined(component.fullProjectionData.get)
                      )
                    )
                  )
                ),
                p(""),
                if (
                  component.fullProjectionData.get.projectionData.children.nonEmpty || component.cdrData.get.nonEmpty
                ) {
                  vTabs(
                    if (component.graphDataList.get.subGroups.nonEmpty) {
                      vTab(
                        vIcon("table_chart"),
                        "Report"
                      )
                    } else {
                      nothing
                    },
                    vTab(
                      vIcon("call"),
                      "Call History"
                    ),
                    if (component.graphDataList.get.subGroups.nonEmpty) {
                      vTabItem(
                        groupingDataTable(
                          RenderOptions(
                            props = Some(
                              DataDisplayerProps(
                                projection = component.fullProjectionData.get.projectionData,
                                projectionData = component.graphDataList.get,
                                preSelectedHeaders = component.selectedHeaders.get.toList,
                                filters = component.filters.getOrElse(js.Array()).toList
                              )
                            ),
                            on = Some(
                              DataDisplayerEvents(
                                headersSelected = js.defined(e => {
                                  logger.debug(
                                    "Received headers from data displayer sending them to reportPage"
                                  )
                                  component.$emit("headersSelected2", e)
                                })
                              )
                            )
                          )
                        )
                      )
                    } else {
                      nothing
                    },
                    vTabItem(
                      callsDataTable(
                        RenderOptions(
                          props = Some(
                            CallsTableProps(
                              cdrs = component.cdrData.get.toList,
                              defaultSort = defaultItemisationSort
                            )
                          )
                        )
                      )
                    )
                  )(
                    RenderOptions(
                      props = Some(
                        VTabsProps(
                          grow = Some(true)
                        )
                      ),
                      on = Some(EventBindings(change = js.defined(e => {
                        if (js.isUndefined(e)) {
                          logger.debug("Change event inside vTabs is undefined")
                        } else {
                          component.currentTab = e.asInstanceOf[Int]
                        }
                      })))
                    )
                  )
                } else {
                  nothing
                }
              )
            } else {
              logger.debug("Cdrs/Projection/graphData is not defined")
              nothing
            }
          ).render(renderer)
        })
      )
  }
}

class GraphManagerData extends js.Object {
  var fullProjectionData: Option[ProjectionChoicesWithData[_]] = None
  var breakDownGraph: Option[js.Array[VDataTableHeader]] = None
  var currentTab: Int = 0
  var defaultSortHeader: Option[GroupingModels.Category[_]] = None
  var lastSavedCdr: js.Array[CDR] = js.Array()
}

class GraphManagerMethods extends js.Object {
  def listenToButtonsAndData(selection: ProjectionChoicesWithData[_]): Unit = {
    this.asInstanceOf[GraphManagerData].fullProjectionData =
      if (js.isUndefined(selection)) None else Some(selection)
  }
}

//class GraphManagerWatcher() extends js.Object {
//  def cdrData(value: js.Any) = {
//    val component = this.asInstanceOf[GraphManagerComponent]
//    if (js.isUndefined(value)) {
//
//    } else {
//      println("CDR CHANGED")
//      if (value.asInstanceOf[js.Array[CDR]] == component.lastSavedCdr) {
//
//      } else {
//        c.$root.$vuetify.goTo("#graphManager")
//      }
//    }
//  }
//}

trait GraphManagerProps extends VueProps {
  val graphDataList: js.UndefOr[RootReportData] = js.undefined
  val cdrData: js.UndefOr[js.Array[CDR]] = js.undefined
  val selectedHeaders: js.UndefOr[js.Array[ReportColumn]] = js.undefined
  val filters: js.UndefOr[js.Array[Filter]] = js.undefined
}

object GraphManagerProps {
  def apply(
      graphDataList: js.UndefOr[RootReportData] = js.undefined,
      selectedHeaders: js.UndefOr[js.Array[ReportColumn]] = js.undefined,
      cdrData: js.UndefOr[List[CDR]] = js.undefined,
      filters: js.UndefOr[List[Filter]] = js.undefined
  ): GraphManagerProps = {
    js.Dynamic
      .literal(
        "graphDataList" -> graphDataList.map(_.asInstanceOf[js.Any]),
        "selectedHeaders" -> selectedHeaders.map(_.toJSArray),
        "cdrData" -> cdrData.map(_.toJSArray),
        "filters" -> filters.map(_.toJSArray)
      )
      .asInstanceOf[GraphManagerProps]
  }
}

trait GraphManagerEvents extends EventBindings {
  def headersSelected2(e: List[TableColumnsWithSorting]): Unit
}

object GraphManagerEvents {
  def apply(
      bindings: EventBindings = EventBindings(),
      headersSelected2: js.UndefOr[js.Function1[List[TableColumnsWithSorting], Unit]]
  ) = {
    bindings.asInstanceOf[js.Dynamic].updateDynamic("headersSelected2")(headersSelected2)
    val selectedBinding = bindings.asInstanceOf[GraphManagerEvents]
    selectedBinding
  }
}
