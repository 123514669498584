package com.sludg.client.components

import java.time.{LocalDateTime, ZoneId}
import java.time.format.DateTimeFormatter

import com.sludg.client.components.CallsTable.CallsTableComponent
import com.sludg.client.components.tables._
import com.sludg.util.PresenterSyntax._
import com.sludg.util.ApiModelPresenters._
import com.sludg.util.models.CallModels.CDR
import com.sludg.util.models.GroupingModels
import com.sludg.util.models.GroupingModels.Category.{Answer, Direction, Termination}
import com.sludg.util.models.GroupingModels.CategoryData
import com.sludg.util.models.ReportModels.ReportColumn
import com.sludg.vue._
import com.sludg.vuetify.VuetifyComponents.{vDataTable, vIcon}
import com.sludg.vuetify.components.{VDataTableHeadersSlotData, VDataTableHeader, VDataTableProps}
import org.log4s.getLogger

import scala.math.Ordering
import scala.math.Ordering.Boolean
import scala.scalajs.js.JSConverters._
import scala.scalajs.js
import com.sludg.vuetify.components.VDataTableScopedSlots
import com.sludg.vuetify.components.VDataTableItemSlotData
import com.sludg.vuetify.components.VDataTableHeader

object CallsTable {

  import com.sludg.vue.RenderHelpers._

  private[components] val logger = getLogger

  type CallsTableComponent = VueComponent[_ <: CallsTableProps, _ <: Slots, _ <: ScopedSlots]
    with CallsTableData
    with CallsTableProps

  def callTableRenderer(registrationName: String) =
    namedTag[CallsTableProps, EventBindings, ScopedSlots]("CallsTable")

  val dateTimeFormatter = DateTimeFormatter.ofPattern("dd/MM/yyyy HH:mm:ss")

  def callsTableComponent() = {
    VueComponent.builder
      .withData(new CallsTableData())
      .withComputed(CallsTableComputed())
      .withProps(js.Array("cdrs", "defaultSort").asInstanceOf[CallsTableProps])
      .build(
        templateOrRender = Right((component, renderer) =>
          if (component.cdrs.nonEmpty) {
            div(
              vDataTable(
                RenderOptions(
                  props = Some(
                    VDataTableProps[CDR, VDataTableHeader](
                      items = Some(component.sortedData),
                      headers = Some(component.headers)
                    )
                  ),
                  scopedSlots = Some(
                    new VDataTableScopedSlots[CDR, VDataTableHeader](
                      items = js.defined(props => {

                        val subscriberExtensions: String = props.item.subscriberExtensions
                          .map(_.map(_.toString).mkString(", "))
                          .getOrElse("-")
                        val autoAttendantExtensions: String = props.item.autoAttendantExtensions
                          .map(_.map(_.toString).mkString(", "))
                          .getOrElse("-")
                        val callGroupExtensions: String = props.item.callGroupExtensions
                          .map(_.map(_.toString).mkString(", "))
                          .getOrElse("-")

                        tr(
                          td(props.item.timestamp.format(dateTimeFormatter)),
                          td(props.item.callingNumber.get),
                          td(props.item.dialedNumber.get),
                          td(CategoryData(Answer, props.item.answer).present),
                          td(CategoryData(Direction, props.item.direction).present),
                          td(DataDisplayerUtil.longToString(props.item.ringTime)),
                          td(DataDisplayerUtil.longToString(props.item.talkTime.getOrElse(0))),
                          td(DataDisplayerUtil.longToString(props.item.duration)),
                          td(CategoryData(Termination, props.item.terminationPoint).present),
                          td(subscriberExtensions),
                          td(autoAttendantExtensions),
                          td(callGroupExtensions),
                          td(props.item.cos.toString),
                          td(props.item.site.getOrElse("-").toString)
                        ).render(renderer)
                      }),
                      headers = js.defined(props => {
                        tr(
                          props.headers.map(a => {
                            val classCustom =
                              if (component.descending) Left("desc") else Left("asc")
                            val classCustom2 =
                              if (component.sortBy contains a.value.get) Left("active")
                              else Left("")
                            val icon =
                              if (component.descending) vIcon("arrow_downward")
                              else vIcon("arrow_upward")
                            th(if (a.text.isDefined) a.text.get else "", vIcon("arrow_upward"))(
                              RenderOptions(
                                props = Some(
                                  js.Dynamic
                                    .literal(
                                      "key" -> a.text.get.asInstanceOf[js.Any]
                                    )
                                    .asInstanceOf[VueProps]
                                ),
                                style = Some(
                                  js.Dynamic.literal(
                                    "white-space" -> "normal"
                                  )
                                ),
                                `class` = List(
                                  Left("column"),
                                  Left("sortable"),
                                  classCustom,
                                  classCustom2
                                ),
                                on = Some(
                                  EventBindings(
                                    click = js.defined(_ => {
                                      component.descending = !component.descending
                                      component.sortBy = Some(a.value.get)
                                    })
                                  )
                                )
                              )
                            )
                          })
                        ).render(renderer)
                      })
                    )
                  )
                )
              )
            ).render(renderer)
          } else {
            div(nothing).render(renderer)
          }
        )
      )
  }
}

trait CallsTableProps extends VueProps {
  val cdrs: List[CDR]
  val defaultSort: js.UndefOr[GroupingModels.Category[_]] = js.undefined
}

object CallsTableProps {

  def apply(
      cdrs: List[CDR] = Nil,
      defaultSort: Option[GroupingModels.Category[_]] = None
  ): CallsTableProps = {
    js.Dynamic
      .literal(
        "cdrs" -> cdrs.asInstanceOf[js.Object],
        "defaultSort" -> defaultSort.map(_.asInstanceOf[js.Object]).orUndefined
      )
      .asInstanceOf[CallsTableProps]
  }
}

trait CallsTableComputed extends js.Object {
  def sortedData: List[CDR]
}

object CallsTableComputed {

  implicit def listOrdering[A](implicit ord: Ordering[A]): Ordering[List[A]] =
    (x: List[A], y: List[A]) => {
      x.zip(y)
        .collectFirst {
          case (a, b) if ord.compare(a, b) != 0 => ord.compare(a, b)
        }
        .getOrElse(x.size.compareTo(y.size))
    }

  implicit val dateOrdering: Ordering[LocalDateTime] = _ compareTo _

  val fieldSorters: Map[String, Ordering[CDR]] = Map(
    "callingNumber" -> Ordering.by(_.callingNumber),
    "dialedNumber" -> Ordering.by(_.dialedNumber),
    "cos" -> Ordering.by(_.cos.toString),
    "talkTime" -> Ordering.by(_.talkTime),
    "answer" -> Ordering.by(_.answer),
    "duration" -> Ordering.by(_.duration),
    "direction" -> Ordering.by(_.direction.toString),
    "ringTime" -> Ordering.by(_.ringTime),
    "site" -> Ordering.by(_.site),
    "terminationPoint" -> Ordering.by(_.terminationPoint.toString),
    "subscriberExtensions" -> Ordering.by(t => t.subscriberExtensions),
    "autoAttendantExtensions" -> Ordering.by(_.autoAttendantExtensions),
    "callGroupExtensions" -> Ordering.by(_.callGroupExtensions),
    "timestamp" -> Ordering.by(_.timestamp)
  )

  val defaultSort = fieldSorters("timestamp")

  def fieldSorter(field: String, descending: Boolean): Ordering[CDR] = {
    fieldSorters.get(field).map(o => if (descending) o.reverse else o).getOrElse(defaultSort)
  }

  def apply(): CallsTableComputed = {
    val sortedDataFunction: js.ThisFunction0[CallsTableData with CallsTableProps, List[CDR]] =
      data => {
        data.sortBy
          .map(field => {
            data.cdrs.sorted(fieldSorter(field, data.descending))
          })
          .getOrElse(data.cdrs)
      }

    js.Dynamic
      .literal(
        sortedData = sortedDataFunction
      )
      .asInstanceOf[CallsTableComputed]
  }
}

class CallsTableData extends js.Object {

  var descending: Boolean = true
  var sortBy: Option[String] = None
  var tableData: List[CDR] = List()
  var headers: List[VDataTableHeader] = List(
    new VDataTableHeader("Timestamp", "timestamp"),
    new VDataTableHeader("Calling Number", "callingNumber"),
    new VDataTableHeader("Dialed Number", "dialedNumber"),
    new VDataTableHeader("Answer", "answer"),
    new VDataTableHeader("Direction", "direction"),
    new VDataTableHeader("Ring Time", "ringTime"),
    new VDataTableHeader("Talk Time", "talkTime"),
    new VDataTableHeader("Duration", "duration"),
    new VDataTableHeader("Termination Point", "terminationPoint"),
    new VDataTableHeader("User Extensions", "subscriberExtensions"),
    new VDataTableHeader("Auto-Attendant Extensions", "autoAttendantExtensions"),
    new VDataTableHeader("CallGroup Extensions", "callGroupExtensions"),
    new VDataTableHeader("Class", "cos"),
    new VDataTableHeader("Site", "site")
  )
}
