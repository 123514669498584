package com.sludg.client

import buildinfo.sbtinfo
import com.sludg.client.pages.ReportPage.ReportPageComponent
import com.sludg.client.pages.ReportPageData
import com.sludg.models.Config.AppLink
import com.sludg.services.ApiCalls
import com.sludg.util.models.SilhouetteModels.Tenant
import com.sludg.vue._
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components.VListTileProps
import com.sludg.Security
import com.sludg.components.VTSLApp
import org.log4s.getLogger
import org.scalajs.dom.Event
import com.sludg.FieldExtractor

import scala.scalajs.js

object AnalyticsApp {

  import com.sludg.helpers.AppSetup.VtslAppScopedSlots
  import com.sludg.helpers.AppSetup.VtslAppProps
  import com.sludg.helpers.AppSetup.VtslAppData
  import com.sludg.helpers.AppSetup.VtslAppData

  private[this] val logger = getLogger

  import RenderHelpers._

  def analyticsAppRenderer(registrationName: String) =
    namedTag[VtslAppProps, EventBindings, VtslAppScopedSlots](registrationName)

  val appRenderer = VTSLApp.appRenderer("VtslApp")

  def analyticsAppComponent(
      apiCalls: ApiCalls,
      security: Security,
      apiCallBus: Vue,
      otherApps: List[AppLink]
  ) =
    VueComponent.builder
      .withScopedSlots[VtslAppScopedSlots]
      .withPropsAs[VtslAppProps]
      .build(
        components = js.defined(
          js.Dynamic.literal(
            "VtslApp" -> VTSLApp.createApp(
              "Analytics",
              apiCalls,
              apiCallBus,
              otherApps,
              Some(sbtinfo.version),
              Some("guide.pdf"),
              _ => security.logout(),
              showLogo = true,
              showFooter = true
            )
          )
        ),
        updated = js.defined(c => {
          logger.debug("Analytics app updated")
        }),
        templateOrRender = Right((component, renderer) => {
          div(
            appRenderer(
              RenderOptions(
                props = Some(
                  new VtslAppProps(
                    title = component.title,
                    spacedToolbar = component.spacedToolbar,
                    displayTenantSelector = true,
                    token = component.token,
                    temporaryDrawer = true
                  )
                ),
                scopedSlots = Some(
                  new VtslAppScopedSlots {
                    override val toolbar: js.UndefOr[js.Function1[Option[Tenant], VNode]] =
                      component.$scopedSlots.toolbar
                    override val toolbarExtension: js.UndefOr[js.Function1[Option[Tenant], VNode]] =
                      component.$scopedSlots.toolbarExtension
                    override val drawer: js.UndefOr[js.Function1[Option[Tenant], VNode]] =
                      js.defined(tenant => {
                        vList(
                          vListTile(
                            VListTileProps(
                              to = Some(Left("/"))
                            ),
                            vListTileAction(vIcon("show_chart")),
                            vListTileAction(
                              "Analytics"
                            )
                          ),
                          vListTile(
                            VListTileProps(
                              to = Some(Left("/scheduling"))
                            ),
                            vListTileAction(vIcon("calendar_today")),
                            vListTileAction(
                              "Scheduling"
                            )
                          )
                        ).render(renderer)
                      })
                    override val default: js.UndefOr[js.Function1[Option[Tenant], VNode]] =
                      component.$scopedSlots.default
                  }
                )
              )
            )
          ).render(renderer)
        })
      )
}
