package com.sludg.client.components.tables

import com.sludg.util.PresenterSyntax._
import com.sludg.util.ApiModelPresenters._
import com.sludg.util.{ChildProjection, Projection, ReportProjections, RootProjection}
import com.sludg.util.models.GroupingModels._
import com.sludg.util.models.ReportModels.ProjectionType._
import com.sludg.util.models.ReportModels.{ProjectionType, Sort}

import scala.collection.immutable
import scala.collection.mutable.ListBuffer

object DataDisplayerUtil {

  // Height gives the total levels of the projection
  def height(n: Projection[Int]): Int = {
    1 + n.children.foldLeft(-1)((h, c) => h max height(c))
  }

  // size gives the total number of nodes in the projection
  def size(n: Projection[Int]): Int = {
    1 + n.children.foldLeft(0)((s, c) => s + size(c))
  }

  def convertTimeInt(unformatted: Int): String = {
    val unformattedTime = unformatted
    val s = unformattedTime.toInt % 60
    val m = (unformattedTime / 60) % 60
    val h = (unformattedTime / 60 / 60)
    val time = s"%02.0f:%02.0f:%02.0f".format(h, m, s)
    time.toString()
  }

  def convertTimeDouble(unformatted: Double): String = {
    val unformattedTime = unformatted.toInt
    val s = unformattedTime % 60
    val m = (unformattedTime / 60) % 60
    val h = (unformattedTime / 60 / 60)
    val time = "%02.0f:%02.0f:%02.0f".format(h, m, s)
    time.toString()
  }

  def longToString(l: Long): String = {
    val unformattedTime = l.toInt
    val s = unformattedTime.toInt % 60
    val m = (unformattedTime / 60) % 60
    val h = (unformattedTime / 60 / 60)
    val time = "%02.0f:%02.0f:%02.0f".format(h, m, s)
    time.toString()
  }

  def dataToTableContents(data: RootReportData): TableContents = {

    /* Leaving these for debugging. Time converters give error during tests. So these needs to be used */

//        val totalProjection = ReportProjections.projectReport(data, _.total)
//        val talkStatsAverage = ReportProjections.projectReport(data, _.talkStats.average)
//        val talkStatsMax = ReportProjections.projectReport(data, _.talkStats.max)
//        val talkStatsMin = ReportProjections.projectReport(data, _.talkStats.min)
//        val talkStatsSum = ReportProjections.projectReport(data, _.talkStats.sum)
//        val ringStatsAverage = ReportProjections.projectReport(data, _.ringStats.average)
//        val ringStatsMax = ReportProjections.projectReport(data, _.ringStats.max)
//        val ringStatsMin = ReportProjections.projectReport(data, _.ringStats.min)
//        val ringStatsSum = ReportProjections.projectReport(data, _.ringStats.sum)
//        val totalDurationAverage = ReportProjections.projectReport(data, _.totalDurationStats.sum.toString)
//        val totalDurationMax = ReportProjections.projectReport(data, _.totalDurationStats.average.toString)
//        val totalDurationMin = ReportProjections.projectReport(data, _.totalDurationStats.max.toString)
//        val totalDurationSum = ReportProjections.projectReport(data, _.totalDurationStats.min.toString)

    def convertIntToCellData(i: Int): Option[IntCellData] = {
      Some(IntCellData(i, i.toString))
    }

    def convertLongtoCellData(i: Long): Option[IntCellData] = {
      Some(IntCellData(i.toInt, longToString(i)))
    }

    val totalProjection: RootProjection[Option[IntCellData]] =
      ReportProjections.projectReport(data, (convertIntToCellData _).compose(_.total))
    val talkStatsSum: RootProjection[Option[IntCellData]] =
      ReportProjections.projectReport(data, (convertLongtoCellData _).compose(a => a.talkStats.sum))
    val ringStatsSum: RootProjection[Option[IntCellData]] =
      ReportProjections.projectReport(data, (convertLongtoCellData _).compose(_.ringStats.sum))

    val talkStatsAverage = ReportProjections.projectReport(
      data,
      _.talkStats.average.map(v => DoubleCellData(v, convertTimeDouble(v)))
    )

    val talkStatsMax = ReportProjections.projectReport(
      data,
      _.talkStats.max.map(v => IntCellData(v.toInt, convertTimeDouble(v.toDouble)))
    )

    val talkStatsMin = ReportProjections.projectReport(
      data,
      _.talkStats.min.map(v => IntCellData(v.toInt, convertTimeDouble(v.toDouble)))
    )

    val ringStatsAverage = ReportProjections.projectReport(
      data,
      _.ringStats.average.map(v => DoubleCellData(v, convertTimeDouble(v)))
    )

    val ringStatsMax = ReportProjections.projectReport(
      data,
      _.ringStats.max.map(v => IntCellData(v.toInt, convertTimeDouble(v.toDouble)))
    )

    val ringStatsMin = ReportProjections.projectReport(
      data,
      _.ringStats.min.map(v => IntCellData(v.toInt, convertTimeDouble(v.toDouble)))
    )

    /* Can be uncommented to be used if required */
//    val totalDurationAverage = ReportProjections.projectReport(data, (convertLongtoCellData _).compose(_.totalDurationStats.sum))
//    val totalDurationMax = ReportProjections.projectReport(data, _.totalDurationStats.average.map(v => DoubleCellData(v, convertTimeDouble(v))))
//    val totalDurationMin = ReportProjections.projectReport(data, _.totalDurationStats.max.map(v => IntCellData(v.toInt, convertTimeInt(v.toInt))))
//    val totalDurationSum = ReportProjections.projectReport(data, _.totalDurationStats.min.map(v => DoubleCellData(v, convertTimeDouble(v))))

    val allProjections: Map[ProjectionType, RootProjection[Option[CellData]]] = Map(
      TotalCalls -> totalProjection,
      AvgTalkTime -> talkStatsAverage,
      MaxTalkTime -> talkStatsMax,
      MinTalkTime -> talkStatsMin,
      TotalTalkTime -> talkStatsSum,
      AvgRingTime -> ringStatsAverage,
      MaxRingTime -> ringStatsMax,
      MinRingTime -> ringStatsMin,
      TotalRingTime -> ringStatsSum
      /* Removed for now */
      //      "Duration Average" -> totalDurationAverage,
      //      "Duration Maximum" -> totalDurationMax,
      //      "Duration Minimum" -> totalDurationMin,
      //      "Duration Total" -> totalDurationSum
    )

    val tableContents: immutable.Iterable[TableContents] = allProjections.map(a => {
      projectionToTableContents(a._2, a._1)
    })

    val (headers, tableData) =
      tableContents.foldRight((Nil: List[ExtractedTableHeader], Nil: List[ExtractedTableData])) {
        case (a @ TableContents(rl, rr), (ll, lr)) =>
          val result: List[ExtractedTableData] = (lr ::: rr)
            .groupBy(_.categoryData)
            .view
            .mapValues(_.map(_.mapOfHeadersWithData).reduce(_ ++ _))
            .map { case (cat, data) =>
              ExtractedTableData(cat, data)
            }
            .toList
          (ll ::: rl) -> result
      }
    TableContents(headers, tableData)
  }

  def projectionToTableContents(
      data: RootProjection[Option[CellData]],
      projectionType: ProjectionType
  ): TableContents = {
    var pathToEndNode: ListBuffer[PathAndValue] = ListBuffer()
    val paths = ListBuffer[NodeWithData]()
    val allPaths: ListBuffer[List[NodeWithData]] = ListBuffer(List())
    val parents = ListBuffer[CategoryData[_]]()
    val rootHeaders: ListBuffer[ExtractedTableHeader] = ListBuffer()

    def recur(n: Projection[Option[CellData]], level: Int): Unit = {
      (n, level) match {
        case (ChildProjection(category, value, _), 1) =>
          rootHeaders.append(ExtractedTableHeader(List(category), projectionType))
          parents.append(category)
          paths.append(NodeWithData(category, value))
        case (ChildProjection(category, value, _), x: Int) if x > 1 =>
          paths.append(NodeWithData(category, value))
        case _ =>
        // Root node ignored
      }
      if (n.children.isEmpty) {
        allPaths.append(paths.toList)
        val p = NodePathWithData(projectionType, paths.toList)
        pathToEndNode += PathAndValue(p, n.value)
      } else {
        for (c <- n.children) {
          recur(c, level + 1)
        }
      }
      if (level > 0) {
        paths.dropRightInPlace(1)
      }
    }
    recur(data, 0)

    convertPathToTableContents(pathToEndNode.toSeq, rootHeaders.toList, projectionType)
  }

  def convertPathToTableContents(
      pathToEndNode: Seq[PathAndValue],
      rootHeaders: Seq[ExtractedTableHeader],
      projectionType: ProjectionType
  ): TableContents = {

    def partitionByAscendingIndexRange(input: List[NodeWithData]) = {
      input.inits.toList.reverse.tail
    }

    val partitionedByNodePath = pathToEndNode
      .flatMap(originalPath => {
        partitionByAscendingIndexRange(originalPath.nodePath.path).map(nodePath => {
          PathAndValue(NodePathWithData(projectionType, nodePath), nodePath.last.data)
        })
      })
      .distinct
      .toList

    val splitedCategoryPathData: Map[CategoryData[_], List[PathAndValue]] =
      partitionedByNodePath.groupBy(_.nodePath.path.head.categoryData)

    val extractedTableData: List[ExtractedTableData] = splitedCategoryPathData
      .map(a => {
        ExtractedTableData(
          a._1,
          a._2
            .map(b =>
              (
                NodePath(projectionType, b.nodePath.path.tail.map(a => Node(a.categoryData))),
                b.data.getOrElse(EmptyCell())
              )
            )
            .toMap
        )
      })
      .toList

    val extractedHeaders: Seq[ExtractedTableHeader] = partitionedByNodePath
      .map(a => ExtractedTableHeader(a.nodePath.path.tail.map(a => a.categoryData), projectionType))
      .distinct

    TableContents(
      headers = extractedHeaders.toList,
      data = extractedTableData.distinct
    )
  }

  def convertFromPathToHeader(
      categoryPath: List[CategoryData[_]],
      projectionType: ProjectionType
  ): Option[String] = {
    if (categoryPath.size < 1) {
      categoryPath.headOption match {
        case Some(catData) =>
          Some(catData.category.present.concat(s" ${projectionType.present}").trim)
        case _ => Some(s"${projectionType.present}")
      }
    } else {
      val result =
        categoryPath.map(a => a.present).mkString(" ").concat(s" ${projectionType.present}").trim
      if (result.length > 0) Some(result) else None
    }
  }
}

case class NodeWithData(
    categoryData: CategoryData[_],
    data: Option[CellData]
)

case class NodePath(projectionType: ProjectionType, path: List[Node])

case class Node(categoryData: CategoryData[_])

case class NodePathWithData(projectionType: ProjectionType, path: List[NodeWithData])

case class PathAndValue(nodePath: NodePathWithData, data: Option[CellData])

case class CategoryWithData[A](
    parent: CategoryData[_],
    projectionType: ProjectionType,
    category: String,
    data: A
)

case class ExtractedTableHeader(categoryData: List[CategoryData[_]], projectionType: ProjectionType)

case class HeaderWithGrouping(
    text: Option[String] = None,
    value: Option[String] = None,
    align: Option[String] = None,
    sortable: Option[Boolean] = None,
    sort: Option[Sort] = None,
    width: Option[String] = None,
    categoryData: Option[List[CategoryData[_]]] = None,
    projectionType: Option[ProjectionType] = None
)

trait CellData {
  val formatted: String
}

case class IntCellData(value: Int, formatted: String) extends CellData

case class DoubleCellData(value: Double, formatted: String) extends CellData

case class EmptyCell(formatted: String = "--") extends CellData

case class ExtractedTableData(
    categoryData: CategoryData[_],
    mapOfHeadersWithData: Map[NodePath, CellData]
)

case class TableContents(headers: List[ExtractedTableHeader], data: List[ExtractedTableData])
