package com.sludg.client.components

import java.time.LocalDate

import com.sludg.util.models.CallModels
import com.sludg.util.models.ReportModels.Filter
import com.sludg.util.models.ReportModels.Filter._
import com.sludg.util.models.SilhouetteModels.{AutoAttendant, CallGroup, Subscriber}
import com.sludg.vue._
import com.sludg.vuetify.components
import com.sludg.vuetify.components._
import com.sludg.vuetify.components.transitions.VTransitionProps
import org.log4s.getLogger

import scala.reflect.ClassTag
import scala.scalajs.js
import com.sludg.components.filters.FilterHelpers
import com.sludg.components.filters.DigitInputFilters
import com.sludg.components.filters.DateFilterInput
import com.sludg.components.filters.ExtensionInput
import _root_.com.sludg.components.filters.DigitInputFilters.DigitInputFilterProps

object FilterManager {

  import com.sludg.components.filters.DateFilterInput._
  import com.sludg.components.filters.EnumeratedPickers._
  import com.sludg.components.filters.ExtensionInput._
  import com.sludg.components.filters.FilterHelpers._
  import com.sludg.components.filters.TimeFilterInput._
  import com.sludg.vue.RenderHelpers._
  import com.sludg.vuetify.VuetifyComponents._

  private[this] val logger = getLogger

  private def callingNumberInput = DigitInputFilters.callingNumberRenderer("CallingNumberFilter")
  private def dialedNumberInput = DigitInputFilters.dialledNumberRenderer("DialedNumberFilter")
  private def dateInput = DateFilterInput.renderer("DateFilter")
  private def subscriberInput = ExtensionInput.subscriberExtensionFilterRenderer("SubscriberFilter")
  private def autoAttendantInput = ExtensionInput.autoAttendantFilterRenderer("AutoAttendantFilter")
  private def callGroupInput = ExtensionInput.callGroupFilterRenderer("CallGroupFilter")

  def filterManagerRenderer(registrationName: String) =
    namedTag[FilterManagerProps, FilterManagerEvents, ScopedSlots]("FilterManager")

  def replaceFilter(old: Filter, newF: Filter, all: List[Filter]): List[Filter] = {
    all.zipWithIndex
      .find(_._1 == old)
      .map { case (_, i) =>
        val (pre, _ :: post) = all.splitAt(i)
        pre ::: newF :: post
      }
      .getOrElse(all)
  }

  def filterItemRenderer(
      animKey: String,
      filter: Filter,
      component: VueComponent[_, _, _],
      data: FilterManagerData,
      props: FilterManagerProps
  ): RenderFunction[VNode] = {

    val currentFilters = props.currentFilters

    def answerFilterInput(currentValue: Boolean) = {
      enumeratedPicker[Boolean, Boolean](
        currentValue,
        _ == _,
        List(
          (true, "Answered", "Include Answered calls"),
          (false, "Not Answered", "Include Not Answered calls")
        ),
        (_, newVal, ticked) =>
          dispatchNewFilterList(
            replaceFilter(filter, AnswerFilter(newVal == ticked), currentFilters),
            component
          )
      )
    }

    def terminationFilterInput(currentSelection: List[CallModels.TerminationPoint]) = {
      enumeratedListPicker[CallModels.TerminationPoint](
        currentSelection,
        List(
          (CallModels.TerminationPoint.Human, "User", "Include calls answered by a User"),
          (
            CallModels.TerminationPoint.AutoAttendant,
            "Auto Attendant",
            "Include calls answered by an Auto Attendant"
          ),
          (
            CallModels.TerminationPoint.Voicemail,
            "Voicemail",
            "Include calls answered by Voicemail"
          ),
          (
            CallModels.TerminationPoint.CallGroup,
            "Call Group",
            "Include calls delivered to Call Groups"
          )
        ),
        newFilters =>
          dispatchNewFilterList(
            replaceFilter(filter, TerminationFilter(newFilters), currentFilters),
            component
          )
      )
    }

    def directionFilterInput(currentSelection: List[CallModels.Direction]) = {
      enumeratedListPicker[CallModels.Direction](
        currentSelection,
        List(
          (CallModels.Direction.Inbound, "Inbound", "Include Inbound calls"),
          (CallModels.Direction.Internal, "Internal", "Include Internal calls"),
          (CallModels.Direction.Outbound, "Outbound", "Include Outbound calls")
        ),
        newFilters =>
          dispatchNewFilterList(
            replaceFilter(filter, DirectionFilter(newFilters), currentFilters),
            component
          )
      )
    }

    def classOfServiceFilterInput(currentSelection: List[CallModels.ClassOfService]) = {
      enumeratedListPicker[CallModels.ClassOfService](
        currentSelection,
        List(
          (CallModels.ClassOfService.Premium, "Premium", "Include Premium calls"),
          (
            CallModels.ClassOfService.OperatorAssisted,
            "Operator Assisted",
            "Include Operator Assisted calls"
          ),
          (CallModels.ClassOfService.Mobile, "Mobile", "Include Mobile calls"),
          (CallModels.ClassOfService.Emergency, "Emergency", "Include Emergency calls"),
          (CallModels.ClassOfService.International, "International", "Include International calls"),
          (CallModels.ClassOfService.National, "National", "Include National calls"),
          (CallModels.ClassOfService.None, "None", "Include calls with no Class Of Service"),
          (CallModels.ClassOfService.Freephone, "Freephone", "Include Freephone (0800) calls")
        ),
        newFilters =>
          dispatchNewFilterList(
            replaceFilter(filter, ClassOfServiceFilter(newFilters), currentFilters),
            component
          )
      )
    }

    def numberCalledFilter(filter: DialedNumberFilter, filterManagerData: FilterManagerData) = {
      dialedNumberInput(
        filter,
        newValue =>
          dispatchNewFilterList(replaceFilter(filter, newValue, currentFilters), component)
      )
    }

    def callingFilter(filter: CallingNumberFilter, filterManagerData: FilterManagerData) = {
      callingNumberInput(
        filter,
        newValue =>
          dispatchNewFilterList(replaceFilter(filter, newValue, currentFilters), component)
      )
    }

    def callGroupFilter(filter: CallGroupFilter) = {

      callGroupInput(
        new ExtensionFilterProps(
          filter,
          props.tenantGroups,
          props.extensionLength
        ),
        newValue =>
          dispatchNewFilterList(replaceFilter(filter, newValue, currentFilters), component)
      )

    }

    def autoAttendantFilter(filter: AutoAttendantFilter) = {

      autoAttendantInput(
        new ExtensionFilterProps(
          filter,
          props.tenantAutoAttendants,
          props.extensionLength
        ),
        newValue =>
          dispatchNewFilterList(replaceFilter(filter, newValue, currentFilters), component)
      )
    }

    def extensionFilter(filter: ExtensionFilter) = {
      subscriberInput(
        new ExtensionFilterProps(
          filter,
          props.tenantExtensions,
          props.extensionLength
        ),
        newValue =>
          dispatchNewFilterList(replaceFilter(filter, newValue, currentFilters), component)
      )
    }

    def replaceCurrentFilter(newFilter: Filter) =
      dispatchNewFilterList(replaceFilter(filter, newFilter, currentFilters), component)

    val filterComponents: Option[(String, String, RenderFunction[VNode], Boolean)] = filter match {
      case AnswerFilter(value) =>
        Some(
          (
            "Answer Filter",
            "Filter calls based on their answered status",
            answerFilterInput(value),
            false
          )
        )
      case DirectionFilter(dirs) =>
        Some(
          (
            "Direction Filter",
            "Filter calls based on their direction",
            directionFilterInput(dirs),
            false
          )
        )
      case TerminationFilter(terms) =>
        Some(
          (
            "Termination Point Filter",
            "Filter calls based on how they were terminated",
            terminationFilterInput(terms),
            false
          )
        )
      case ClassOfServiceFilter(cos) =>
        Some(
          (
            "Class Of Service Filter",
            "Filter calls based on their service type",
            classOfServiceFilterInput(cos),
            false
          )
        )
      case d: DialedNumberFilter =>
        Some(
          (
            "Dialled Number Filter",
            "Filter calls based on their destination",
            numberCalledFilter(d, data),
            false
          )
        )
      case c: CallingNumberFilter =>
        Some(
          (
            "Calling Number Filter",
            "Filter calls based on their origin",
            callingFilter(c, data),
            false
          )
        )
      case d: DateFilter =>
        Some(
          (
            "Date Filter",
            "The period of days this report will cover (up to a maximum of 90 days)",
            dateInput(Left(d), f => replaceCurrentFilter(f.merge)),
            true
          )
        )
      case t: TimeFilter =>
        Some(
          (
            "Time of Day Filter",
            "The times of day this report will select calls from",
            timeFilterInput(t, replaceCurrentFilter),
            false
          )
        )
      case e: ExtensionFilter =>
        Some(
          (
            "Extension Filter",
            "Select calls to include based on the extension of the last user involved in the call. " +
              "A call may have multiple users involved over its duration at different stages and via transfers. " +
              "This filter does not include Call Groups or Auto Attendants.",
            extensionFilter(e),
            false
          )
        )
      case a: AutoAttendantFilter =>
        Some(
          (
            "Auto Attendant Filter",
            "Select calls to include based on one or many Auto Attendants",
            autoAttendantFilter(a),
            false
          )
        )
      case c: CallGroupFilter =>
        Some(
          (
            "Call Group Filter",
            "Select calls to include based on one or many Call Groups",
            callGroupFilter(c),
            false
          )
        )
      case d: RelativeDateFilter =>
        Some(
          (
            "Date Filter",
            "The period of days this report will cover",
            dateInput(Right(d), f => replaceCurrentFilter(f.merge)),
            true
          )
        )
      case f =>
        logger.error(s"A filter was detected that could not be recognised! $f")
        (None: Option[(String, String, RenderFunction[VNode], Boolean)])
    }

    filterComponents
      .map(v => {
        val (cardTitle, cardText, _, mandatory) = v
        val cardContents = v._3

        filterCard(
          animKey,
          cardTitle,
          cardText,
          mandatory,
          filter,
          currentFilters,
          f => dispatchNewFilterList(currentFilters.filterNot(_ == f), component),
          component
        )(
          cardContents
        )
      })
      .getOrElse(div())
    // Pattern matching can get weird with existential types, hence these 2 following lines

  }

  def dispatchNewFilterList(newFilters: List[Filter], component: VueComponent[_, _, _]) = {
    component.$emit("filterListUpdated", newFilters)
  }

  def mainBody(component: VueComponent[_, _, _] with FilterManagerData with FilterManagerProps) = {
    val allColumns = if (component.currentFilters.nonEmpty) {

      val columns = component.currentFilters.zipWithIndex
        .groupBy(_._2 % 3)
        .toList

      val blanksToAppend =
        List.iterate((columns.size, Nil: List[(Filter, Int)]), 3 - columns.size)(blankColumn =>
          blankColumn._1 + 1 -> blankColumn._2
        )

      (blanksToAppend ::: columns).map { case (columnIndex, columnItems) =>
        val columnFilters = columnItems.zipWithIndex.map { case ((f, _), index) =>
          filterItemRenderer(s"filter-c$columnIndex-$index", f, component, component, component)
        }

        vFlex(
          RenderOptions(
            `class` = List("lg4", "md6", "xs12").map(Left.apply)
          ),
          //            vScaleTransition(
          //              VTransitionProps(group = Some(true)),
          columnFilters
          //            )
        )
      }
    } else {
      Nil
    }

    if (allColumns.isEmpty) {
      vCard(RenderOptions(key = Some(s"filter--1")))(
        vList(
          vListTile(
            vListTileContent(
              "No filters added yet. Click the plus sign below to add a new one!"
            )
          )
        )
      )
    } else {
      vLayout(
        RenderOptions(
          `class` = List("row", "wrap").map(s => Left(s))
        ),
        allColumns.reverse
      )
    }
  }

  def filterManagerComponent() = {
    VueComponent.builder
      .withData(new FilterManagerData())
      .withProps(
        js.Array(
          "currentFilters",
          "extensionLength",
          "tenantExtensions",
          "tenantGroups",
          "tenantAutoAttendants"
        ).asInstanceOf[FilterManagerProps]
      )
      .build(
        created = js.defined(c => {
          logger.debug("Filter manager created")
        }),
        updated = js.defined(c => {
          logger.debug("Filter manager updated")
        }),
        components = js.Dynamic.literal(
          "CallingNumberFilter" -> DigitInputFilters.callingNumberComponent,
          "DialedNumberFilter" -> DigitInputFilters.dialledNumberComponent,
          "DateFilter" -> DateFilterInput.dateFilterInputComponent(90),
          "SubscriberFilter" -> ExtensionInput.subscriberExtensionFilterComponent,
          "AutoAttendantFilter" -> ExtensionInput.autoAttendantFilterComponent,
          "CallGroupFilter" -> ExtensionInput.callGroupFilterComponent
        ),
        templateOrRender = Right((component, renderer) => {
          div(
            vCardTitle(
              RenderOptions(
                `class` = List(Left("headline"), Left("info-text"))
              ),
              "Filters"
            ),
            p(
              RenderOptions(
                `class` = List(Left("info-text")),
                style = Some(
                  js.Dynamic.literal(
                  )
                )
              ),
              "Use filters to refine your data. Add filters by using the + button at the bottom. All filters shown below are active on your current report."
            ),
            mainBody(component),
            vSpacer {
              //
              val filtersToAdd = FilterHelpers.simpleFilterOptions.collect {
                case FilterOption(displayName, blank, shouldDisplay)
                    if shouldDisplay(component.currentFilters) =>
                  vListTile(click(e => {
                    dispatchNewFilterList(component.currentFilters ::: List(blank), component)
                  }))(
                    vListTileContent(
                      displayName
                    )
                  )
              }
              vMenu(VMenuProps(disabled = Some(filtersToAdd.isEmpty)))(
                vButton(
                  RenderOptions(
                    slot = Some("activator"),
                    props = Some(
                      VButtonProps(
                        fab = Some(true),
                        disabled = Some(filtersToAdd.isEmpty)
                      )
                    )
                  ),
                  vIcon("add")
                ),
                vList(
                  vFadeTransition(
                    VTransitionProps(
                      group = Some(true)
                    )
                  ),
                  filtersToAdd
                )
              )
            }
          ).render(renderer)
        })
      )
  }

}

trait FilterManagerEvents extends EventBindings {
  def filterListUpdated(e: List[Filter]): Unit
}

object FilterManagerEvents {
  def apply(
      bindings: EventBindings = EventBindings(),
      filterListUpdated: js.UndefOr[js.Function1[List[Filter], Unit]] = js.undefined
  ) = {
    if (filterListUpdated.isDefined)
      bindings.asInstanceOf[js.Dynamic].updateDynamic("filterListUpdated")(filterListUpdated)
    val selectedBinding = bindings.asInstanceOf[FilterManagerEvents]
    selectedBinding
  }
}

sealed trait FilterManagerProps extends VueProps {
  val currentFilters: List[Filter]
  val extensionLength: Option[Int]
  val tenantExtensions: List[Subscriber]
  val tenantGroups: List[CallGroup]
  val tenantAutoAttendants: List[AutoAttendant]
}

object FilterManagerProps {
  def apply(
      currentFilters: List[Filter],
      extensionLength: Option[Int] = None,
      tenantExtensions: List[Subscriber] = Nil,
      tenantGroups: List[CallGroup] = Nil,
      tenantAutoAttendants: List[AutoAttendant] = Nil
  ): FilterManagerProps = {
    js.Dynamic
      .literal(
        "currentFilters" -> currentFilters.asInstanceOf[js.Object],
        "extensionLength" -> extensionLength.asInstanceOf[js.Object],
        "tenantExtensions" -> tenantExtensions.asInstanceOf[js.Object],
        "tenantGroups" -> tenantGroups.asInstanceOf[js.Object],
        "tenantAutoAttendants" -> tenantAutoAttendants.asInstanceOf[js.Object]
      )
      .asInstanceOf[FilterManagerProps]
  }
}

class FilterManagerData extends js.Object {
  var dialledNumberInput: Option[String] = None
  var callingNumberInput: Option[String] = None
  var extensionInput: Option[String] = None
  var callGroupInput: Option[String] = None
  var autoAttendantInput: Option[String] = None

}
