package com.sludg.client.components.tables

import com.sludg.client.components.tables.GroupingDataTable.GroupingDataTableComponent
import com.sludg.client.components.tables.GroupingTableModels.{
  GroupingTableItem,
  TableColumnsWithSorting
}
import com.sludg.util.PresenterSyntax._
import com.sludg.util.ApiModelPresenters._
import com.sludg.util.models.GroupingModels
import com.sludg.util.models.GroupingModels.RootReportData
import com.sludg.util.models.ReportModels.Filter.AnswerFilter
import com.sludg.util.models.ReportModels.ReportColumn
import com.sludg.util.models.ReportModels.Sort.{Ascending, Descending, Neutral}

import scala.scalajs.js

class DataDisplayerWatcher() extends js.Object {

  def showAllHeaders(showAll: Boolean) = {
    val component = this.asInstanceOf[GroupingDataTableComponent]
    if (js.isUndefined(showAll)) {
      GroupingDataTable.logger.debug("Show all headers is undefined")
    } else {
      if (showAll) {
        component.currentlySelectedHeaders = component.selectableHeaderPool
      } else {
        component.currentlySelectedHeaders = List()
      }
    }
  }

  def rawTableData(rawTableItems: List[ExtractedTableData]) = {
    GroupingDataTable.logger.debug("Raw data changed")

    val component = this.asInstanceOf[GroupingDataTableComponent]
    if (rawTableItems.nonEmpty) {
      GroupingDataTable.logger.info("Raw data not empty")
      val rawTableHeaders = component.rawTableHeaders

      val formattedPossibleHeaders: List[HeaderWithGrouping] = rawTableHeaders.map(a => {
        HeaderWithGrouping(
          DataDisplayerUtil.convertFromPathToHeader(a.categoryData, a.projectionType),
          DataDisplayerUtil.convertFromPathToHeader(a.categoryData, a.projectionType),
          Some("left"),
          Some(false),
          categoryData = Some(a.categoryData),
          projectionType = Some(a.projectionType),
          sort = Some(Neutral),
          width = Some("1%")
        )
      })

      val formattedTableItems: List[GroupingTableItem] = rawTableItems.map(a => {
        GroupingTableItem(
          a.categoryData.present,
          a.mapOfHeadersWithData.map(mapWithData => {
            (
              DataDisplayerUtil.convertFromPathToHeader(
                mapWithData._1.path.map(_.categoryData),
                mapWithData._1.projectionType
              ),
              mapWithData._2
            )
          })
        )
      })

      component.selectableHeaderPool = formattedPossibleHeaders.distinct

      def filterTalkFromValue(header: HeaderWithGrouping) = {
        header.value.getOrElse("") match {
          case x if x.contains("Not Answered") && !component.removeTalkTimeProjection(header) =>
            false
          case _ => true
        }
      }

      if (component.preSelectedHeaders.isEmpty) {
        component.currentlySelectedHeaders = formattedPossibleHeaders.filter(filterTalkFromValue)
      } else {
        if (component.isItSameReport) {
          // All Good
          val updatedList = component.currentlySelectedHeaders.filter(a =>
            component.selectableHeaderPool.map(_.value).contains(a.value)
          )
          component.currentlySelectedHeaders =
            if (updatedList.isEmpty) formattedPossibleHeaders else updatedList
          component.isItSameReport = false
        } else {
          component.currentlySelectedHeaders = component.preSelectedHeaders.map(savedColsToHeader)
        }
      }
      def savedColsToHeader(a: ReportColumn): HeaderWithGrouping = {
        HeaderWithGrouping(
          DataDisplayerUtil.convertFromPathToHeader(
            a.columns,
            a.projectionType
          ), // This is where custom header name would go
          DataDisplayerUtil.convertFromPathToHeader(a.columns, a.projectionType),
          categoryData = Some(a.columns),
          projectionType = Some(a.projectionType),
          sort = Some(a.sort),
          align = Some("left"),
          sortable = Some(false),
          width = Some("1%")
        )
      }

      if (component.selectableHeaderPool == component.currentlySelectedHeaders) {
        component.showAllHeaders = true
      }

      component.itemsData = formattedTableItems
      component.loading = false
    } else {
      GroupingDataTable.logger.debug("Empty Data")
    }
  }

  def tableContents(tableContentsNew: TableContents) = {
    if (js.isUndefined(tableContentsNew)) {
      GroupingDataTable.logger.debug("Table contents undefined")
    } else {
      GroupingDataTable.logger.debug("Table contents has changed")
      val component = this.asInstanceOf[GroupingDataTableComponent]
      val rawTableHeaders: List[ExtractedTableHeader] = tableContentsNew.headers
      component.rawTableHeaders = rawTableHeaders

      val rawTableItems: List[ExtractedTableData] = tableContentsNew.data

      if (component.preSelectedHeaders.isEmpty) {
        GroupingDataTable.logger.info("No Saved Headers")
        component.rawTableData = rawTableItems
      } else {
        component.rawTableData = rawTableItems
        // Should give the header that that data will be sorted by
        val sortByOpt = component.preSelectedHeaders.find(_.sort != Neutral)
        sortByOpt match {
          case Some(sortBy) => {
            component.sortBy = js.defined(
              TableColumnsWithSorting(
                sortBy.name,
                sortBy.sort,
                sortBy.columns,
                sortBy.projectionType
              )
            )
            val nodePath = NodePath(sortBy.projectionType, sortBy.columns.map(c => Node(c)))
            val descending = if (sortBy.sort == Ascending) false else true
            component.descending = descending
            component.customSort(nodePath, descending, rawTableItems)
          }
          case None => {
            GroupingDataTable.logger.debug("Not found sortby")
            component.defaultSort(rawTableItems)
          }
        }
      }
    }
  }

  def projectionData(value: RootReportData, oldValue: RootReportData): Unit = {
    if (js.isUndefined(value)) {
      GroupingDataTable.logger.debug("Projection undefined")
    } else {
      val props = this.asInstanceOf[DataDisplayerProps]
      val data = this.asInstanceOf[DataDisplayerData]

      if (!data.isItSameReport) {
        //Force the tableContents watcher to be invoked and sort the table if a new report is loaded
        data.tableContents = DataDisplayerUtil.dataToTableContents(props.projectionData)
      }
      val allProjectionsData = value
      val tableContents = DataDisplayerUtil.dataToTableContents(allProjectionsData)

      val rawTableHeaders: Seq[ExtractedTableHeader] = tableContents.headers

      val rawTableItems: Seq[ExtractedTableData] = tableContents.data
      data.rawTableHeaders = rawTableHeaders.toList
      data.rawTableData = rawTableItems.toList

      val rootTableHead = props.projectionData.subGroups.head.categoryData.category
      data.defaultRootHead = List(
        HeaderWithGrouping(
          Some(rootTableHead.present),
          Some(rootTableHead.present),
          Some("left"),
          Some(true),
          Some(Neutral)
        )
      )
      data.rootTableHead = List(
        HeaderWithGrouping(
          Some(rootTableHead.present),
          Some(rootTableHead.present),
          Some("left"),
          Some(true),
          Some(Neutral)
        )
      )

    }
  }

  def currentlySelectedHeaders(selectedHeaders: List[HeaderWithGrouping]) = {
    val component = this.asInstanceOf[GroupingDataTableComponent]
    if (js.isUndefined(selectedHeaders)) {
      GroupingDataTable.logger.debug("selected headers not defined")
    } else {
      if (selectedHeaders.nonEmpty) {
        val convertedSelectedHeaders: List[TableColumnsWithSorting] = selectedHeaders.map(a => {
          TableColumnsWithSorting(
            a.text.getOrElse(""),
            a.sort.getOrElse(Neutral),
            a.categoryData.map(_.toList).getOrElse(List()),
            a.projectionType.get
          )
        })
        GroupingDataTable.logger.trace("Emitting updated headers")
        component.$emit("headersSelected", convertedSelectedHeaders)
      } else {
        component.$emit("headersSelected", Nil)
      }
    }
  }
}
